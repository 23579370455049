import React, { useContext } from 'react';
import { PropTypes } from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import { Redirect } from 'react-router';
import Dashboard from '../Templates/Dashboard';
import { ThemeContext } from './ThemeWrapper';
import {
  AnalyticDashboard,
  NotFound,
  ComingSoon,
  ProductList,
  CatalogCreate,
  CatalogList,
  CatalogDetail,
  Profile,
  CatalogProductListExpanded,
  CatalogEcoScore,
} from '../pageListAsync';

function Application(props) {
  const { history } = props;
  const changeMode = useContext(ThemeContext);

  return (
    <Dashboard
      history={history}
      changeMode={changeMode}
    >
      <Switch>
        {/* Home */}
        <Route
          exact
          path="/app"
          component={AnalyticDashboard}
        />
        {/* User */}
        <Route
          path="/app/profile"
          component={Profile}
        />
        {/* Campains */}
        <Route
          path="/app/campaign-create"
          component={CatalogCreate}
        />
        <Route
          path="/app/campaign-list"
          component={CatalogList}
        />
        <Route
          path="/app/campaign-detail/:id"
          component={({ match }) => (
            <Redirect to={{ pathname: '/app/campaign-detail', state: { id: match.params.id } }} />
          )}
        />
        <Route
          path="/app/campaign-detail"
          component={CatalogDetail}
        />
        <Route
          path="/app/campaign-product-list-extended/:id?"
          component={CatalogProductListExpanded}
        />
        <Route
          path="/app/campaign-eco-score/:id?"
          component={CatalogEcoScore}
        />
        <Route
          path="/app/campaign-statistics"
          component={ComingSoon}
        />
        {/* Products */}
        <Route
          path="/app/product-create"
          component={ComingSoon}
        />
        <Route
          path="/app/product-list"
          component={ProductList}
        />
        <Route
          path="/app/product-statistics"
          component={ComingSoon}
        />
        {/* Configurations */}
        <Route
          path="/app/labels"
          component={ComingSoon}
        />
        <Route
          path="/app/api"
          component={ComingSoon}
        />
        <Route
          path="/app/connectors"
          component={ComingSoon}
        />
        <Route
          path="/app/integrations"
          component={ComingSoon}
        />
        {/* Statistics & Ratings */}
        <Route
          path="/app/market-statistics"
          component={ComingSoon}
        />
        <Route
          path="/app/sector-comparison"
          component={ComingSoon}
        />
        <Route
          path="/app/research"
          component={ComingSoon}
        />
        <Route component={NotFound} />
      </Switch>
    </Dashboard>
  );
}

Application.propTypes = { history: PropTypes.object.isRequired };

export default Application;

import React, { Fragment } from "react";
import { makeStyles } from "tss-react/mui";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { injectIntl, FormattedMessage } from "react-intl";
import messages from "./messages";

const useStyles = makeStyles()((theme) => ({
  campaignSummary: {
    margin: "0 auto",
    padding: theme.spacing(3),
    paddingTop: 0,
  },
  campaignSummaryContent: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
  },
  campaignSummaryCategory: {
    textDecoration: "underline",
  },
}));

function CatalogReview(props) {
  const { type, campaignName, description, files } = props;
  const { classes } = useStyles();

  return (
    <Fragment>
      <div className={classes.campaignSummary}>
        <Typography
          variant="h6"
          gutterBottom
        >
          <FormattedMessage {...messages.campaign_summary} />
        </Typography>
        <Grid
          container
          spacing={2}
          className={classes.campaignSummaryContent}
        >
          <Typography gutterBottom>
            <span className={classes.campaignSummaryCategory}>
              <FormattedMessage {...messages.campaign_name} />
            </span>
            &nbsp;:
            {" " + campaignName}
          </Typography>
          <Typography gutterBottom>
            <span className={classes.campaignSummaryCategory}>
              <FormattedMessage {...messages.campaign_description} />
            </span>
            &nbsp;:
            {" " + description}
          </Typography>
          <Typography gutterBottom>
            <span className={classes.campaignSummaryCategory}>
              <FormattedMessage {...messages.campaign_type} />
            </span>
            &nbsp;:
            {" " + type.replace("type_", "").toUpperCase()}
          </Typography>
          <Typography gutterBottom>
            <span className={classes.campaignSummaryCategory}>
              <FormattedMessage {...messages.campaign_files_upload} />
            </span>
            &nbsp;:
            {" " + files.length} <FormattedMessage {...messages.files_uploaded} />
          </Typography>
        </Grid>
      </div>
    </Fragment>
  );
}

export default injectIntl(CatalogReview);

import React from 'react';
import { get } from 'lodash';
import { addUnit, getter } from '../../../utils/datagrid/constants';
import { units } from '../../../utils/ecobalyse/constants';
import productListMessages from '../CatalogProductListExpandedHelpers/messages';
import messages from './messages';

/** @type {() => import('@mui/x-data-grid').GridColDef[]} */
export const EcoScoreColumns = (intl) => [
  {
    field: 'product_code',
    headerName: intl.formatMessage(productListMessages.column_header_product_name),
    valueGetter: get('product_code', ''),
    type: 'string',
    width: 200,
  },
  {
    field: 'attributes.product_type',
    headerName: intl.formatMessage(productListMessages.column_header_product_type),
    type: 'string',
    width: 150,
  },
  {
    field: 'attributes.ecs', headerName: intl.formatMessage(messages.ecs), width: 150, options: units.ecs
  },
  {
    field: 'attributes.pef', headerName: intl.formatMessage(messages.pef), width: 300, options: units.pef
  },
  { field: 'attributes.wtu', headerName: intl.formatMessage(messages.wtu), options: units.wtu },
  { field: 'attributes.tre', headerName: intl.formatMessage(messages.tre), options: units.tre },
  { field: 'attributes.swe', headerName: intl.formatMessage(messages.swe), options: units.swe },
  { field: 'attributes.pma', headerName: intl.formatMessage(messages.pma), options: units.pma },
  { field: 'attributes.pco', headerName: intl.formatMessage(messages.pco), options: units.pco },
  { field: 'attributes.ozd', headerName: intl.formatMessage(messages.ozd), options: units.ozd },
  { field: 'attributes.mru', headerName: intl.formatMessage(messages.mru), options: units.mru },
  { field: 'attributes.ldu', headerName: intl.formatMessage(messages.ldu), options: units.ldu },
  { field: 'attributes.ior', headerName: intl.formatMessage(messages.ior), options: units.ior },
  { field: 'attributes.htn', headerName: intl.formatMessage(messages.htn), options: units.htn },
  { field: 'attributes.htc', headerName: intl.formatMessage(messages.htc), options: units.htc },
  { field: 'attributes.fwe', headerName: intl.formatMessage(messages.fwe), options: units.fwe },
  { field: 'attributes.fru', headerName: intl.formatMessage(messages.fru), options: units.fru },
  { field: 'attributes.etf', headerName: intl.formatMessage(messages.etf), options: units.etf },
  { field: 'attributes.cch', headerName: intl.formatMessage(messages.cch), options: units.cch },
  { field: 'attributes.acd', headerName: intl.formatMessage(messages.acd), options: units.acd },
  { field: 'attributes.bvi', headerName: intl.formatMessage(messages.bvi), options: units.bvi },
];

/** @type {(colOpt: import('@mui/x-data-grid').GridColDef) => import('@mui/x-data-grid').GridColDef} */
const ColumnBaseOptions = (colOpt) => ({
  width: 250,
  editable: false,
  type: 'number',
  valueGetter: (__, row) => (
    (colOpt.type === 'string')
      ? getter(colOpt.field)(__, row)
      : Number(getter(colOpt.field)(__, row)).toFixed(colOpt.options.round ?? 2)
  ),
  valueFormatter: colOpt.options?.unit ? addUnit(colOpt.options.unit) : undefined,
  renderCell: (params) => (
    <p style={{ ...{}, ...units[params.field.split('.').pop()]?.customStyle }}>{params.formattedValue}</p>
  ),
});

export const EcoScoreColumnsOptions = (intl) => {
  const allColumnsOptions = EcoScoreColumns(intl);
  return allColumnsOptions.map((columnOptions) => ({ ...ColumnBaseOptions(columnOptions), ...columnOptions }));
};

export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
export const OPEN_MENU = 'OPEN_MENU';
export const OPEN_SUBMENU = 'OPEN_SUBMENU';
export const CHANGE_THEME = 'CHANGE_THEME';
export const CHANGE_MODE = 'CHANGE_MODE';
export const CHANGE_GRADIENT = 'CHANGE_GRADIENT';
export const CHANGE_DECO = 'CHANGE_DECO';
export const CHANGE_BG_POSITION = 'CHANGE_BG_POSITION';
export const CHANGE_LAYOUT = 'CHANGE_LAYOUT';
export const CHANGE_DIRECTION = 'CHANGE_DIRECTION';
export const LOAD_PAGE = 'LOAD_PAGE';
export const CLOSE_MENU = 'CLOSE_MENU';

import React from "react";
import { PropTypes } from "prop-types";
import { Router, Switch, Route } from "react-router-dom";
import NotFound from "../Pages/Standalone/NotFoundDedicated";
import Auth from "./Auth";
import Application from "./Application";
import LandingCorporate from "./Landing";
import ThemeWrapper from "./ThemeWrapper";
import withAuthorizationRouter from "../Session/withAuthorizationRouter";
import { Redirect } from "react-router";
window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;

function App(props) {
	const { history } = props;
	return (
		<ThemeWrapper>
			<Router history={history}>
				<Switch>
					{/* <Route path="/" exact component={LandingCorporate} /> */}
					<Redirect
						exact
						from="/"
						to="/app"
					/>
					<Route
						path="/app"
						component={withAuthorizationRouter(Application)}
					/>
					<Route component={Auth} />
					<Route component={NotFound} />
				</Switch>
			</Router>
		</ThemeWrapper>
	);
}

App.propTypes = { history: PropTypes.object.isRequired };

const mapStateToProps = (state) => ({});

export default App;

import { defineMessages } from 'react-intl';

export const scope = 'boilerplate.components.Catalogs.CatalogProductListExpanded';

export default defineMessages({
  product_list_new_value: {
    id: 'boilerplate.containers.Products.ProductList.product_list_new_value',
    defaultMessage: 'New Value',
  },
  product_list_change_value: {
    id: 'boilerplate.containers.Products.ProductList.product_list_change_value',
    defaultMessage: 'Change Value',
  },
  product_list_select_column: {
    id: 'boilerplate.containers.Products.ProductList.product_list_select_column',
    defaultMessage: 'Select Column',
  },
  // columns group headers
  header_group_characteristics: {
    id: `${scope}.HeaderGroup.characteristics`,
    defaultMessage: 'Caractéristiques',
  },
  header_group_completion: {
    id: `${scope}.HeaderGroup.completion`,
    defaultMessage: 'Complétion',
  },
  header_group_non_physical_durability: {
    id: `${scope}.HeaderGroup.non_physical_durability`,
    defaultMessage: 'Durabilité non-physique',
  },
  header_group_raw_materials: {
    id: `${scope}.HeaderGroup.raw_materials`,
    defaultMessage: 'Matières premières',
  },
  header_group_transformation: {
    id: `${scope}.HeaderGroup.transformation`,
    defaultMessage: 'Transformation',
  },
  // columns headers
  column_header_product_name: {
    id: `${scope}.ColumnHeader.product_name`,
    defaultMessage: 'Produit',
  },
  column_header_ecs: {
    id: `${scope}.ColumnHeader.ecs`,
    defaultMessage: 'ECS',
  },
  column_header_product_type: {
    id: `${scope}.ColumnHeader.product_type`,
    defaultMessage: 'Type de produit',
  },
  column_header_description: {
    id: `${scope}.ColumnHeader.description`,
    defaultMessage: 'Description',
  },
  column_header_reference: {
    id: `${scope}.ColumnHeader.reference`,
    defaultMessage: 'Référence',
  },
  column_header_supplier: {
    id: `${scope}.ColumnHeader.supplier`,
    defaultMessage: 'Fournisseur',
  },
  column_header_completion_rate: {
    id: `${scope}.ColumnHeader.completion_rate`,
    defaultMessage: 'Taux de complétion',
  },
  column_header_nok_number: {
    id: `${scope}.ColumnHeader.nok_number`,
    defaultMessage: 'Nombre NOK',
  },
  column_header_mass: {
    id: `${scope}.ColumnHeader.mass`,
    defaultMessage: 'Masse',
  },
  column_header_price: {
    id: `${scope}.ColumnHeader.price`,
    defaultMessage: 'Prix',
  },
  column_header_reference_number: {
    id: `${scope}.ColumnHeader.reference_number`,
    defaultMessage: 'Nombre de référence',
  },
  column_header_marketing_duration: {
    id: `${scope}.ColumnHeader.marketing_duration`,
    defaultMessage: 'Durée de commercialisation',
  },
  column_header_business: {
    id: `${scope}.ColumnHeader.business`,
    defaultMessage: 'Type of comany',
  },
  column_header_displayed_traceability: {
    id: `${scope}.ColumnHeader.displayed_traceability`,
    defaultMessage: 'Traçabilité affichée',
  },
  column_header_material: {
    id: `${scope}.ColumnHeader.material`,
    defaultMessage: 'Matière {nb}',
  },
  column_header_origin: {
    id: `${scope}.ColumnHeader.origin`,
    defaultMessage: 'Origine m{nb}',
  },
  column_header_percentage: {
    id: `${scope}.ColumnHeader.percentage`,
    defaultMessage: '% m{nb}',
  },
  column_header_spinning_country: {
    id: `${scope}.ColumnHeader.spinning_country`,
    defaultMessage: 'Pays filature',
  },
  column_header_titration_nm: {
    id: `${scope}.ColumnHeader.titration_nm`,
    defaultMessage: 'Titrage (nm)',
  },
  column_header_weaving_country: {
    id: `${scope}.ColumnHeader.weaving_country`,
    defaultMessage: 'Pays tissage',
  },
  column_header_process: {
    id: `${scope}.ColumnHeader.process`,
    defaultMessage: 'Procédé',
  },
  column_header_finishing_country: {
    id: `${scope}.ColumnHeader.finishing_country`,
    defaultMessage: 'Pays ennoblissement',
  },
  column_header_dyeing_on: {
    id: `${scope}.ColumnHeader.dyeing_on`,
    defaultMessage: 'Teinture sur',
  },
  column_header_printing: {
    id: `${scope}.ColumnHeader.printing`,
    defaultMessage: 'Impression',
  },
  column_header_manufacturing_country: {
    id: `${scope}.ColumnHeader.manufacturing_country`,
    defaultMessage: 'Pays de confection',
  },
  column_header_loss_percentage: {
    id: `${scope}.ColumnHeader.loss_percentage`,
    defaultMessage: '% perte',
  },
  column_header_dormant_stock_percentage: {
    id: `${scope}.ColumnHeader.dormant_stock_percentage`,
    defaultMessage: '% stocks dormants',
  },
  column_header_air_transport_percentage: {
    id: `${scope}.ColumnHeader.air_transport_percentage`,
    defaultMessage: '% transport aérien',
  },
  column_header_actions: {
    id: `${scope}.ColumnHeader.actions`,
    defaultMessage: 'Actions',
  },
  column_header_sync: {
    id: `${scope}.ColumnHeader.sync`,
    defaultMessage: 'Calculer Impact',
  },
  column_header_ecobalyse_result_url: {
    id: `${scope}.ColumnHeader.ecobalyse_result_url`,
    defaultMessage: 'Lien simulateur',
  },
  column_header_ecobalyse_input_json: {
    id: `${scope}.ColumnHeader.ecobalyse_input_json`,
    defaultMessage: 'Données envoyées',
  },
});

import axios from 'axios';

export const fetchCampaignData = async (campaignId) => {
  axios.get(process.env.NOCOO_INDEX_URL + `/catalog/${campaignId}`)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error);
    });
};

export const fetchCampaignProducts = (campaignId) => {
  axios.get(process.env.NOCOO_INDEX_URL + `/catalog/${campaignId}`)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error);
    });
};

import { call, put } from 'redux-saga/effects';
import history from '../../utils/history';
import {
  loginSuccess,
  loginFailure,
  logoutSuccess,
  logoutFailure,
  loginWithEmailSuccess,
  loginWithEmailFailure,
  syncUser,
  registerWithEmailSuccess,
  registerWithEmailFailure,
  createUserSuccess,
  createUserFailure,
  passwordForgetSuccess,
  passwordForgetFailure,
} from '../actions/authActions';
import {
  fetchDolibarrUser,
  loginWithEmailDolibarr,
  logoutDolibarr,
  registerWithEmailDolibarr,
} from '../../dolibarr/dolibarrAuth';

/* eslint-disable no-unused-vars */

function getUrlVars() {
  const vars = {};
  window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, (m, key, value) => {
    // eslint-disable-line
    vars[key] = value;
  });
  return vars;
}

export function* loginSagaDolibarr(provider) {
  try {
    // console.log("provider", provider);
    // const data = yield call(firebaseAuth.signInWithPopup, provider.payload.authProvider);
    const data = { email: 'admin@admin.com', password: 'admin' };
    localStorage.setItem('user', JSON.stringify(data));
    yield put(loginSuccess(data));
    if (getUrlVars().next) {
      // Redirect to next route
      yield history.push(getUrlVars().next);
    } else {
      // Redirect to dashboard if no next parameter
      yield history.push('/app');
    }
  } catch (error) {
    yield put(loginFailure(error));
  }
}

export function* loginWithEmailSagaDolibarr(payload) {
  try {
    const data = yield call(loginWithEmailDolibarr, payload.email, payload.password);
    yield put(loginWithEmailSuccess(data));
    if (getUrlVars().next) {
      yield history.push(getUrlVars().next);
    } else {
      yield history.push('/app');
    }
  } catch (error) {
    yield put(loginWithEmailFailure(error));
  }
}

export function* registerWithEmailSagaDolibarr(payload) {
  console.log('registerWithEmailSagaDolibarr', payload.credential);
  try {
    const data = yield call(registerWithEmailDolibarr, payload.credential);
    // const dataWithName = { email: 'admin@admin.com', password: 'admin', name: dummyContents.user.name };
    // yield call(firebaseAuth.createUserWithEmailAndPassword, payload.email, payload.password);
    // const dataWithName = yield call(firebaseAuth.updateProfile, {
    //  displayName: payload.name,
    // });
    yield put(registerWithEmailSuccess(data));
    yield history.push('/login');
  } catch (error) {
    yield put(registerWithEmailFailure(error));
  }
}

export function* logoutSagaDolibarr() {
  try {
    const data = yield call(logoutDolibarr);
    yield put(logoutSuccess(data));
    yield history.replace('/');
  } catch (error) {
    yield put(logoutFailure(error));
  }
}

export function* syncUserSagaDolibarr() {
  try {
    const user = yield call(fetchDolibarrUser);
    yield put(syncUser(user));
  } catch (error) {
    yield put(syncUser(null));
  }
}

export function* createUserSagaDolibarr({ credential }) {
  try {
    // console.log(credential);
    // yield call(firebaseDb.create, "user", {
    //  email: credential.email,
    //  displayName: credential.displayName,
    //  creationTime: credential.metadata.creationTime,
    // });
    yield put(createUserSuccess(credential));
  } catch (error) {
    yield put(createUserFailure(error));
  }
}

export function* passwordForgetSagaDolibarr({ email }) {
  try {
    // console.log(email);
    // yield call(firebaseAuth.sendPasswordResetEmail, email);
    yield put(passwordForgetSuccess());
  } catch (error) {
    yield put(passwordForgetFailure(error));
  }
}

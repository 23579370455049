/*
 * User Form Messages
 *
 * This contains all the text for the Form components.
 */
import { defineMessages } from 'react-intl';

export const scope = 'boilerplate.components.Catalogs';

export default defineMessages({
  campaign_informations: {
    id: `${scope}.CatalogCreate.campaign_informations`,
    defaultMessage: 'Catalog Informations',
  },
  campaign_name: {
    id: `${scope}.CatalogCreate.campaign_name`,
    defaultMessage: 'Catalog Name',
  },
  campaign_description: {
    id: `${scope}.CatalogCreate.campaign_description`,
    defaultMessage: 'Catalog Description',
  },
  campaign_type: {
    id: `${scope}.CatalogCreate.campaign_type`,
    defaultMessage: 'Catalog Type',
  },
  type_food: {
    id: `${scope}.CatalogCreate.campaign_type.food`,
    defaultMessage: 'Food',
    icon: 'lunch_dining',
  },
  type_clothes: {
    id: `${scope}.CatalogCreate.campaign_type.clothes`,
    defaultMessage: 'Clothes',
    icon: 'shopping_bag',
  },
  type_material: {
    id: `${scope}.CatalogCreate.campaign_type.material`,
    defaultMessage: 'Material',
    icon: 'construction',
  },
  type_fabric_ecobalyse: {
    id: `${scope}.CatalogCreate.campaign_type.fabric_ecobalyse`,
    defaultMessage: 'Fabric - Ecobalyse',
    icon: 'checkroom',
  },
  drop_files: {
    id: `${scope}.CatalogFileUpload.drop_files`,
    defaultMessage: 'Drag and drop file(s) here or click',
  },
  campaign_summary: {
    id: `${scope}.CatalogSideReview.campaign_summary`,
    defaultMessage: 'Catalog Summary',
  },
  // campaign_description: {
  //   id: `${scope}.CatalogSideReview.campaign_description`,
  //   defaultMessage: "Catalog description",
  // },
  // campaign_type: {
  //   id: `${scope}.CatalogSideReview.campaign_type`,
  //   defaultMessage: "Catalog type",
  // },
  campaign_files_upload: {
    id: `${scope}.CatalogSideReview.campaign_files_upload`,
    defaultMessage: 'Catalog Files Upload',
  },
  files_uploaded: {
    id: `${scope}.CatalogSideReview.files_uploaded`,
    defaultMessage: 'files uploaded',
  },
  processed: {
    id: `${scope}.CatalogProcessed.processed`,
    defaultMessage: 'Your campaign has been created successfully!',
  },
  files_processing: {
    id: `${scope}.CatalogProcessed.files_processing`,
    defaultMessage: 'Your files are being processed.',
  },
  see_campaign: {
    id: `${scope}.CatalogProcessed.see_campaign`,
    defaultMessage: 'See my campaign',
  },
  campaign_list_image: {
    id: `${scope}.CatalogListHelpers.campaign_list_image`,
    defaultMessage: 'Image',
  },
  campaign_list_type: {
    id: `${scope}.CatalogListHelpers.campaign_list_type`,
    defaultMessage: 'Type',
  },
  campaign_list_name: {
    id: `${scope}.CatalogListHelpers.campaign_list_name`,
    defaultMessage: 'Name',
  },
  campaign_list_code: {
    id: `${scope}.CatalogListHelpers.campaign_list_code`,
    defaultMessage: 'Code',
  },
  campaign_list_status: {
    id: `${scope}.CatalogListHelpers.campaign_list_status`,
    defaultMessage: 'Status',
  },
  campaign_list_files_pending: {
    id: `${scope}.CatalogListHelpers.campaign_list_files_pending`,
    defaultMessage: 'Files pending',
  },
  campaign_list_files_success: {
    id: `${scope}.CatalogListHelpers.campaign_list_files_success`,
    defaultMessage: 'Files success',
  },
  campaign_list_files_errors: {
    id: `${scope}.CatalogListHelpers.campaign_list_files_errors`,
    defaultMessage: 'Files errors',
  },
  campaign_list_last_update: {
    id: `${scope}.CatalogListHelpers.campaign_list_last_update`,
    defaultMessage: 'Last update',
  },
  campaign_list_actions: {
    id: `${scope}.CatalogListHelpers.campaign_list_actions`,
    defaultMessage: 'Actions',
  },
  campaign_list_delete: {
    id: `${scope}.CatalogListHelpers.campaign_list_delete`,
    defaultMessage: 'Delete',
  },
  // campaign_list_code: {
  //   id: `${scope}.CatalogDetailHelpers.campaign_list_code`,
  //   defaultMessage: "Code",
  // },
  // Default messages
  createNewAccount: {
    id: `${scope}.Login.create`,
    defaultMessage: 'Create new account',
  },
  login: {
    id: `${scope}.Login.signin`,
    defaultMessage: 'Sign in',
  },
  loginOr: {
    id: `${scope}.Login.or`,
    defaultMessage: 'Or sign in with',
  },
  registerOr: {
    id: `${scope}.Register.or`,
    defaultMessage: 'Or register with',
  },
  loginFieldName: {
    id: `${scope}.Register.field.name`,
    defaultMessage: 'Username',
  },
  loginFieldEmail: {
    id: `${scope}.Login.field.email`,
    defaultMessage: 'Your Email',
  },
  loginFieldPassword: {
    id: `${scope}.Login.field.password`,
    defaultMessage: 'Your Password',
  },
  loginFieldRetypePassword: {
    id: `${scope}.Register.field.retypePassword`,
    defaultMessage: 'Re-type Password',
  },
  loginForgotPassword: {
    id: `${scope}.Login.field.forgot`,
    defaultMessage: 'Forgot Password',
  },
  loginRemember: {
    id: `${scope}.Login.field.remember`,
    defaultMessage: 'Remember',
  },
  loginButtonContinue: {
    id: `${scope}.Login.button.continue`,
    defaultMessage: 'Continue',
  },
  toAccount: {
    id: `${scope}.Register.create`,
    defaultMessage: 'Already have account ?',
  },
  register: {
    id: `${scope}.Register.signup`,
    defaultMessage: 'Register',
  },
  tabEmail: {
    id: `${scope}.Register.tab.email`,
    defaultMessage: 'With Email',
  },
  tabSocial: {
    id: `${scope}.Register.tab.social`,
    defaultMessage: 'With Social Media',
  },
  aggree: {
    id: `${scope}.Register.agree`,
    defaultMessage: 'Agree with',
  },
  terms: {
    id: `${scope}.Register.terms`,
    defaultMessage: 'Terms & Condition',
  },
  resetTitle: {
    id: `${scope}.Reset.title`,
    defaultMessage: 'Reset Password',
  },
  resetSubtitle: {
    id: `${scope}.Reset.subtitle`,
    defaultMessage: 'Send reset password link to Your email',
  },
  resetButton: {
    id: `${scope}.Reset.button`,
    defaultMessage: 'Send Reset Link',
  },
  lockHint: {
    id: `${scope}.Lock.hint`,
    defaultMessage: 'Hint: Type anything to unlock!',
  },
  requiredForm: {
    id: `${scope}.Required.text`,
    defaultMessage: 'Required',
  },
  shipping_address: {
    id: `${scope}.Checkout.shipping_address`,
    defaultMessage: 'Shipping address',
  },
  order_summary: {
    id: `${scope}.Checkout.order_summary`,
    defaultMessage: 'Order Summary',
  },
  quantity: {
    id: `${scope}.Checkout.quantity`,
    defaultMessage: 'Quantity',
  },
  total: {
    id: `${scope}.Checkout.total`,
    defaultMessage: 'Total',
  },
  first_name: {
    id: `${scope}.Checkout.first_name`,
    defaultMessage: 'First Name',
  },
  last_name: {
    id: `${scope}.Checkout.last_name`,
    defaultMessage: 'Last Name',
  },
  address1: {
    id: `${scope}.Checkout.address1`,
    defaultMessage: 'Address line 1',
  },
  address2: {
    id: `${scope}.Checkout.address2`,
    defaultMessage: 'Address line 2',
  },
  city: {
    id: `${scope}.Checkout.city`,
    defaultMessage: 'City',
  },
  state: {
    id: `${scope}.Checkout.state`,
    defaultMessage: 'State/Province/Region',
  },
  zip: {
    id: `${scope}.Checkout.zip`,
    defaultMessage: 'Zip / Postal code',
  },
  country: {
    id: `${scope}.Checkout.country`,
    defaultMessage: 'Country',
  },
  check_address: {
    id: `${scope}.Checkout.check_address`,
    defaultMessage: 'Use this address for payment details',
  },
  last_three: {
    id: `${scope}.Checkout.last_three`,
    defaultMessage: 'Last three digits on signature strip',
  },
  check_credit: {
    id: `${scope}.Checkout.check_credit`,
    defaultMessage: 'Remember credit card details for next time',
  },
  shipping: {
    id: `${scope}.Checkout.shipping`,
    defaultMessage: 'Shipping',
  },
  payment_detail: {
    id: `${scope}.Checkout.payment_detail`,
    defaultMessage: 'Payment details',
  },
  // Temporary
  review_order: {
    id: 'boilerplate.containers.Pages.Checkout.review_order',
    defaultMessage: 'Review your order',
  },
  place: {
    id: 'boilerplate.containers.Pages.Checkout.place',
    defaultMessage: 'Place Order',
  },
  thank: {
    id: 'boilerplate.containers.Pages.Checkout.thank',
    defaultMessage: 'Thank you for your order.',
  },
  your_order: {
    id: 'boilerplate.containers.Pages.Checkout.your_order',
    defaultMessage: 'Your order number is',
  },
  we_have: {
    id: 'boilerplate.containers.Pages.Checkout.we_have',
    defaultMessage: 'We have emailed your order confirmation, and will send you an update when your order has shipped.',
  },
  shopping_again: {
    id: 'boilerplate.containers.Pages.Checkout.shopping_again',
    defaultMessage: 'Shopping Again',
  },
  // <CatalogProducts>
  product_list_title: {
    id: 'boilerplate.containers.Products.ProductList.product_list_title',
    defaultMessage: 'Product List',
  },
  product_list_image: {
    id: 'boilerplate.containers.Products.ProductList.product_list_image',
    defaultMessage: 'Image',
  },
  product_list_name: {
    id: 'boilerplate.containers.Products.ProductList.product_list_name',
    defaultMessage: 'Product Name',
  },
  product_list_description: {
    id: 'boilerplate.containers.Products.ProductList.product_list_description',
    defaultMessage: 'Description',
  },
  product_list_brand: {
    id: 'boilerplate.containers.Products.ProductList.product_list_brand',
    defaultMessage: 'Brand',
  },
  product_list_co2: {
    id: 'boilerplate.containers.Products.ProductList.product_list_co2',
    defaultMessage: 'CO₂ Level',
  },
  product_env_level: {
    id: 'boilerplate.containers.Products.ProductList.product_env_level',
    defaultMessage: 'Env Level',
  },
  product_list_co2_label: {
    id: 'boilerplate.containers.Products.ProductList.product_list_co2_label',
    defaultMessage: 'CO₂ Score',
  },
  product_list_env_label: {
    id: 'boilerplate.containers.Products.ProductList.product_list_env_label',
    defaultMessage: 'Env Score',
  },
  product_list_env_letter: {
    id: 'boilerplate.containers.Products.ProductList.product_list_env_letter',
    defaultMessage: 'Product environmental etiquette',
  },
  product_list_env_level: {
    id: 'boilerplate.containers.Products.ProductList.product_list_env_level',
    defaultMessage: 'PEF Rankings',
  },
  product_list_co2_level: {
    id: 'boilerplate.containers.Products.ProductList.product_list_co2_level',
    defaultMessage: 'CO₂ Rankings',
  },
  // </CatalogProducts>
});

import messages from "./messages";

const nbFormat = new Intl.NumberFormat(navigator.language, {
  style: "decimal",
  maximumFractionDigits: 3,
});

export const CatalogDetailProductsColumns = (intl) => [
  {
    name: "product_id",
    options: { display: "excluded" },
  },
  {
    name: "product_code",
    label: intl.formatMessage(messages.campaign_list_code),
  },
  // {
  //   name: "name",
  //   label: "Name",
  //   options: {},
  // },
  {
    name: "attributes.pef",
    label: "PEF",
    options: {
      customBodyRender: (value) => nbFormat.format(value ?? 0),
    },
  },
  // {
  //   name: "wch",
  //   label: "WCH",
  //   options: {},
  // },
  // {
  //   name: "status",
  //   label: "Status",
  //   options: {},
  // },
  {
    name: "attributes.fru",
    label: "FRU",
    options: {
      customBodyRender: (value) => nbFormat.format(value ?? 0),
    },
  },
  {
    name: "attributes.fwe",
    label: "FWE",
    options: {
      customBodyRender: (value) => nbFormat.format(value ?? 0),
    },
  },
  {
    name: "attributes.ior",
    label: "IOR",
    options: {
      customBodyRender: (value) => nbFormat.format(value ?? 0),
    },
  },
  {
    name: "attributes.ldu",
    label: "LDU",
    options: {
      customBodyRender: (value) => nbFormat.format(value ?? 0),
    },
  },
  {
    name: "attributes.mru",
    label: "MRU",
    options: {
      customBodyRender: (value) => nbFormat.format(value ?? 0),
    },
  },
  {
    name: "attributes.ozd",
    label: "OZD",
    options: {
      customBodyRender: (value) => nbFormat.format(value ?? 0),
    },
  },
  {
    name: "attributes.pco",
    label: "PCO",
    options: {
      customBodyRender: (value) => nbFormat.format(value ?? 0),
    },
  },
  {
    name: "attributes.pma",
    label: "PMA",
    options: {
      customBodyRender: (value) => nbFormat.format(value ?? 0),
    },
  },
  {
    name: "attributes.swe",
    label: "SWE",
    options: {
      customBodyRender: (value) => nbFormat.format(value ?? 0),
    },
  },
  {
    name: "attributes.tre",
    label: "TRE",
    options: {
      customBodyRender: (value) => nbFormat.format(value ?? 0),
    },
  },
  {
    name: "attributes.wtu",
    label: "WTU",
    options: {
      customBodyRender: (value) => nbFormat.format(value ?? 0),
    },
  },
];

export const CatalogDetailMock = (() => {
  const data = [
    {
      campaignId: 1,
      type: "material",
      name: "Green Energy Solutions",
      description: "Catalog of solar panels, batteries, and wind turbines",
      fileProcessingStatus: {
        pending: [
          {
            fileId: 101,
            fileName: "green_energy_solutions.pdf",
            submittedOn: "2024-03-25T10:30:00Z",
          },
        ],
        success: [
          {
            fileId: 102,
            fileName: "solar_panel_technologies.csv",
            processedOn: "2024-03-26T14:45:00Z",
            productsIssued: [
              { productId: 101, productName: "Compact Solar Panels" },
              { productId: 102, productName: "Solar Batteries" },
            ],
          },
        ],
        problems: [
          {
            fileId: 103,
            fileName: "wind_turbine_specifications.docx",
            problem: "Data corruption detected",
            submittedOn: "2024-03-24T09:20:00Z",
          },
        ],
      },
    },
    {
      campaignId: 2,
      type: "clothes", // fabric
      name: "Eco-Friendly Textiles",
      description: "Catalog of biodegradable fabrics and sustainable dyes",
      fileProcessingStatus: {
        pending: [],
        success: [
          {
            fileId: 201,
            fileName: "biodegradable_packaging.csv",
            processedOn: "2024-03-23T16:00:00Z",
            productsIssued: [
              { productId: 201, productName: "Plant-based Packing Peanuts" },
              { productId: 202, productName: "Compostable Mailers" },
            ],
          },
          {
            fileId: 202,
            fileName: "eco_friendly_office_supplies.xlsx",
            processedOn: "2024-03-25T13:30:00Z",
            productsIssued: [
              { productId: 203, productName: "Bamboo Desk Organizers" },
              { productId: 204, productName: "Recycled Paper Notebooks" },
            ],
          },
        ],
        problems: [
          {
            fileId: 203,
            fileName: "sustainable_furniture_designs.pdf",
            problem: "Unsupported file size",
            submittedOn: "2024-03-22T11:45:00Z",
          },
        ],
      },
    },
    {
      campaignId: 3,
      type: "food",
      name: "Local Farm Produce",
      description: "Catalog of organic fruits, vegetables, and dairy products",
      fileProcessingStatus: {
        pending: [
          {
            fileId: 301,
            fileName: "local_farm_produce.pdf",
            submittedOn: "2024-03-27T10:15:00Z",
          },
          {
            fileId: 302,
            fileName: "organic_seeds_campaign.csv",
            submittedOn: "2024-03-27T10:20:00Z",
          },
        ],
        success: [
          {
            fileId: 303,
            fileName: "vegan_food_products.xlsx",
            processedOn: "2024-03-26T15:50:00Z",
            productsIssued: [
              { productId: 301, productName: "Almond Milk" },
              { productId: 302, productName: "Quinoa Burgers" },
            ],
          },
        ],
        problems: [
          {
            fileId: 304,
            fileName: "ecological_impact_of_fishing.docx",
            problem: "File format not supported",
            submittedOn: "2024-03-26T08:30:00Z",
          },
        ],
      },
    },
  ];

  return data[Math.floor(Math.random() * data.length)];
})();

export const CatalogDetailProductsMock = [
  {
    name: "Compact Solar Panels",
    status: "pending",
    pef: 5.486694088544668,
    wch: "",
    attributes: {
      fru: 278.87099761377505,
      fwe: 10.825383038014897,
      ior: 88.7969923164227,
      ldu: 10.193938619528776,
      mru: 84.3621541894544,
      ozd: 7.827726148637495,
      pco: 46.731518203783814,
      pma: 51.91505263582421,
      swe: 47.42943228835787,
      tre: 16.18915761410136,
      wtu: 899.6661860550835,
    },
  },
  {
    name: "Solar Batteries",
    status: "success",
    pef: 8.123456789,
    wch: "",
    attributes: {
      fru: 123.456789,
      fwe: 45.678901,
      ior: 67.890123,
      ldu: 89.012345,
      mru: 90.123456,
      ozd: 12.345678,
      pco: 34.56789,
      pma: 56.789012,
      swe: 78.901234,
      tre: 90.123456,
      wtu: 98.765432,
    },
  },
  {
    name: "Compostable Mailers",
    status: "problems",
    pef: 3.141592653,
    wch: "",
    attributes: {
      fru: 1.414213562,
      fwe: 2.236067977,
      ior: 3.16227766,
      ldu: 4.123105626,
      mru: 5.567764362,
      ozd: 6.283185307,
      pco: 7.071067812,
      pma: 8.246211251,
      swe: 9.486832981,
      tre: 10.81665383,
      wtu: 12.34567891,
    },
  },
];

import { Button } from '@mui/material';
import React from 'react';
import * as Icons from '@mui/icons-material';
import messages from './messages';

export const CatalogProductsColumns = (intl) => [
  {
    name: 'Image',
    label: intl.formatMessage(messages.product_list_image),
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value, tableMeta) => (
        <img
          src={require(`enl-images/shirts/shirt-${tableMeta.rowData[1].split(" ").pop()}.png`).default} // eslint-disable-line
          alt="T-Shirt image"
          width={50}
          height={50}
          style={{ minWidth: '50px' }}
        />
      ),
    },
  },
  {
    name: 'Nom',
    label: intl.formatMessage(messages.product_list_name),
    options: {
      filter: false,
    },
  },
  {
    name: 'Description courte',
    label: intl.formatMessage(messages.product_list_description),
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: 'Marque',
    label: intl.formatMessage(messages.product_list_brand),
    options: {
      filter: false,
    },
  },
  {
    name: 'attributes.cch_niveau',
    label: intl.formatMessage(messages.product_list_co2),
    options: {
      customBodyRender: (value) => (
        <img
          src={require(`enl-images/planetes/planete-${value.replace(/[^\d]/g, "")}.png`).default} // eslint-disable-line
          alt="Planete"
          width={50}
          height={50}
        />
      ),
      filter: false,
    },
  },
  {
    name: 'pef_niveau',
    label: intl.formatMessage(messages.product_env_level),
    options: {
      customBodyRender: (value) => (
        <img
          src={require(`enl-images/planetes/planete-${value.replace(/[^\d]/g, "")}.png`).default} // eslint-disable-line
          alt="Planete"
          width={50}
          height={50}
        />
      ),
      filter: false,
    },
  },
  {
    name: 'attributes.cch_affichage',
    label: intl.formatMessage(messages.product_list_co2_label),
    options: {
      filter: false,
    },
  },
  {
    name: 'attributes.PEF_affichage_detail',
    label: intl.formatMessage(messages.product_list_env_label),
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: 'aff_env_lettre',
    label: intl.formatMessage(messages.product_list_env_letter),
    options: {
      display: false,
      filterType: 'multiselect',
    },
  },
  {
    name: 'pef_niveau',
    label: intl.formatMessage(messages.product_list_env_level),
    options: {
      display: false,
      filterType: 'multiselect',
    },
  },
  {
    name: 'attributes.cch_niveau',
    label: intl.formatMessage(messages.product_list_co2_level),
    options: {
      display: false,
      filterType: 'multiselect',
    },
  },
  {
    name: 'actions',
    label: 'Actions',
    options: {
      display: 'excluded',
      filter: false,
      sort: false,
      empty: true,
      customBodyRender: () => (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            width: '100px',
          }}
        >
          <Button
            variant="text"
            color="primary"
            size="small"
            // onClick={(e) => console.log("update", e)}
          >
            <Icons.Edit />
          </Button>
          <Button
            variant="text"
            color="secondary"
            size="small"
            // onClick={(e) => console.log("delete", e)}
          >
            <Icons.Delete />
          </Button>
        </div>
      ),
    },
  },
];

export const CatalogProductsMock = [
  {
    'ID produit': '1',
    'Num produit': '1',
    Nom: 'Tee-shirt 1',
    Marque: 'Marque X',
    'Description courte': 'Coton - fabriqu\u00e9 en Chine',
    'Description longue': '',
    Label: 'Global Organic Textile Standard,Oeko Tex Label',
    'Logo (from Label)':
      'image_2023-03-15_114621318.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/plydAiTv9TgoIKkyHxFC5Q/PSqT4Ejb5g-U4tgNxQIvgvuTCTFYDeHUW-1r0clAJqebyBxK0QLAVe6F47xSYwTLSmlo--a7lhhMypSmAF6pY3rclq9AB6HBTWyUTwO1vsI7USv0QPtIoeiEYArc9WHWAL8OzRAe-as7baYqaCXj8RTSzikLRafNny3EvdNfLXo/9cuw9XjWlsV7FOxPTerJx2FJ_X9_bwDH6ffpUpB_Px0),image_2023-03-15_114155185.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/FZq6jK3h2zYhnlIM7j_OAw/RqVMQYC76s2cZWcwYloryRPVx_wRjBf23wUlr5h5VjSsV4mFvvwYXexlFxIipRCe4qJHHe2-W6Kgk8z3I5DhO2JKzO0w1YJEtmm7qAncpvjEDrUkINzQuBI1aJjoaQKL87BYP-JjXxEXPDIcCbb3BvJnuwUz3e6-WJ2RtwaoMqc/Q4wZXRclBhzneF1ZDZZjXky5Y9qXvOwJlBtWvsGpCZE)',
    URL: '',
    Prix: '\u20ac30.00',
    Image:
      'image_2023-02-21_154431304-removebg-preview.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/7Fqb1hZbt4Sf8yDRxw361w/fE7ifAfGqr-auNol4GaLlIdg6fuaZdc1TtSUTY89R98Ef7xAQVQLZToeJME3g72dy6Fl65FKekiQNM7OShlw_wjH_NScNm18_opf1USJGJqCIsTLmx0bNlzwGHnOhD1ZoqgcvI_4elIsLkC3tMQtaDfxZqXSAWNXDnhiyfzvUSvEkfm5e49AFFHHEIDWyGOe/4TJizr3dpZkYDw60AK21ocFSzt_5uOwexkIJlEEsIGo)',
    Catégorie_softr: 'Habillement',
    Catégorie: 'Textile',
    Type: 'Tee-shirt',
    wtu: '1106',
    wtu_picto:
      'image_2023-03-09_113253563.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/Tp5mq0CH0hDIz-R4Byzwzw/_EhCHERMBBISU6erycAlwHKN1BMUHAr_bleE7A9hotX3yhf_t7PhYJmnflU_-ibB3vwSdMxf65cx_xOX28I7kIaiunqt951ZSy8e1joVabEIWzlMjJH2eCibYCO6Qg1Bc_y6wP6WxriFdSyYeQb-zupwjk2j25YvO9lM5dPi7ac/PINf1tWNOYKVczsQ8zt-F5giwzb8HVv3Th85xyyBd8g)',
    pef_picto:
      'image_2023-03-09_113337288.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/BQbvMTJzkU8GzAKN4NL2JQ/0DNEpSjem-PIiiJXwhCV2VXhpbmePHc0ZJ-bzTjSA_F-xds8b6RtzUHTJBIDvYt-tOjc8pd6gnWTsAV489-51uE20FBm5QA85vWG11GsBMjAvirifVZ2e3n7u6ffTEJeIABphjilMCOUnSz2Beiu_LH8AMpbsd2W_3Ll1QDPLUc/mks6bu1Y4A0sc5mTpOOlEZU1nMK2yyXCbsTu92zWJ3E)',
    aff_env_lettre: 'C',
    affichage_environnemental:
      'image_2023-03-09_160849976.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/5XU3Jo-hXroOT7cu7Lf5BQ/Ihgb4Hyt2EVlIVAwb-rGhl-o2ZluSlg6owQzvVk59BEGi4kiDBjaABEYw0Cur6cIFNJDuiixnBlWIPzJn4gtjxKEYFDxI5oekU36bpAfEUelcREVbEXW9b_KfPVEP-cuqf4G21XQ42NSGLCS9xZid34VueWbEzowoZs6mcJlfrc/X2xGHzgJTuyZxvF-Iq-2fABseOGY7o9CyWF3_F723Vw)',
    pef_niveau: '1 plan\u00e8te',
    'Name (from pef_niveau)': '1 plan\u00e8te',
    pef_img_médaille:
      'planettes2-12.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/_M9H6rc9ajqUARqZBAN3lw/4P0vKT1eL3vUx4d_OLbQg-Nrllaayv8CVUNmAMKXFLcpsbJVLFky-VweC0qfdB_eSgkaBlFr9r4jgbcFIIiyij4MwwoBjndrLXoyGPWSIffk1AvhMJC3zuno6Hz6SENxyf0b4qknIpLoeEXRC4kAPw/n7r58wfXEj9OhV42EjTixvjhGTAx6lm5EGo505LnhKk)',
    'pef_description medaille': "Ce produit n'a pas de qualit\u00e9 particuli\u00e8re pour ce crit\u00e8re",
    'Name (from cch_niveau)': '1 plan\u00e8te',
    tracabilite:
      'tracabilite_teeshirt1.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/_ai0a_ZHw5-JHLsgNkFC8Q/GFnAdBSfv6g2WMWcbtQNAv-990o2k3YABsp7Kc9w_4LEO_UEl_i1b3c1gxmW5FolCOGHkE6dBkRERfnMj9K-0csRKGAMPzaw3iJO1yFyU3ewyhTHQQye7zUqpWkMfDdJ8I1w3ZDz9N1amkWn6eLz3B6Mx8tXtAdS4Esuv_wFCoM/8s8e09LAdXBJ8pug92liDlL9iSo7JK3nax7ghZFIdtQ)',
    vide: '',
    'URL popup':
      'https://images.bannerbear.com/direct/KDWmnAMxpAvM80dVk4/requests/000/035/483/098/nGPeXKvLJY7RGBnnQd81p93OM/e07557f241ebbd47fe6371c618738466083d2ea8.png',
    'IMG popup':
      'popup-teeshirt1.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/LTx5c4Cd3q6z1HXCiMIs-A/qnWh4RSL6iSHENmi_T0aAIL8B5QJQeIeioFUHOe9Bl9bGVGlJZl1gzAQ78wYuIzU-9xRM2aLK0Y89n6WRfWKH4sTgHm8Bo-dowhrKqNWReslMlqaYXA-yu5NC0cd0HIwzyEC-c_-BP8NHZLiDRuq-A/_tMg1hNE1YZoiRs5PSso0PiuSbD_4U9mBZG--mTRKwo)',
    attributes: {
      cch: {
        cch: '8',
        cch_km_voiture: "7.8 kg CO2e, c'est environ 50 km en voiture",
        cch_limite_planete:
          "Et c'est 1.5 j d'\u00e9missions \u00e9quivalentes dans un monde bas carbone (objectif 2050)",
        cch_mediane_cat: '6',
        cch_mediane_affichage: '50 % des produits \u00e9mettent plus de \t5.8 kg CO2e',
        cch_ecart_mediane: '34%',
        cch_ecart_affichage: "+34% d'empreinte carbone",
        cch_ecart_affichage_detail: 'Ce produit \u00e9met +34% par rapport \u00e0 la m\u00e9diane de sa cat\u00e9gorie',
        cch_min_cat: '3',
        cch_min_affichage: 'Le meilleur produit de cette cat\u00e9gorie \u00e9met 3.2 kg CO2e',
        cch_affichage: '7.8 kg CO2e',
        cch_affichage_detail: 'Ce produit \u00e9met             7.8 kg CO2e',
        cch_picto:
          '3222073.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/Cg7FbxZjzXNenRznTxruiQ/ej8LA0MFzFDAuonjmhtIGjM_jmUVdYVkgoE2z15gEdwzQp1HxHgjXg7DJB5zuk5qhuuXMvwSXVCYxxYHF3f8xXKqQWM-bAbPCfU0M6ThLn4i_xFHBFw11yad-AGnv9O9h4DnYf2ACDrqVrgmwefaNQ/k9z9DBziUBoeYW10m8F9yEueIYlgsz2zF6_03in2PGI)',
        cch_niveau: '1 plan\u00e8te',
        'cch_description medaille': "Ce produit n'a pas de qualit\u00e9 particuli\u00e8re pour ce crit\u00e8re",
        cch_img_médaille:
          'planettes2-12.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/_M9H6rc9ajqUARqZBAN3lw/4P0vKT1eL3vUx4d_OLbQg-Nrllaayv8CVUNmAMKXFLcpsbJVLFky-VweC0qfdB_eSgkaBlFr9r4jgbcFIIiyij4MwwoBjndrLXoyGPWSIffk1AvhMJC3zuno6Hz6SENxyf0b4qknIpLoeEXRC4kAPw/n7r58wfXEj9OhV42EjTixvjhGTAx6lm5EGo505LnhKk)',
      },
      cch_km_voiture: "7.8 kg CO2e, c'est environ 50 km en voiture",
      cch_limite_planete: "Et c'est 1.5 j d'\u00e9missions \u00e9quivalentes dans un monde bas carbone (objectif 2050)",
      cch_mediane_cat: '6',
      cch_mediane_affichage: '50 % des produits \u00e9mettent plus de \t5.8 kg CO2e',
      cch_ecart_mediane: '34%',
      cch_ecart_affichage: "+34% d'empreinte carbone",
      cch_ecart_affichage_detail: 'Ce produit \u00e9met +34% par rapport \u00e0 la m\u00e9diane de sa cat\u00e9gorie',
      cch_min_cat: '3',
      cch_min_affichage: 'Le meilleur produit de cette cat\u00e9gorie \u00e9met 3.2 kg CO2e',
      cch_affichage: '7.8 kg CO2e',
      cch_affichage_detail: 'Ce produit \u00e9met             7.8 kg CO2e',
      cch_picto:
        '3222073.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/Cg7FbxZjzXNenRznTxruiQ/ej8LA0MFzFDAuonjmhtIGjM_jmUVdYVkgoE2z15gEdwzQp1HxHgjXg7DJB5zuk5qhuuXMvwSXVCYxxYHF3f8xXKqQWM-bAbPCfU0M6ThLn4i_xFHBFw11yad-AGnv9O9h4DnYf2ACDrqVrgmwefaNQ/k9z9DBziUBoeYW10m8F9yEueIYlgsz2zF6_03in2PGI)',
      cch_niveau: '1 plan\u00e8te',
      'cch_description medaille': "Ce produit n'a pas de qualit\u00e9 particuli\u00e8re pour ce crit\u00e8re",
      cch_img_médaille:
        'planettes2-12.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/_M9H6rc9ajqUARqZBAN3lw/4P0vKT1eL3vUx4d_OLbQg-Nrllaayv8CVUNmAMKXFLcpsbJVLFky-VweC0qfdB_eSgkaBlFr9r4jgbcFIIiyij4MwwoBjndrLXoyGPWSIffk1AvhMJC3zuno6Hz6SENxyf0b4qknIpLoeEXRC4kAPw/n7r58wfXEj9OhV42EjTixvjhGTAx6lm5EGo505LnhKk)',
      'Pays - Etape 1': 'CN',
      'Pays - Etape 2': 'CN',
      'Pays - Etape 3': 'CN',
      'Pays - Etape 4': 'CN',
      'Pays - Etape 5': 'CN',
      'Pays - Etape 6': 'FR',
      'Pays - Etape 7': 'FR',
      'Pays - Etape 8': 'FR',
      country_step: {
        'Pays - Etape 1': 'CN',
        'Pays - Etape 2': 'CN',
        'Pays - Etape 3': 'CN',
        'Pays - Etape 4': 'CN',
        'Pays - Etape 5': 'CN',
        'Pays - Etape 6': 'FR',
        'Pays - Etape 7': 'FR',
        'Pays - Etape 8': 'FR',
      },
      PEF: '983',
      PEF_affichage_detail: 'Ce produit vaut 980 \u00b5Pt',
      PEF_limite_planete:
        "980 \u00b5Pt, c'est l'\u00e9quivalent de 9 heures de l'empreinte annuelle d'un europ\u00e9en",
      PEF_mediane_cat: '758',
      PEF_mediane_affichage: '50 % des produits valent plus de 760 \u00b5Pt',
      PEF_ecart_mediane: '23%',
      PEF_ecart_affichage: "+23% d'empreinte environnementale",
      PEF_ecart_affichage_detail: 'Ce produit \u00e9met +23% par rapport \u00e0 la m\u00e9diane de sa cat\u00e9gorie',
      PEF_min_cat: '493',
      PEF_min_affichage: 'Le meilleur produit de cette cat\u00e9gorie vaut 490 \u00b5Pt',
      pef: {
        PEF: '983',
        PEF_affichage_detail: 'Ce produit vaut 980 \u00b5Pt',
        PEF_limite_planete:
          "980 \u00b5Pt, c'est l'\u00e9quivalent de 9 heures de l'empreinte annuelle d'un europ\u00e9en",
        PEF_mediane_cat: '758',
        PEF_mediane_affichage: '50 % des produits valent plus de 760 \u00b5Pt',
        PEF_ecart_mediane: '23%',
        PEF_ecart_affichage: "+23% d'empreinte environnementale",
        PEF_ecart_affichage_detail: 'Ce produit \u00e9met +23% par rapport \u00e0 la m\u00e9diane de sa cat\u00e9gorie',
        PEF_min_cat: '493',
        PEF_min_affichage: 'Le meilleur produit de cette cat\u00e9gorie vaut 490 \u00b5Pt',
      },
    },
  },
  {
    'ID produit': '2',
    'Num produit': '2',
    Nom: 'Tee-shirt 2',
    Marque: 'Marque X',
    'Description courte': 'Polyester - fabriqu\u00e9 en Chine',
    'Description longue': '',
    Label: 'Oeko Tex Label',
    'Logo (from Label)':
      'image_2023-03-15_114155185.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/FZq6jK3h2zYhnlIM7j_OAw/RqVMQYC76s2cZWcwYloryRPVx_wRjBf23wUlr5h5VjSsV4mFvvwYXexlFxIipRCe4qJHHe2-W6Kgk8z3I5DhO2JKzO0w1YJEtmm7qAncpvjEDrUkINzQuBI1aJjoaQKL87BYP-JjXxEXPDIcCbb3BvJnuwUz3e6-WJ2RtwaoMqc/Q4wZXRclBhzneF1ZDZZjXky5Y9qXvOwJlBtWvsGpCZE)',
    URL: '',
    Prix: '\u20ac20.00',
    Image:
      'image_2023-02-21_154624610-removebg-preview.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/1OSSBdW-ri4AT9J74PQnNw/lAaxARJb1Swh2U8ngYAITIC1dFBIQ-h_Hwpf1JFYW-pwrYBzkGSzfQYE53ZuY_KMQX7wDkT1oxgdBuYCcdGt2y5teVDLj_pOJQhs7Gn-LARGhdo-2_SKUs7XZHhzvDWqqaYiItETsQy46xUKBX1gTe8zUWYDSQv9vHOL2YpmG32SVqDvO2SxgyJTbdC08MC3/F-d2IUFhYWBTtEoi7imqJopioUN74OHjEhrXqxwEaHs)',
    Catégorie_softr: 'Habillement,Meuble',
    Catégorie: 'Textile',
    Type: 'Tee-shirt',
    wtu: '169',
    wtu_picto:
      'image_2023-03-09_113253563.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/Tp5mq0CH0hDIz-R4Byzwzw/_EhCHERMBBISU6erycAlwHKN1BMUHAr_bleE7A9hotX3yhf_t7PhYJmnflU_-ibB3vwSdMxf65cx_xOX28I7kIaiunqt951ZSy8e1joVabEIWzlMjJH2eCibYCO6Qg1Bc_y6wP6WxriFdSyYeQb-zupwjk2j25YvO9lM5dPi7ac/PINf1tWNOYKVczsQ8zt-F5giwzb8HVv3Th85xyyBd8g)',
    pef_picto:
      'image_2023-03-09_113337288.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/BQbvMTJzkU8GzAKN4NL2JQ/0DNEpSjem-PIiiJXwhCV2VXhpbmePHc0ZJ-bzTjSA_F-xds8b6RtzUHTJBIDvYt-tOjc8pd6gnWTsAV489-51uE20FBm5QA85vWG11GsBMjAvirifVZ2e3n7u6ffTEJeIABphjilMCOUnSz2Beiu_LH8AMpbsd2W_3Ll1QDPLUc/mks6bu1Y4A0sc5mTpOOlEZU1nMK2yyXCbsTu92zWJ3E)',
    aff_env_lettre: 'C',
    affichage_environnemental:
      'image_2023-03-09_160909655.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/wxz4XLhcRCwQNB7y5p1qMQ/AAnyxqE9f19AyL-sVZLXQFyqlUa8eG0yBlF5f8yrOIokMu8DGA-NpJlMN1ocD-iQSoIPeqt9rssgD-j1fiX-FNespJqrCl0_yxvISFpHfME0f-lFTWQ4l0RXWWERgUmmDRoHWfc7pBfzT6fb6n26TTgsvVyVeLay8-Jnv4JCVi0/J-cG8fEsPue6JX5vVYkyXBXLYydLkMS3CVlvGupPFNM)',
    pef_niveau: '1 plan\u00e8te',
    'Name (from pef_niveau)': '1 plan\u00e8te',
    pef_img_médaille:
      'planettes2-12.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/_M9H6rc9ajqUARqZBAN3lw/4P0vKT1eL3vUx4d_OLbQg-Nrllaayv8CVUNmAMKXFLcpsbJVLFky-VweC0qfdB_eSgkaBlFr9r4jgbcFIIiyij4MwwoBjndrLXoyGPWSIffk1AvhMJC3zuno6Hz6SENxyf0b4qknIpLoeEXRC4kAPw/n7r58wfXEj9OhV42EjTixvjhGTAx6lm5EGo505LnhKk)',
    'pef_description medaille': "Ce produit n'a pas de qualit\u00e9 particuli\u00e8re pour ce crit\u00e8re",
    'Name (from cch_niveau)': '1 plan\u00e8te',
    tracabilite:
      'tracabilite_teeshirt2.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/NgVeaAFApfcJuX_9J1yWTA/GSlaXkzeuoSZwhwDmmzUTdYYiu2Mcv-jhEgMRSH9sByJzuJ0gKMI0sD-vp6NxorTDO7HKXIV48sgo-Etf5u5n6i666l9e4Wf_Zhim2R4FoY1XBBaHfHhjCY4BEII5hxjn9v_2IwvW-KshJcRn3bChvxytEhEuj672KOGEIpwJUk/p5mSXh_MOIoy5RrXfWVD8M4s0THznRACpgL8d-QErF0)',
    vide: '',
    'URL popup':
      'https://images.bannerbear.com/direct/KDWmnAMxpAvM80dVk4/requests/000/035/483/095/jMVrobL3AQ5BMRn4YG9ReqJW5/7c25dfe56b212075a88883235fed71440f7ca210.png',
    'IMG popup':
      'popup-teeshirt2.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/ef8TqQ5rY2fZp0RKKvqlFQ/Suwnc7gNy4a649JiX4QM9IvxEUMFQF-GefkkaIybeG-_yvqC2MUZ5ARW1lrFW9uT9pDovjKghFt7cws-p5Tn_Bb5QU3yKDVLuF3FXszI9nld2aTHbG6PXDYZ_-_goDImOgUuz3wnOnUOg6P9nHtorQ/_4cdJ51mB9jqbkvmGJjpoJv_JvT8J9_4tvsP3GYBfdU)',
    attributes: {
      cch: {
        cch: '7',
        cch_km_voiture: "6.5 kg CO2e, c'est environ 40 km en voiture",
        cch_limite_planete: "Et c'est 1 j d'\u00e9missions \u00e9quivalentes dans un monde bas carbone (objectif 2050)",
        cch_mediane_cat: '6',
        cch_mediane_affichage: '50 % des produits \u00e9mettent plus de \t5.8 kg CO2e',
        cch_ecart_mediane: '11%',
        cch_ecart_affichage: "+11% d'empreinte carbone",
        cch_ecart_affichage_detail: 'Ce produit \u00e9met +11% par rapport \u00e0 la m\u00e9diane de sa cat\u00e9gorie',
        cch_min_cat: '3',
        cch_min_affichage: 'Le meilleur produit de cette cat\u00e9gorie \u00e9met 3.2 kg CO2e',
        cch_affichage: '6.5 kg CO2e',
        cch_affichage_detail: 'Ce produit \u00e9met             6.5 kg CO2e',
        cch_picto:
          '3222073.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/Cg7FbxZjzXNenRznTxruiQ/ej8LA0MFzFDAuonjmhtIGjM_jmUVdYVkgoE2z15gEdwzQp1HxHgjXg7DJB5zuk5qhuuXMvwSXVCYxxYHF3f8xXKqQWM-bAbPCfU0M6ThLn4i_xFHBFw11yad-AGnv9O9h4DnYf2ACDrqVrgmwefaNQ/k9z9DBziUBoeYW10m8F9yEueIYlgsz2zF6_03in2PGI)',
        cch_niveau: '1 plan\u00e8te',
        'cch_description medaille': "Ce produit n'a pas de qualit\u00e9 particuli\u00e8re pour ce crit\u00e8re",
        cch_img_médaille:
          'planettes2-12.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/_M9H6rc9ajqUARqZBAN3lw/4P0vKT1eL3vUx4d_OLbQg-Nrllaayv8CVUNmAMKXFLcpsbJVLFky-VweC0qfdB_eSgkaBlFr9r4jgbcFIIiyij4MwwoBjndrLXoyGPWSIffk1AvhMJC3zuno6Hz6SENxyf0b4qknIpLoeEXRC4kAPw/n7r58wfXEj9OhV42EjTixvjhGTAx6lm5EGo505LnhKk)',
      },
      cch_km_voiture: "6.5 kg CO2e, c'est environ 40 km en voiture",
      cch_limite_planete: "Et c'est 1 j d'\u00e9missions \u00e9quivalentes dans un monde bas carbone (objectif 2050)",
      cch_mediane_cat: '6',
      cch_mediane_affichage: '50 % des produits \u00e9mettent plus de \t5.8 kg CO2e',
      cch_ecart_mediane: '11%',
      cch_ecart_affichage: "+11% d'empreinte carbone",
      cch_ecart_affichage_detail: 'Ce produit \u00e9met +11% par rapport \u00e0 la m\u00e9diane de sa cat\u00e9gorie',
      cch_min_cat: '3',
      cch_min_affichage: 'Le meilleur produit de cette cat\u00e9gorie \u00e9met 3.2 kg CO2e',
      cch_affichage: '6.5 kg CO2e',
      cch_affichage_detail: 'Ce produit \u00e9met             6.5 kg CO2e',
      cch_picto:
        '3222073.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/Cg7FbxZjzXNenRznTxruiQ/ej8LA0MFzFDAuonjmhtIGjM_jmUVdYVkgoE2z15gEdwzQp1HxHgjXg7DJB5zuk5qhuuXMvwSXVCYxxYHF3f8xXKqQWM-bAbPCfU0M6ThLn4i_xFHBFw11yad-AGnv9O9h4DnYf2ACDrqVrgmwefaNQ/k9z9DBziUBoeYW10m8F9yEueIYlgsz2zF6_03in2PGI)',
      cch_niveau: '1 plan\u00e8te',
      'cch_description medaille': "Ce produit n'a pas de qualit\u00e9 particuli\u00e8re pour ce crit\u00e8re",
      cch_img_médaille:
        'planettes2-12.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/_M9H6rc9ajqUARqZBAN3lw/4P0vKT1eL3vUx4d_OLbQg-Nrllaayv8CVUNmAMKXFLcpsbJVLFky-VweC0qfdB_eSgkaBlFr9r4jgbcFIIiyij4MwwoBjndrLXoyGPWSIffk1AvhMJC3zuno6Hz6SENxyf0b4qknIpLoeEXRC4kAPw/n7r58wfXEj9OhV42EjTixvjhGTAx6lm5EGo505LnhKk)',
      'Pays - Etape 1': 'CN',
      'Pays - Etape 2': 'CN',
      'Pays - Etape 3': 'CN',
      'Pays - Etape 4': 'CN',
      'Pays - Etape 5': 'CN',
      'Pays - Etape 6': 'FR',
      'Pays - Etape 7': 'FR',
      'Pays - Etape 8': 'FR',
      country_step: {
        'Pays - Etape 1': 'CN',
        'Pays - Etape 2': 'CN',
        'Pays - Etape 3': 'CN',
        'Pays - Etape 4': 'CN',
        'Pays - Etape 5': 'CN',
        'Pays - Etape 6': 'FR',
        'Pays - Etape 7': 'FR',
        'Pays - Etape 8': 'FR',
      },
      PEF: '803',
      PEF_affichage_detail: 'Ce produit vaut 800 \u00b5Pt',
      PEF_limite_planete:
        "800 \u00b5Pt, c'est l'\u00e9quivalent de 7 heures de l'empreinte annuelle d'un europ\u00e9en",
      PEF_mediane_cat: '758',
      PEF_mediane_affichage: '50 % des produits valent plus de 760 \u00b5Pt',
      PEF_ecart_mediane: '6%',
      PEF_ecart_affichage: "+6% d'empreinte environnementale",
      PEF_ecart_affichage_detail: 'Ce produit \u00e9met +6% par rapport \u00e0 la m\u00e9diane de sa cat\u00e9gorie',
      PEF_min_cat: '493',
      PEF_min_affichage: 'Le meilleur produit de cette cat\u00e9gorie vaut 490 \u00b5Pt',
      pef: {
        PEF: '803',
        PEF_affichage_detail: 'Ce produit vaut 800 \u00b5Pt',
        PEF_limite_planete:
          "800 \u00b5Pt, c'est l'\u00e9quivalent de 7 heures de l'empreinte annuelle d'un europ\u00e9en",
        PEF_mediane_cat: '758',
        PEF_mediane_affichage: '50 % des produits valent plus de 760 \u00b5Pt',
        PEF_ecart_mediane: '6%',
        PEF_ecart_affichage: "+6% d'empreinte environnementale",
        PEF_ecart_affichage_detail: 'Ce produit \u00e9met +6% par rapport \u00e0 la m\u00e9diane de sa cat\u00e9gorie',
        PEF_min_cat: '493',
        PEF_min_affichage: 'Le meilleur produit de cette cat\u00e9gorie vaut 490 \u00b5Pt',
      },
    },
  },
  {
    'ID produit': '3',
    'Num produit': '3',
    Nom: 'Tee-shirt 3',
    Marque: 'Marque X',
    'Description courte': 'Coton recycl\u00e9 - fabriqu\u00e9 en Chine',
    'Description longue': '',
    Label: 'Global Recycled Standard',
    'Logo (from Label)':
      'image_2023-03-15_114050784.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/-tq4_nXb8vOxprYkKAhFMg/d7ZKNpv5RyJzLWV76kE37h4mRWxWa4HpGTs0eOXZRf3Tb6A-9RbZRmcPvzFWkbJvWX8925P-y-nGAQMBd_zWA4_cHaTKysKTD_XPOH1mqqbJS0Q0jc0MVMcahM0pISYfYzDZ2Elm6SzePZEpC33YW8UOax_FecvFlSWZBBXzZWs/rsy_IgItu7JqrEA22fY_bkWvhh5_k6H2EDRlGHCZO_8)',
    URL: '',
    Prix: '\u20ac25.00',
    Image:
      'image_2023-02-23_105831833-removebg-preview.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/Atpd-p3Zl_t5j1I2zNE2Pg/Sxp4axJbo8RQuNun1dbbY2q-1Qq2HEukrqQwPU8tLSfepBmxnWKfkXWXW1YDjrHodyZhMnXe1HtTPnA-27d09B8ys6sKfg3ZM5Rj1FbS7XI0z1rUOMKRvZf8ti_G3h5UpYts9j9IJp3DyYImXd3jHTU69-jcKQSnW_eLQ3by8pkRuB9IriRvP8YrnnTikOnJ/DDkAxGvxKAbxdu8T_H4jj_uxHBoAl9JG5_M56t34wS0)',
    Catégorie_softr: 'Habillement,Tourisme',
    Catégorie: 'Textile',
    Type: 'Tee-shirt',
    wtu: '111',
    wtu_picto:
      'image_2023-03-09_113253563.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/Tp5mq0CH0hDIz-R4Byzwzw/_EhCHERMBBISU6erycAlwHKN1BMUHAr_bleE7A9hotX3yhf_t7PhYJmnflU_-ibB3vwSdMxf65cx_xOX28I7kIaiunqt951ZSy8e1joVabEIWzlMjJH2eCibYCO6Qg1Bc_y6wP6WxriFdSyYeQb-zupwjk2j25YvO9lM5dPi7ac/PINf1tWNOYKVczsQ8zt-F5giwzb8HVv3Th85xyyBd8g)',
    pef_picto:
      'image_2023-03-09_113337288.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/BQbvMTJzkU8GzAKN4NL2JQ/0DNEpSjem-PIiiJXwhCV2VXhpbmePHc0ZJ-bzTjSA_F-xds8b6RtzUHTJBIDvYt-tOjc8pd6gnWTsAV489-51uE20FBm5QA85vWG11GsBMjAvirifVZ2e3n7u6ffTEJeIABphjilMCOUnSz2Beiu_LH8AMpbsd2W_3Ll1QDPLUc/mks6bu1Y4A0sc5mTpOOlEZU1nMK2yyXCbsTu92zWJ3E)',
    aff_env_lettre: 'C',
    affichage_environnemental:
      'image_2023-03-09_160924503.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/9vLNCcUJ6oJizTKzClBpQQ/MhuJJaasokx3KdmmV_GJjHMx70rq_sF2OBUrfc9xK8jtGAQSjAEWnTFZEi_4aVkpnMvY-u2_Qkozug2e_Skg-zbto8iZr0THSrb3cC8d11URT1IQ20eSjzMT7SOHI5gFIvSYpKjcMydsFNjfze4KE-LUMvzI3wy7n8rGA92S7XI/cQ1n_MlrQ840wszPpWvVewo_1dUwB0AROG166VDKgWc)',
    pef_niveau: '2 plan\u00e8tes',
    'Name (from pef_niveau)': '2 plan\u00e8tes',
    pef_img_médaille:
      'planettes2-11.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/R66-AtqV295Qa2JtdkyRvw/IWepgFSNhQWt4zlKKakQKD6FUwqKoqOkgaqvKqlnMBtiqkWBSnY9pk4-9VcChvs3S02nLgQBcucFLnJisjRb6QlIGWWwNK624Htsf7y5AS3J3XsAB2VUtphGiLMu3FqaIKsWdxwCSsG90Mq74K2DPA/W0WIuQxot0HuYxOprYdyWnhmxjbV_k3UhLMqoG3xFSQ)',
    'pef_description medaille': 'Ce produit fait partie des 50% des meilleurs de sa cat\u00e9gorie',
    'Name (from cch_niveau)': '2 plan\u00e8tes',
    tracabilite:
      'tracabilite_teeshirt3.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/vfUzA2raeoswIEAF3_L3hQ/hGyeSFVkUXGxrsLJMqzEHcHK5mhtSPdF3luu6aPYvSX96qm5u1lELuHT3oG6wYMTbih_sCRRURgjDFVGXSMaz9KStoqAJicPOokF7HmZumZ9AU3KJCkeEoBW3ZtVEjKmOI5N2KDBoOexYykMmPNoDr6q1N4W8Q0DxmCnGHgZdIo/CfRdc_X4PSUGty857kDU2JgbbcTs2OLJolZlXIIlhvo)',
    vide: '',
    'URL popup':
      'https://images.bannerbear.com/direct/KDWmnAMxpAvM80dVk4/requests/000/035/483/094/kW7yv9eBdzpPmZpDzNLRwa5Px/0ddbead5c6f6b203f5467d77b80fcf2caf361b01.png',
    'IMG popup':
      'popup-teeshirt3.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/Sdr3Om8fTzP0nV2UjnbHQg/n2Aq6OSP01EhKZZ_5-3zmD_WIPImhFo3Dc2BC4Ie9oIStyiBFnxcCEXKN7Z7l4H54Qxo4NznXCIEqcUJTBfiZFPwF2yMJnKuBz2okByWF46MU6qQ-rXgGvzqSZON7OBFMPBAm4wJOqYJ8sZEMkFHBw/cL_fUdyZszg_7PPsJOjQUWCOZNSuHwBozs5yxzkIybk)',
    attributes: {
      cch: {
        cch: '6',
        cch_km_voiture: "5.7 kg CO2e, c'est environ 40 km en voiture",
        cch_limite_planete: "Et c'est 1 j d'\u00e9missions \u00e9quivalentes dans un monde bas carbone (objectif 2050)",
        cch_mediane_cat: '6',
        cch_mediane_affichage: '50 % des produits \u00e9mettent plus de \t5.8 kg CO2e',
        cch_ecart_mediane: '-3%',
        cch_ecart_affichage: "-3% d'empreinte carbone",
        cch_ecart_affichage_detail: 'Ce produit \u00e9met -3% par rapport \u00e0 la m\u00e9diane de sa cat\u00e9gorie',
        cch_min_cat: '3',
        cch_min_affichage: 'Le meilleur produit de cette cat\u00e9gorie \u00e9met 3.2 kg CO2e',
        cch_affichage: '5.7 kg CO2e',
        cch_affichage_detail: 'Ce produit \u00e9met             5.7 kg CO2e',
        cch_picto:
          '3222073.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/Cg7FbxZjzXNenRznTxruiQ/ej8LA0MFzFDAuonjmhtIGjM_jmUVdYVkgoE2z15gEdwzQp1HxHgjXg7DJB5zuk5qhuuXMvwSXVCYxxYHF3f8xXKqQWM-bAbPCfU0M6ThLn4i_xFHBFw11yad-AGnv9O9h4DnYf2ACDrqVrgmwefaNQ/k9z9DBziUBoeYW10m8F9yEueIYlgsz2zF6_03in2PGI)',
        cch_niveau: '2 plan\u00e8tes',
        'cch_description medaille': 'Ce produit fait partie des 50% des meilleurs de sa cat\u00e9gorie',
        cch_img_médaille:
          'planettes2-11.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/R66-AtqV295Qa2JtdkyRvw/IWepgFSNhQWt4zlKKakQKD6FUwqKoqOkgaqvKqlnMBtiqkWBSnY9pk4-9VcChvs3S02nLgQBcucFLnJisjRb6QlIGWWwNK624Htsf7y5AS3J3XsAB2VUtphGiLMu3FqaIKsWdxwCSsG90Mq74K2DPA/W0WIuQxot0HuYxOprYdyWnhmxjbV_k3UhLMqoG3xFSQ)',
      },
      cch_km_voiture: "5.7 kg CO2e, c'est environ 40 km en voiture",
      cch_limite_planete: "Et c'est 1 j d'\u00e9missions \u00e9quivalentes dans un monde bas carbone (objectif 2050)",
      cch_mediane_cat: '6',
      cch_mediane_affichage: '50 % des produits \u00e9mettent plus de \t5.8 kg CO2e',
      cch_ecart_mediane: '-3%',
      cch_ecart_affichage: "-3% d'empreinte carbone",
      cch_ecart_affichage_detail: 'Ce produit \u00e9met -3% par rapport \u00e0 la m\u00e9diane de sa cat\u00e9gorie',
      cch_min_cat: '3',
      cch_min_affichage: 'Le meilleur produit de cette cat\u00e9gorie \u00e9met 3.2 kg CO2e',
      cch_affichage: '5.7 kg CO2e',
      cch_affichage_detail: 'Ce produit \u00e9met             5.7 kg CO2e',
      cch_picto:
        '3222073.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/Cg7FbxZjzXNenRznTxruiQ/ej8LA0MFzFDAuonjmhtIGjM_jmUVdYVkgoE2z15gEdwzQp1HxHgjXg7DJB5zuk5qhuuXMvwSXVCYxxYHF3f8xXKqQWM-bAbPCfU0M6ThLn4i_xFHBFw11yad-AGnv9O9h4DnYf2ACDrqVrgmwefaNQ/k9z9DBziUBoeYW10m8F9yEueIYlgsz2zF6_03in2PGI)',
      cch_niveau: '2 plan\u00e8tes',
      'cch_description medaille': 'Ce produit fait partie des 50% des meilleurs de sa cat\u00e9gorie',
      cch_img_médaille:
        'planettes2-11.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/R66-AtqV295Qa2JtdkyRvw/IWepgFSNhQWt4zlKKakQKD6FUwqKoqOkgaqvKqlnMBtiqkWBSnY9pk4-9VcChvs3S02nLgQBcucFLnJisjRb6QlIGWWwNK624Htsf7y5AS3J3XsAB2VUtphGiLMu3FqaIKsWdxwCSsG90Mq74K2DPA/W0WIuQxot0HuYxOprYdyWnhmxjbV_k3UhLMqoG3xFSQ)',
      'Pays - Etape 1': 'FR',
      'Pays - Etape 2': 'CN',
      'Pays - Etape 3': 'CN',
      'Pays - Etape 4': 'CN',
      'Pays - Etape 5': 'CN',
      'Pays - Etape 6': 'FR',
      'Pays - Etape 7': 'FR',
      'Pays - Etape 8': 'FR',
      country_step: {
        'Pays - Etape 1': 'FR',
        'Pays - Etape 2': 'CN',
        'Pays - Etape 3': 'CN',
        'Pays - Etape 4': 'CN',
        'Pays - Etape 5': 'CN',
        'Pays - Etape 6': 'FR',
        'Pays - Etape 7': 'FR',
        'Pays - Etape 8': 'FR',
      },
      PEF: '758',
      PEF_affichage_detail: 'Ce produit vaut 760 \u00b5Pt',
      PEF_limite_planete:
        "760 \u00b5Pt, c'est l'\u00e9quivalent de 7 heures de l'empreinte annuelle d'un europ\u00e9en",
      PEF_mediane_cat: '758',
      PEF_mediane_affichage: '50 % des produits valent plus de 760 \u00b5Pt',
      PEF_ecart_mediane: '0%',
      PEF_ecart_affichage: "0% d'empreinte environnementale",
      PEF_ecart_affichage_detail: 'Ce produit \u00e9met 0% par rapport \u00e0 la m\u00e9diane de sa cat\u00e9gorie',
      PEF_min_cat: '493',
      PEF_min_affichage: 'Le meilleur produit de cette cat\u00e9gorie vaut 490 \u00b5Pt',
      pef: {
        PEF: '758',
        PEF_affichage_detail: 'Ce produit vaut 760 \u00b5Pt',
        PEF_limite_planete:
          "760 \u00b5Pt, c'est l'\u00e9quivalent de 7 heures de l'empreinte annuelle d'un europ\u00e9en",
        PEF_mediane_cat: '758',
        PEF_mediane_affichage: '50 % des produits valent plus de 760 \u00b5Pt',
        PEF_ecart_mediane: '0%',
        PEF_ecart_affichage: "0% d'empreinte environnementale",
        PEF_ecart_affichage_detail: 'Ce produit \u00e9met 0% par rapport \u00e0 la m\u00e9diane de sa cat\u00e9gorie',
        PEF_min_cat: '493',
        PEF_min_affichage: 'Le meilleur produit de cette cat\u00e9gorie vaut 490 \u00b5Pt',
      },
    },
  },
  {
    'ID produit': '4',
    'Num produit': '4',
    Nom: 'Tee-shirt 4',
    Marque: 'Marque X',
    'Description courte': 'Coton - fabriqu\u00e9 au Portugal',
    'Description longue': '',
    Label: 'Oeko Tex Label,Oeko Tex Label Made in Green,Global Organic Textile Standard',
    'Logo (from Label)':
      'image_2023-03-15_114155185.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/FZq6jK3h2zYhnlIM7j_OAw/RqVMQYC76s2cZWcwYloryRPVx_wRjBf23wUlr5h5VjSsV4mFvvwYXexlFxIipRCe4qJHHe2-W6Kgk8z3I5DhO2JKzO0w1YJEtmm7qAncpvjEDrUkINzQuBI1aJjoaQKL87BYP-JjXxEXPDIcCbb3BvJnuwUz3e6-WJ2RtwaoMqc/Q4wZXRclBhzneF1ZDZZjXky5Y9qXvOwJlBtWvsGpCZE),image_2023-03-15_114455758.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/rjm9Fedk3enc4EOBS6KhLQ/JO3wXlCmVLd_cBLC5HI_cFoZ2bbu6bVG6SafcMUbWkLE9pAA58KbO479WmR_Gp1UDOS20YIEP91oOvIiTMmg0TLemkPppu7e5Oe_YFM-lAOEmLAOAOEcl7LUSJnPNrvq-yIDNyz2TWQz35u8ejR2lAMV_MoxdCYWAuQUlX4gUXU/43CnIaZz754zA5haitRU189UR5i-qT_wqAqfp7M4Zl0),image_2023-03-15_114621318.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/plydAiTv9TgoIKkyHxFC5Q/PSqT4Ejb5g-U4tgNxQIvgvuTCTFYDeHUW-1r0clAJqebyBxK0QLAVe6F47xSYwTLSmlo--a7lhhMypSmAF6pY3rclq9AB6HBTWyUTwO1vsI7USv0QPtIoeiEYArc9WHWAL8OzRAe-as7baYqaCXj8RTSzikLRafNny3EvdNfLXo/9cuw9XjWlsV7FOxPTerJx2FJ_X9_bwDH6ffpUpB_Px0)',
    URL: '',
    Prix: '\u20ac40.00',
    Image:
      'image_2023-02-23_105948127-removebg-preview.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/5blNP0v8ZHhn3sObV4DkMg/Ey6r8wIkGwMNS3-FwrDFRuQ1sHra7Jc89KVGyzdYEgkZItMoEjNM70IXj6HbsHLU0oj3YtWSO9m8tc8-GUBhjjo-Rn6fQBnVRXwiYK7igd3AJILp5_hftZdO9T55NkOWZ7dOMwN0nczyZ7oA1BNblVnijJxNh46hNxDJeh06lnqxBdTpT6lwVSfqK9IzSJbs/6xR-zojgEuV_AgRBQUFO1SrAbg76_kcDzt6FUa1sEyo)',
    Catégorie_softr: 'Habillement',
    Catégorie: 'Textile',
    Type: 'Tee-shirt',
    wtu: '1106',
    wtu_picto:
      'image_2023-03-09_113253563.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/Tp5mq0CH0hDIz-R4Byzwzw/_EhCHERMBBISU6erycAlwHKN1BMUHAr_bleE7A9hotX3yhf_t7PhYJmnflU_-ibB3vwSdMxf65cx_xOX28I7kIaiunqt951ZSy8e1joVabEIWzlMjJH2eCibYCO6Qg1Bc_y6wP6WxriFdSyYeQb-zupwjk2j25YvO9lM5dPi7ac/PINf1tWNOYKVczsQ8zt-F5giwzb8HVv3Th85xyyBd8g)',
    pef_picto:
      'image_2023-03-09_113337288.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/BQbvMTJzkU8GzAKN4NL2JQ/0DNEpSjem-PIiiJXwhCV2VXhpbmePHc0ZJ-bzTjSA_F-xds8b6RtzUHTJBIDvYt-tOjc8pd6gnWTsAV489-51uE20FBm5QA85vWG11GsBMjAvirifVZ2e3n7u6ffTEJeIABphjilMCOUnSz2Beiu_LH8AMpbsd2W_3Ll1QDPLUc/mks6bu1Y4A0sc5mTpOOlEZU1nMK2yyXCbsTu92zWJ3E)',
    aff_env_lettre: 'C',
    affichage_environnemental:
      'image_2023-03-09_160930087.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/WGnIceAMGPQ02dCEN7LCDg/BdO60QHWvtZTUKce_6CTSVKTycnHHZXhLRw6Q9w6FldR4WhXJJNJsSKjFRbOJKkpI45c2xuFgzPYiN5ZKbaoN1Jpk0h2mqH-RNOQcOcwcCxPDKpW7x0lWCxNfqVnPvhfwb3m0QjlW6JWZ5teaBQhp4B94dqBxxDbUlgNYtJbPZQ/YPSxQOsS8Tiz6FbD3sJr7jeTXsdEHLKsPKaJjZM9m1k)',
    pef_niveau: '1 plan\u00e8te',
    'Name (from pef_niveau)': '1 plan\u00e8te',
    pef_img_médaille:
      'planettes2-12.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/_M9H6rc9ajqUARqZBAN3lw/4P0vKT1eL3vUx4d_OLbQg-Nrllaayv8CVUNmAMKXFLcpsbJVLFky-VweC0qfdB_eSgkaBlFr9r4jgbcFIIiyij4MwwoBjndrLXoyGPWSIffk1AvhMJC3zuno6Hz6SENxyf0b4qknIpLoeEXRC4kAPw/n7r58wfXEj9OhV42EjTixvjhGTAx6lm5EGo505LnhKk)',
    'pef_description medaille': "Ce produit n'a pas de qualit\u00e9 particuli\u00e8re pour ce crit\u00e8re",
    'Name (from cch_niveau)': '1 plan\u00e8te',
    tracabilite:
      'tracabilite_teeshirt4.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/SIlx7xjvc7AUgTvsOF9hwA/bcbQk2mqokPMhODtS6prpYpchep305_rFGkQxDXajcAyFZrrwnTnu8jiM67rxrUNdosYOp2RZd496pog4TDWXa3aKx4LsICpGT6YhDcT7uTNEk2S7GL5iok2mI2LN3gh9-fcKMTd2moSHiRNH74IxNdNcUriYZIfmhtYKilIC-8/byUMH4BnVBGFXkSeafVtucrgeDnLf839DSZj--zgv8U)',
    vide: '',
    'URL popup':
      'https://images.bannerbear.com/direct/KDWmnAMxpAvM80dVk4/requests/000/035/483/099/9BvRDJ724zW7k4Z2zlAKNOd03/e325adc654c915ec91ac94e99004ecd57040b0ce.png',
    'IMG popup':
      'popup-teeshirt4.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/lxBHd2xCpW7PXlFbz0CMAQ/l1I2n-jbTzxIJ_EYvjeD1_tUUEGKZNtr0tFyFGQ2hCFOXqM7rgC2BnrlctCfdiWBIPw2Ke4HJAiZ1YXgVGOxOjPqXoOtXBFf5qJ-fbGkthTSYHUSrqoFx4nJhJR_-AVTpeWUSeb25EY4qIjqOZM_0Q/MDPVwYYV6Qr7uwOK9t8VsFUDbPehnEmu1_Ya_pkYanE)',
    attributes: {
      cch: {
        cch: '7',
        cch_km_voiture: "6.8 kg CO2e, c'est environ 50 km en voiture",
        cch_limite_planete: "Et c'est 1 j d'\u00e9missions \u00e9quivalentes dans un monde bas carbone (objectif 2050)",
        cch_mediane_cat: '6',
        cch_mediane_affichage: '50 % des produits \u00e9mettent plus de \t5.8 kg CO2e',
        cch_ecart_mediane: '16%',
        cch_ecart_affichage: "+16% d'empreinte carbone",
        cch_ecart_affichage_detail: 'Ce produit \u00e9met +16% par rapport \u00e0 la m\u00e9diane de sa cat\u00e9gorie',
        cch_min_cat: '3',
        cch_min_affichage: 'Le meilleur produit de cette cat\u00e9gorie \u00e9met 3.2 kg CO2e',
        cch_affichage: '6.8 kg CO2e',
        cch_affichage_detail: 'Ce produit \u00e9met             6.8 kg CO2e',
        cch_picto:
          '3222073.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/Cg7FbxZjzXNenRznTxruiQ/ej8LA0MFzFDAuonjmhtIGjM_jmUVdYVkgoE2z15gEdwzQp1HxHgjXg7DJB5zuk5qhuuXMvwSXVCYxxYHF3f8xXKqQWM-bAbPCfU0M6ThLn4i_xFHBFw11yad-AGnv9O9h4DnYf2ACDrqVrgmwefaNQ/k9z9DBziUBoeYW10m8F9yEueIYlgsz2zF6_03in2PGI)',
        cch_niveau: '1 plan\u00e8te',
        'cch_description medaille': "Ce produit n'a pas de qualit\u00e9 particuli\u00e8re pour ce crit\u00e8re",
        cch_img_médaille:
          'planettes2-12.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/_M9H6rc9ajqUARqZBAN3lw/4P0vKT1eL3vUx4d_OLbQg-Nrllaayv8CVUNmAMKXFLcpsbJVLFky-VweC0qfdB_eSgkaBlFr9r4jgbcFIIiyij4MwwoBjndrLXoyGPWSIffk1AvhMJC3zuno6Hz6SENxyf0b4qknIpLoeEXRC4kAPw/n7r58wfXEj9OhV42EjTixvjhGTAx6lm5EGo505LnhKk)',
      },
      cch_km_voiture: "6.8 kg CO2e, c'est environ 50 km en voiture",
      cch_limite_planete: "Et c'est 1 j d'\u00e9missions \u00e9quivalentes dans un monde bas carbone (objectif 2050)",
      cch_mediane_cat: '6',
      cch_mediane_affichage: '50 % des produits \u00e9mettent plus de \t5.8 kg CO2e',
      cch_ecart_mediane: '16%',
      cch_ecart_affichage: "+16% d'empreinte carbone",
      cch_ecart_affichage_detail: 'Ce produit \u00e9met +16% par rapport \u00e0 la m\u00e9diane de sa cat\u00e9gorie',
      cch_min_cat: '3',
      cch_min_affichage: 'Le meilleur produit de cette cat\u00e9gorie \u00e9met 3.2 kg CO2e',
      cch_affichage: '6.8 kg CO2e',
      cch_affichage_detail: 'Ce produit \u00e9met             6.8 kg CO2e',
      cch_picto:
        '3222073.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/Cg7FbxZjzXNenRznTxruiQ/ej8LA0MFzFDAuonjmhtIGjM_jmUVdYVkgoE2z15gEdwzQp1HxHgjXg7DJB5zuk5qhuuXMvwSXVCYxxYHF3f8xXKqQWM-bAbPCfU0M6ThLn4i_xFHBFw11yad-AGnv9O9h4DnYf2ACDrqVrgmwefaNQ/k9z9DBziUBoeYW10m8F9yEueIYlgsz2zF6_03in2PGI)',
      cch_niveau: '1 plan\u00e8te',
      'cch_description medaille': "Ce produit n'a pas de qualit\u00e9 particuli\u00e8re pour ce crit\u00e8re",
      cch_img_médaille:
        'planettes2-12.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/_M9H6rc9ajqUARqZBAN3lw/4P0vKT1eL3vUx4d_OLbQg-Nrllaayv8CVUNmAMKXFLcpsbJVLFky-VweC0qfdB_eSgkaBlFr9r4jgbcFIIiyij4MwwoBjndrLXoyGPWSIffk1AvhMJC3zuno6Hz6SENxyf0b4qknIpLoeEXRC4kAPw/n7r58wfXEj9OhV42EjTixvjhGTAx6lm5EGo505LnhKk)',
      'Pays - Etape 1': 'CN',
      'Pays - Etape 2': 'PT',
      'Pays - Etape 3': 'PT',
      'Pays - Etape 4': 'PT',
      'Pays - Etape 5': 'PT',
      'Pays - Etape 6': 'FR',
      'Pays - Etape 7': 'FR',
      'Pays - Etape 8': 'FR',
      country_step: {
        'Pays - Etape 1': 'CN',
        'Pays - Etape 2': 'PT',
        'Pays - Etape 3': 'PT',
        'Pays - Etape 4': 'PT',
        'Pays - Etape 5': 'PT',
        'Pays - Etape 6': 'FR',
        'Pays - Etape 7': 'FR',
        'Pays - Etape 8': 'FR',
      },
      PEF: '774',
      PEF_affichage_detail: 'Ce produit vaut 770 \u00b5Pt',
      PEF_limite_planete:
        "770 \u00b5Pt, c'est l'\u00e9quivalent de 7 heures de l'empreinte annuelle d'un europ\u00e9en",
      PEF_mediane_cat: '758',
      PEF_mediane_affichage: '50 % des produits valent plus de 760 \u00b5Pt',
      PEF_ecart_mediane: '2%',
      PEF_ecart_affichage: "+2% d'empreinte environnementale",
      PEF_ecart_affichage_detail: 'Ce produit \u00e9met +2% par rapport \u00e0 la m\u00e9diane de sa cat\u00e9gorie',
      PEF_min_cat: '493',
      PEF_min_affichage: 'Le meilleur produit de cette cat\u00e9gorie vaut 490 \u00b5Pt',
      pef: {
        PEF: '774',
        PEF_affichage_detail: 'Ce produit vaut 770 \u00b5Pt',
        PEF_limite_planete:
          "770 \u00b5Pt, c'est l'\u00e9quivalent de 7 heures de l'empreinte annuelle d'un europ\u00e9en",
        PEF_mediane_cat: '758',
        PEF_mediane_affichage: '50 % des produits valent plus de 760 \u00b5Pt',
        PEF_ecart_mediane: '2%',
        PEF_ecart_affichage: "+2% d'empreinte environnementale",
        PEF_ecart_affichage_detail: 'Ce produit \u00e9met +2% par rapport \u00e0 la m\u00e9diane de sa cat\u00e9gorie',
        PEF_min_cat: '493',
        PEF_min_affichage: 'Le meilleur produit de cette cat\u00e9gorie vaut 490 \u00b5Pt',
      },
    },
  },
  {
    'ID produit': '5',
    'Num produit': '5',
    Nom: 'Tee-shirt 5',
    Marque: 'Marque X',
    'Description courte': 'Coton recycl\u00e9 - fabriqu\u00e9 au Portugal ',
    'Description longue': '',
    Label: 'Global Recycled Standard,Oeko Tex Label',
    'Logo (from Label)':
      'image_2023-03-15_114050784.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/-tq4_nXb8vOxprYkKAhFMg/d7ZKNpv5RyJzLWV76kE37h4mRWxWa4HpGTs0eOXZRf3Tb6A-9RbZRmcPvzFWkbJvWX8925P-y-nGAQMBd_zWA4_cHaTKysKTD_XPOH1mqqbJS0Q0jc0MVMcahM0pISYfYzDZ2Elm6SzePZEpC33YW8UOax_FecvFlSWZBBXzZWs/rsy_IgItu7JqrEA22fY_bkWvhh5_k6H2EDRlGHCZO_8),image_2023-03-15_114155185.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/FZq6jK3h2zYhnlIM7j_OAw/RqVMQYC76s2cZWcwYloryRPVx_wRjBf23wUlr5h5VjSsV4mFvvwYXexlFxIipRCe4qJHHe2-W6Kgk8z3I5DhO2JKzO0w1YJEtmm7qAncpvjEDrUkINzQuBI1aJjoaQKL87BYP-JjXxEXPDIcCbb3BvJnuwUz3e6-WJ2RtwaoMqc/Q4wZXRclBhzneF1ZDZZjXky5Y9qXvOwJlBtWvsGpCZE)',
    URL: '',
    Prix: '\u20ac40.00',
    Image:
      'image_2023-02-23_105848123-removebg-preview.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/48ROX6sohltA9H8RueB0_g/8zY_oq9dH0y_pYOlv3csCAoMOHv6SpQSy9Wg2aaI_R6PhlpFa1lP-bKy8EeN-rkyVbmbDZrP50TW21EhIw_ULyBgGn01lso49FqGYDSQUoJxBNaC7NObtD6Prfj7-mkXdvn8sPCiPtIe_raYnv-w9E1wFKut-ShhyQ5JmcicazqXJSeyx0wjvrOEXQ1hKl_p/8iQZceXSBOWsN9x09gQ9vlcJBfOKPCWbL61sGS3m_j4)',
    Catégorie_softr: 'Habillement',
    Catégorie: 'Textile',
    Type: 'Tee-shirt',
    wtu: '111',
    wtu_picto:
      'image_2023-03-09_113253563.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/Tp5mq0CH0hDIz-R4Byzwzw/_EhCHERMBBISU6erycAlwHKN1BMUHAr_bleE7A9hotX3yhf_t7PhYJmnflU_-ibB3vwSdMxf65cx_xOX28I7kIaiunqt951ZSy8e1joVabEIWzlMjJH2eCibYCO6Qg1Bc_y6wP6WxriFdSyYeQb-zupwjk2j25YvO9lM5dPi7ac/PINf1tWNOYKVczsQ8zt-F5giwzb8HVv3Th85xyyBd8g)',
    pef_picto:
      'image_2023-03-09_113337288.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/BQbvMTJzkU8GzAKN4NL2JQ/0DNEpSjem-PIiiJXwhCV2VXhpbmePHc0ZJ-bzTjSA_F-xds8b6RtzUHTJBIDvYt-tOjc8pd6gnWTsAV489-51uE20FBm5QA85vWG11GsBMjAvirifVZ2e3n7u6ffTEJeIABphjilMCOUnSz2Beiu_LH8AMpbsd2W_3Ll1QDPLUc/mks6bu1Y4A0sc5mTpOOlEZU1nMK2yyXCbsTu92zWJ3E)',
    aff_env_lettre: 'B',
    affichage_environnemental:
      'image_2023-03-09_161410454.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/uO_1HhArLy4GCYFBvM3i1w/lojX7WMHR8jHsVsF6eQ2LJv26bEMWbl81jetGASQLxlDbtoxKI6g5e-SKb76CLdElQRy_sNRsTCcYxCJsFi5J7EUEZ2x3pXSDd1iN7QcUm9EL6hoNSB4r18ETuind5AWNgEpr7NiLlpqWtvjfC_aGTFp-kXLpY71_cWFsMMW1-8/CWoPqi98ZQFgCRnOAf6CryIiYXqGWPUMN1m02Nlacdw)',
    pef_niveau: '4 plan\u00e8tes',
    'Name (from pef_niveau)': '4 plan\u00e8tes',
    pef_img_médaille:
      'planettes2-09.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/BFuwmfjvjm-bkkvj7HLiIg/mjne6tjaU2Q5d_Ssx5lv024bdQAF80gRzVogWoxM_sJe76FYzrGI-ijoSWCT1FSpqIIibdoGqmUCVNtZvwKamf3P7TisYQHMoPaGCUAXBHvtppiFZc11MxBSiS6lkuvAUEeupEJGqP8TzRQqdrWZ8A/2dNMnsjvkLK7sx6MdPhbdt0kE6Jc-Gs4yB-9nMPRBbY)',
    'pef_description medaille': 'Ce produit fait partie des 10% des meilleurs de sa cat\u00e9gorie',
    'Name (from cch_niveau)': '3 plan\u00e8tes',
    tracabilite:
      'tracabilite_teeshirt5.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/tW6vkjJ7ArhM3fR86K0vUQ/rD8DvpnHDWYPVYtcrep65HSNCCc-vGlSqBgxAyAnZs8sJnb4cP_zBtSXWo9sSkx3f_O0eL2aN6Q1F6VmrcEYxXGTPeCdibfqcabYpn5wGB6wIaphUorx4dtxby6WvPFwSt621I6v62KmkOL-N4KBhOKyiSR7ul3uZzQccIGgEbY/635ifkmJo1ngtyRvDKwmku6F-kn79FhakE9FEmtUcQQ)',
    vide: '',
    'URL popup':
      'https://images.bannerbear.com/direct/KDWmnAMxpAvM80dVk4/requests/000/035/483/100/NgL30amMOYeAk794YprJxBoye/f22f8cb880f5e0c411d13ed63944836140176b4c.png',
    'IMG popup':
      'popup-teeshirt5.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/n8LDpyEq_DJRJo-M94NdDg/kdXLR2ZRw-mWTIlmrvmze9gPuJLg_EjfsGgh6MjkBGSTJ4pb45RwiRMWoP-P72AcrWTtIpVnciJlZo1RyCuyFLI7sEmRgiTPS0NoKrQZFFL_12gWIjE9_PAKq-NwmWaq3tYpT7NKO2DUjViH1ABtZg/OA12R5Y4fajmAx7qq6CnuVucplZQm76LSU_Dn3Q2Lo4)',
    attributes: {
      cch: {
        cch: '4',
        cch_km_voiture: "4.2 kg CO2e, c'est environ 30 km en voiture",
        cch_limite_planete: "Et c'est 1 j d'\u00e9missions \u00e9quivalentes dans un monde bas carbone (objectif 2050)",
        cch_mediane_cat: '6',
        cch_mediane_affichage: '50 % des produits \u00e9mettent plus de \t5.8 kg CO2e',
        cch_ecart_mediane: '-28%',
        cch_ecart_affichage: "-28% d'empreinte carbone",
        cch_ecart_affichage_detail: 'Ce produit \u00e9met -28% par rapport \u00e0 la m\u00e9diane de sa cat\u00e9gorie',
        cch_min_cat: '3',
        cch_min_affichage: 'Le meilleur produit de cette cat\u00e9gorie \u00e9met 3.2 kg CO2e',
        cch_affichage: '4.2 kg CO2e',
        cch_affichage_detail: 'Ce produit \u00e9met             4.2 kg CO2e',
        cch_picto:
          '3222073.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/Cg7FbxZjzXNenRznTxruiQ/ej8LA0MFzFDAuonjmhtIGjM_jmUVdYVkgoE2z15gEdwzQp1HxHgjXg7DJB5zuk5qhuuXMvwSXVCYxxYHF3f8xXKqQWM-bAbPCfU0M6ThLn4i_xFHBFw11yad-AGnv9O9h4DnYf2ACDrqVrgmwefaNQ/k9z9DBziUBoeYW10m8F9yEueIYlgsz2zF6_03in2PGI)',
        cch_niveau: '3 plan\u00e8tes',
        'cch_description medaille': 'Ce produit fait partie des 25% des meilleurs de sa cat\u00e9gorie',
        cch_img_médaille:
          'planettes2-10.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/mVM9KoEIOlq297o8ahR1Bw/A5rkxooKkBnJ2622FnDndxZ1VV8dnf_7c7tJ58LII4_FlsLPA7aQ5mUc57xXn-RqWHZohU3m3irG2-nM5DQElq0I4TMfuBst-MUtPLORcC1IuqCFxjAqcUEz7g4KZvGOR4LpXOH-gWdJkyN3ez-N0Q/XRwU39-tqUork8C-E8fdV2HncmV-Rr6HTKsFAdeJ-Wo)',
      },
      cch_km_voiture: "4.2 kg CO2e, c'est environ 30 km en voiture",
      cch_limite_planete: "Et c'est 1 j d'\u00e9missions \u00e9quivalentes dans un monde bas carbone (objectif 2050)",
      cch_mediane_cat: '6',
      cch_mediane_affichage: '50 % des produits \u00e9mettent plus de \t5.8 kg CO2e',
      cch_ecart_mediane: '-28%',
      cch_ecart_affichage: "-28% d'empreinte carbone",
      cch_ecart_affichage_detail: 'Ce produit \u00e9met -28% par rapport \u00e0 la m\u00e9diane de sa cat\u00e9gorie',
      cch_min_cat: '3',
      cch_min_affichage: 'Le meilleur produit de cette cat\u00e9gorie \u00e9met 3.2 kg CO2e',
      cch_affichage: '4.2 kg CO2e',
      cch_affichage_detail: 'Ce produit \u00e9met             4.2 kg CO2e',
      cch_picto:
        '3222073.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/Cg7FbxZjzXNenRznTxruiQ/ej8LA0MFzFDAuonjmhtIGjM_jmUVdYVkgoE2z15gEdwzQp1HxHgjXg7DJB5zuk5qhuuXMvwSXVCYxxYHF3f8xXKqQWM-bAbPCfU0M6ThLn4i_xFHBFw11yad-AGnv9O9h4DnYf2ACDrqVrgmwefaNQ/k9z9DBziUBoeYW10m8F9yEueIYlgsz2zF6_03in2PGI)',
      cch_niveau: '3 plan\u00e8tes',
      'cch_description medaille': 'Ce produit fait partie des 25% des meilleurs de sa cat\u00e9gorie',
      cch_img_médaille:
        'planettes2-10.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/mVM9KoEIOlq297o8ahR1Bw/A5rkxooKkBnJ2622FnDndxZ1VV8dnf_7c7tJ58LII4_FlsLPA7aQ5mUc57xXn-RqWHZohU3m3irG2-nM5DQElq0I4TMfuBst-MUtPLORcC1IuqCFxjAqcUEz7g4KZvGOR4LpXOH-gWdJkyN3ez-N0Q/XRwU39-tqUork8C-E8fdV2HncmV-Rr6HTKsFAdeJ-Wo)',
      'Pays - Etape 1': 'FR',
      'Pays - Etape 2': 'PT',
      'Pays - Etape 3': 'PT',
      'Pays - Etape 4': 'PT',
      'Pays - Etape 5': 'PT',
      'Pays - Etape 6': 'FR',
      'Pays - Etape 7': 'FR',
      'Pays - Etape 8': 'FR',
      country_step: {
        'Pays - Etape 1': 'FR',
        'Pays - Etape 2': 'PT',
        'Pays - Etape 3': 'PT',
        'Pays - Etape 4': 'PT',
        'Pays - Etape 5': 'PT',
        'Pays - Etape 6': 'FR',
        'Pays - Etape 7': 'FR',
        'Pays - Etape 8': 'FR',
      },
      PEF: '509',
      PEF_affichage_detail: 'Ce produit vaut 510 \u00b5Pt',
      PEF_limite_planete:
        "510 \u00b5Pt, c'est l'\u00e9quivalent de 4 heures de l'empreinte annuelle d'un europ\u00e9en",
      PEF_mediane_cat: '758',
      PEF_mediane_affichage: '50 % des produits valent plus de 760 \u00b5Pt',
      PEF_ecart_mediane: '-49%',
      PEF_ecart_affichage: "-49% d'empreinte environnementale",
      PEF_ecart_affichage_detail: 'Ce produit \u00e9met -49% par rapport \u00e0 la m\u00e9diane de sa cat\u00e9gorie',
      PEF_min_cat: '493',
      PEF_min_affichage: 'Le meilleur produit de cette cat\u00e9gorie vaut 490 \u00b5Pt',
      pef: {
        PEF: '509',
        PEF_affichage_detail: 'Ce produit vaut 510 \u00b5Pt',
        PEF_limite_planete:
          "510 \u00b5Pt, c'est l'\u00e9quivalent de 4 heures de l'empreinte annuelle d'un europ\u00e9en",
        PEF_mediane_cat: '758',
        PEF_mediane_affichage: '50 % des produits valent plus de 760 \u00b5Pt',
        PEF_ecart_mediane: '-49%',
        PEF_ecart_affichage: "-49% d'empreinte environnementale",
        PEF_ecart_affichage_detail: 'Ce produit \u00e9met -49% par rapport \u00e0 la m\u00e9diane de sa cat\u00e9gorie',
        PEF_min_cat: '493',
        PEF_min_affichage: 'Le meilleur produit de cette cat\u00e9gorie vaut 490 \u00b5Pt',
      },
    },
  },
  {
    'ID produit': '6',
    'Num produit': '6',
    Nom: 'Tee-shirt 6',
    Marque: 'Marque X',
    'Description courte': 'Chanvre - fabriqu\u00e9 en France',
    'Description longue': '',
    Label: 'Oeko Tex Label Made in Green,Global Organic Textile Standard',
    'Logo (from Label)':
      'image_2023-03-15_114455758.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/rjm9Fedk3enc4EOBS6KhLQ/JO3wXlCmVLd_cBLC5HI_cFoZ2bbu6bVG6SafcMUbWkLE9pAA58KbO479WmR_Gp1UDOS20YIEP91oOvIiTMmg0TLemkPppu7e5Oe_YFM-lAOEmLAOAOEcl7LUSJnPNrvq-yIDNyz2TWQz35u8ejR2lAMV_MoxdCYWAuQUlX4gUXU/43CnIaZz754zA5haitRU189UR5i-qT_wqAqfp7M4Zl0),image_2023-03-15_114621318.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/plydAiTv9TgoIKkyHxFC5Q/PSqT4Ejb5g-U4tgNxQIvgvuTCTFYDeHUW-1r0clAJqebyBxK0QLAVe6F47xSYwTLSmlo--a7lhhMypSmAF6pY3rclq9AB6HBTWyUTwO1vsI7USv0QPtIoeiEYArc9WHWAL8OzRAe-as7baYqaCXj8RTSzikLRafNny3EvdNfLXo/9cuw9XjWlsV7FOxPTerJx2FJ_X9_bwDH6ffpUpB_Px0)',
    URL: '',
    Prix: '\u20ac55.00',
    Image:
      'image_2023-02-23_110127969-removebg-preview.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/n4c5IWF14DDuygAwM0B1zA/U5tP2wsdsnIwjS4xSMaeaBxD_LgQMTAQh9mfTRdsZGg3aCPX-Zu7766iUaPZkG6RPhZ5albMjmOMQVB4A1EnP4RMoupkJWa2m7THpMNAJdNGKMjcd27a1L2oPqxXo_BQQ3i8wnuBNJAbAGtlje3kVmLPIfDsYVx0jliuAZh4KB_UvQYB-6rBSTbHScFrVknZ/SVcqEpY0Aekb97WEJYzwBAwxWr6S-LYPCD7CrChwNSo)',
    Catégorie_softr: 'Habillement',
    Catégorie: 'Textile',
    Type: 'Tee-shirt',
    wtu: '5',
    wtu_picto:
      'image_2023-03-09_113253563.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/Tp5mq0CH0hDIz-R4Byzwzw/_EhCHERMBBISU6erycAlwHKN1BMUHAr_bleE7A9hotX3yhf_t7PhYJmnflU_-ibB3vwSdMxf65cx_xOX28I7kIaiunqt951ZSy8e1joVabEIWzlMjJH2eCibYCO6Qg1Bc_y6wP6WxriFdSyYeQb-zupwjk2j25YvO9lM5dPi7ac/PINf1tWNOYKVczsQ8zt-F5giwzb8HVv3Th85xyyBd8g)',
    pef_picto:
      'image_2023-03-09_113337288.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/BQbvMTJzkU8GzAKN4NL2JQ/0DNEpSjem-PIiiJXwhCV2VXhpbmePHc0ZJ-bzTjSA_F-xds8b6RtzUHTJBIDvYt-tOjc8pd6gnWTsAV489-51uE20FBm5QA85vWG11GsBMjAvirifVZ2e3n7u6ffTEJeIABphjilMCOUnSz2Beiu_LH8AMpbsd2W_3Ll1QDPLUc/mks6bu1Y4A0sc5mTpOOlEZU1nMK2yyXCbsTu92zWJ3E)',
    aff_env_lettre: 'C',
    affichage_environnemental:
      'image_2023-03-09_161336485.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/cKH_JsLD3F8QovYTkTzj5w/LA4tepkZFq6rxLjXS8RraDmFjw5RsY3E1z_jb792dgPfRzzyFtcvKK0H_d1BbkZ8qEdyObOBrmR9nDivQGQApO4bq8Ww0Wv-F0rnW-ZVR8biovvlh-EnqxqVTh6SQ2EJBu8IbSdhCQBlx2kreKd6nJRS0C0jOLD_EFAE7goVeP8/UFNTf_VoTzxQ2hOjmgjvUyaq2fq2IbifhK_vgMVEUNk)',
    pef_niveau: '1 plan\u00e8te',
    'Name (from pef_niveau)': '1 plan\u00e8te',
    pef_img_médaille:
      'planettes2-12.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/_M9H6rc9ajqUARqZBAN3lw/4P0vKT1eL3vUx4d_OLbQg-Nrllaayv8CVUNmAMKXFLcpsbJVLFky-VweC0qfdB_eSgkaBlFr9r4jgbcFIIiyij4MwwoBjndrLXoyGPWSIffk1AvhMJC3zuno6Hz6SENxyf0b4qknIpLoeEXRC4kAPw/n7r58wfXEj9OhV42EjTixvjhGTAx6lm5EGo505LnhKk)',
    'pef_description medaille': "Ce produit n'a pas de qualit\u00e9 particuli\u00e8re pour ce crit\u00e8re",
    'Name (from cch_niveau)': '1 plan\u00e8te',
    tracabilite:
      'tracabilite_teeshirt6.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/_5e4G6HTPJJve3YgJw6gZA/DAzu3s_8-kyui-fM_S1cKHme0R_s1sQIkVGFzyn_UQFZoZOOWqGOyf6OBJ0SbyPwCmDJMBuRBlwpn0-ZyWplFq3PJiMH3W8AabOj9_89jzzQbpg5xRl8H_c3yOm3W5mvQ__QmLiThgyhIONy1heZ5vRibY88Hjop2HMtjbScjoA/XtzrNahzPK3AvS-7NBhAgskvhg-eIjEpNQuThlcltWQ)',
    vide: '',
    'URL popup':
      'https://images.bannerbear.com/direct/KDWmnAMxpAvM80dVk4/requests/000/035/483/096/5nDZ3xmVezb0k5RNQy2qpdWj9/d1856cc223b988f54632fcc78b3add011b59cf6d.png',
    'IMG popup':
      'popup-teeshirt6.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/YwDgbIu9T2syAjX5ZDaG-g/qRtHO5m85U1UNYRBkr_KQQMcFzstVEFtLTWksrWe1aIZE_V23-tDOPwhjA1JdjchZpciR-1-Eg0zyOwinkF8ili2_eg63NMEE--zIMRWvasc6EvW_vIAxoa4XVBM8w2PqnxA364Zi6vm_y7gYFr3Bw/tO65Egk2pcbv42XvcUERNzPzGpVd4vBsHFTtYkEB9PE)',
    attributes: {
      cch: {
        cch: '7',
        cch_km_voiture: "6.7 kg CO2e, c'est environ 40 km en voiture",
        cch_limite_planete: "Et c'est 1 j d'\u00e9missions \u00e9quivalentes dans un monde bas carbone (objectif 2050)",
        cch_mediane_cat: '6',
        cch_mediane_affichage: '50 % des produits \u00e9mettent plus de \t5.8 kg CO2e',
        cch_ecart_mediane: '15%',
        cch_ecart_affichage: "+15% d'empreinte carbone",
        cch_ecart_affichage_detail: 'Ce produit \u00e9met +15% par rapport \u00e0 la m\u00e9diane de sa cat\u00e9gorie',
        cch_min_cat: '3',
        cch_min_affichage: 'Le meilleur produit de cette cat\u00e9gorie \u00e9met 3.2 kg CO2e',
        cch_affichage: '6.7 kg CO2e',
        cch_affichage_detail: 'Ce produit \u00e9met             6.7 kg CO2e',
        cch_picto:
          '3222073.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/Cg7FbxZjzXNenRznTxruiQ/ej8LA0MFzFDAuonjmhtIGjM_jmUVdYVkgoE2z15gEdwzQp1HxHgjXg7DJB5zuk5qhuuXMvwSXVCYxxYHF3f8xXKqQWM-bAbPCfU0M6ThLn4i_xFHBFw11yad-AGnv9O9h4DnYf2ACDrqVrgmwefaNQ/k9z9DBziUBoeYW10m8F9yEueIYlgsz2zF6_03in2PGI)',
        cch_niveau: '1 plan\u00e8te',
        'cch_description medaille': "Ce produit n'a pas de qualit\u00e9 particuli\u00e8re pour ce crit\u00e8re",
        cch_img_médaille:
          'planettes2-12.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/_M9H6rc9ajqUARqZBAN3lw/4P0vKT1eL3vUx4d_OLbQg-Nrllaayv8CVUNmAMKXFLcpsbJVLFky-VweC0qfdB_eSgkaBlFr9r4jgbcFIIiyij4MwwoBjndrLXoyGPWSIffk1AvhMJC3zuno6Hz6SENxyf0b4qknIpLoeEXRC4kAPw/n7r58wfXEj9OhV42EjTixvjhGTAx6lm5EGo505LnhKk)',
      },
      cch_km_voiture: "6.7 kg CO2e, c'est environ 40 km en voiture",
      cch_limite_planete: "Et c'est 1 j d'\u00e9missions \u00e9quivalentes dans un monde bas carbone (objectif 2050)",
      cch_mediane_cat: '6',
      cch_mediane_affichage: '50 % des produits \u00e9mettent plus de \t5.8 kg CO2e',
      cch_ecart_mediane: '15%',
      cch_ecart_affichage: "+15% d'empreinte carbone",
      cch_ecart_affichage_detail: 'Ce produit \u00e9met +15% par rapport \u00e0 la m\u00e9diane de sa cat\u00e9gorie',
      cch_min_cat: '3',
      cch_min_affichage: 'Le meilleur produit de cette cat\u00e9gorie \u00e9met 3.2 kg CO2e',
      cch_affichage: '6.7 kg CO2e',
      cch_affichage_detail: 'Ce produit \u00e9met             6.7 kg CO2e',
      cch_picto:
        '3222073.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/Cg7FbxZjzXNenRznTxruiQ/ej8LA0MFzFDAuonjmhtIGjM_jmUVdYVkgoE2z15gEdwzQp1HxHgjXg7DJB5zuk5qhuuXMvwSXVCYxxYHF3f8xXKqQWM-bAbPCfU0M6ThLn4i_xFHBFw11yad-AGnv9O9h4DnYf2ACDrqVrgmwefaNQ/k9z9DBziUBoeYW10m8F9yEueIYlgsz2zF6_03in2PGI)',
      cch_niveau: '1 plan\u00e8te',
      'cch_description medaille': "Ce produit n'a pas de qualit\u00e9 particuli\u00e8re pour ce crit\u00e8re",
      cch_img_médaille:
        'planettes2-12.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/_M9H6rc9ajqUARqZBAN3lw/4P0vKT1eL3vUx4d_OLbQg-Nrllaayv8CVUNmAMKXFLcpsbJVLFky-VweC0qfdB_eSgkaBlFr9r4jgbcFIIiyij4MwwoBjndrLXoyGPWSIffk1AvhMJC3zuno6Hz6SENxyf0b4qknIpLoeEXRC4kAPw/n7r58wfXEj9OhV42EjTixvjhGTAx6lm5EGo505LnhKk)',
      'Pays - Etape 1': 'CN',
      'Pays - Etape 2': 'FR',
      'Pays - Etape 3': 'FR',
      'Pays - Etape 4': 'FR',
      'Pays - Etape 5': 'FR',
      'Pays - Etape 6': 'FR',
      'Pays - Etape 7': 'FR',
      'Pays - Etape 8': 'FR',
      country_step: {
        'Pays - Etape 1': 'CN',
        'Pays - Etape 2': 'FR',
        'Pays - Etape 3': 'FR',
        'Pays - Etape 4': 'FR',
        'Pays - Etape 5': 'FR',
        'Pays - Etape 6': 'FR',
        'Pays - Etape 7': 'FR',
        'Pays - Etape 8': 'FR',
      },
      PEF: '864',
      PEF_affichage_detail: 'Ce produit vaut 860 \u00b5Pt',
      PEF_limite_planete:
        "860 \u00b5Pt, c'est l'\u00e9quivalent de 8 heures de l'empreinte annuelle d'un europ\u00e9en",
      PEF_mediane_cat: '758',
      PEF_mediane_affichage: '50 % des produits valent plus de 760 \u00b5Pt',
      PEF_ecart_mediane: '12%',
      PEF_ecart_affichage: "+12% d'empreinte environnementale",
      PEF_ecart_affichage_detail: 'Ce produit \u00e9met +12% par rapport \u00e0 la m\u00e9diane de sa cat\u00e9gorie',
      PEF_min_cat: '493',
      PEF_min_affichage: 'Le meilleur produit de cette cat\u00e9gorie vaut 490 \u00b5Pt',
      pef: {
        PEF: '864',
        PEF_affichage_detail: 'Ce produit vaut 860 \u00b5Pt',
        PEF_limite_planete:
          "860 \u00b5Pt, c'est l'\u00e9quivalent de 8 heures de l'empreinte annuelle d'un europ\u00e9en",
        PEF_mediane_cat: '758',
        PEF_mediane_affichage: '50 % des produits valent plus de 760 \u00b5Pt',
        PEF_ecart_mediane: '12%',
        PEF_ecart_affichage: "+12% d'empreinte environnementale",
        PEF_ecart_affichage_detail: 'Ce produit \u00e9met +12% par rapport \u00e0 la m\u00e9diane de sa cat\u00e9gorie',
        PEF_min_cat: '493',
        PEF_min_affichage: 'Le meilleur produit de cette cat\u00e9gorie vaut 490 \u00b5Pt',
      },
    },
  },
  {
    'ID produit': '7',
    'Num produit': '7',
    Nom: 'Tee-shirt 7',
    Marque: 'Marque X',
    'Description courte': 'Cachemire - fabriqu\u00e9 en Inde',
    'Description longue': '',
    Label: 'Oeko Tex Label,Global Organic Textile Standard',
    'Logo (from Label)':
      'image_2023-03-15_114155185.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/FZq6jK3h2zYhnlIM7j_OAw/RqVMQYC76s2cZWcwYloryRPVx_wRjBf23wUlr5h5VjSsV4mFvvwYXexlFxIipRCe4qJHHe2-W6Kgk8z3I5DhO2JKzO0w1YJEtmm7qAncpvjEDrUkINzQuBI1aJjoaQKL87BYP-JjXxEXPDIcCbb3BvJnuwUz3e6-WJ2RtwaoMqc/Q4wZXRclBhzneF1ZDZZjXky5Y9qXvOwJlBtWvsGpCZE),image_2023-03-15_114621318.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/plydAiTv9TgoIKkyHxFC5Q/PSqT4Ejb5g-U4tgNxQIvgvuTCTFYDeHUW-1r0clAJqebyBxK0QLAVe6F47xSYwTLSmlo--a7lhhMypSmAF6pY3rclq9AB6HBTWyUTwO1vsI7USv0QPtIoeiEYArc9WHWAL8OzRAe-as7baYqaCXj8RTSzikLRafNny3EvdNfLXo/9cuw9XjWlsV7FOxPTerJx2FJ_X9_bwDH6ffpUpB_Px0)',
    URL: '',
    Prix: '\u20ac130.00',
    Image:
      'image_2023-02-23_110239597-removebg-preview.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/d0N59Kw5AAfU_gnR0gcMbg/8wDQUHTQCFN9d51nJ3Gm9iVnWf6Nqx2DbiRwNafdCGtsvAsqrXjUd7rvx9m7rX76x3l9H6ECXrmQJ3onYUS9OAdhyxcRTqgE5B8kNiZIYljHKMH4E2-xKRPFGUWdfgBk3HIuewfL2gClCmaS076Nx33N7z4xCIvH4Q8GptYXbq4iKwayTtQzGuHCEmzxEaG8/uQMVnfpQ_fyEQRA2QDNQWkb3fT3gSq5a9EzIdT7I6-A)',
    Catégorie_softr: 'Habillement',
    Catégorie: 'Textile',
    Type: 'Tee-shirt',
    wtu: '23272',
    wtu_picto:
      'image_2023-03-09_113253563.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/Tp5mq0CH0hDIz-R4Byzwzw/_EhCHERMBBISU6erycAlwHKN1BMUHAr_bleE7A9hotX3yhf_t7PhYJmnflU_-ibB3vwSdMxf65cx_xOX28I7kIaiunqt951ZSy8e1joVabEIWzlMjJH2eCibYCO6Qg1Bc_y6wP6WxriFdSyYeQb-zupwjk2j25YvO9lM5dPi7ac/PINf1tWNOYKVczsQ8zt-F5giwzb8HVv3Th85xyyBd8g)',
    pef_picto:
      'image_2023-03-09_113337288.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/BQbvMTJzkU8GzAKN4NL2JQ/0DNEpSjem-PIiiJXwhCV2VXhpbmePHc0ZJ-bzTjSA_F-xds8b6RtzUHTJBIDvYt-tOjc8pd6gnWTsAV489-51uE20FBm5QA85vWG11GsBMjAvirifVZ2e3n7u6ffTEJeIABphjilMCOUnSz2Beiu_LH8AMpbsd2W_3Ll1QDPLUc/mks6bu1Y4A0sc5mTpOOlEZU1nMK2yyXCbsTu92zWJ3E)',
    aff_env_lettre: 'E',
    affichage_environnemental:
      'image_2023-03-09_161434367.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/EuCwtHGA55lSXeFmNczntA/hcwpdYFP_oxC9TZe4XjfuFysfBx6T91qpVRVpltGpkTfGnswsxDUtNqOY3CeyNc8viIlKOg_SPWe77kwUYAZh_I-qDwfVU54fCl5EnsXZdEPKJuLCrQ0Gemf3WzVKudRr-j-p1MQs4gfU6BdVr_i6SH88NWVdunYG4-YlGiEJds/yXZlSm3Nzkh1JFfd76cWbatYarvL_z7b1Zw4zy6UNEk)',
    pef_niveau: '1 plan\u00e8te',
    'Name (from pef_niveau)': '1 plan\u00e8te',
    pef_img_médaille:
      'planettes2-12.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/_M9H6rc9ajqUARqZBAN3lw/4P0vKT1eL3vUx4d_OLbQg-Nrllaayv8CVUNmAMKXFLcpsbJVLFky-VweC0qfdB_eSgkaBlFr9r4jgbcFIIiyij4MwwoBjndrLXoyGPWSIffk1AvhMJC3zuno6Hz6SENxyf0b4qknIpLoeEXRC4kAPw/n7r58wfXEj9OhV42EjTixvjhGTAx6lm5EGo505LnhKk)',
    'pef_description medaille': "Ce produit n'a pas de qualit\u00e9 particuli\u00e8re pour ce crit\u00e8re",
    'Name (from cch_niveau)': '1 plan\u00e8te',
    tracabilite:
      'tracabilite_teeshirt7.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/bx7ZSN5iJH2rNBG-BoQJ7A/EeoHdG03T_jdFHhxfukxMyADtgZN9u5rMVvB9J6JvzWBraWpxBGEZC9AJLxkJIGcZZDrCpQUwHxT1S0ZxQ8LeNTdE2dhwC7tJ41tTyH7xlSUkpZ0o_NIdKPYpwgtmUaqjHiE1ltlUQJi_OF2rskv_8oppd6FHrxsXCU2HsudUFw/sNrZjxkVW0csTc0CK_ne_YzMyDCeBd0xedRckum7ImE)',
    vide: '',
    'URL popup':
      'https://images.bannerbear.com/direct/KDWmnAMxpAvM80dVk4/requests/000/035/483/097/P0ev7XDZrzqpylaXYMjR9og8N/5d4c839a89333c6f0ddafdd8728b80e26448689a.png',
    'IMG popup':
      'popup-teeshirt7.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/5NMsN7C19W_PGIdo1likYA/PG3WoXNvTGp1h5cyseT4He0-koRMbibYTdaz_R1LqxVDIHR-0SDbBnL3gqvJoHNDrbdpW4PhuRbvd7qfrqJTRsGN2mED2Uk_dGxKBbLtbqwpjud3gsZJ8thquGG43ERBmdJtdOKgxO7QbblwyFJl7w/M43RpK0upGudwJleNNyDnntLU8LyCfu39u8L7mPJqUE)',
    attributes: {
      cch: {
        cch: '90',
        cch_km_voiture: "90.1 kg CO2e, c'est environ 600 km en voiture",
        cch_limite_planete:
          "Et c'est 16.5 j d'\u00e9missions \u00e9quivalentes dans un monde bas carbone (objectif 2050)",
        cch_mediane_cat: '6',
        cch_mediane_affichage: '50 % des produits \u00e9mettent plus de \t5.8 kg CO2e',
        cch_ecart_mediane: '1441%',
        cch_ecart_affichage: "+1441% d'empreinte carbone",
        cch_ecart_affichage_detail:
          'Ce produit \u00e9met +1441% par rapport \u00e0 la m\u00e9diane de sa cat\u00e9gorie',
        cch_min_cat: '3',
        cch_min_affichage: 'Le meilleur produit de cette cat\u00e9gorie \u00e9met 3.2 kg CO2e',
        cch_affichage: '90.1 kg CO2e',
        cch_affichage_detail: 'Ce produit \u00e9met             90.1 kg CO2e',
        cch_picto:
          '3222073.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/Cg7FbxZjzXNenRznTxruiQ/ej8LA0MFzFDAuonjmhtIGjM_jmUVdYVkgoE2z15gEdwzQp1HxHgjXg7DJB5zuk5qhuuXMvwSXVCYxxYHF3f8xXKqQWM-bAbPCfU0M6ThLn4i_xFHBFw11yad-AGnv9O9h4DnYf2ACDrqVrgmwefaNQ/k9z9DBziUBoeYW10m8F9yEueIYlgsz2zF6_03in2PGI)',
        cch_niveau: '1 plan\u00e8te',
        'cch_description medaille': "Ce produit n'a pas de qualit\u00e9 particuli\u00e8re pour ce crit\u00e8re",
        cch_img_médaille:
          'planettes2-12.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/_M9H6rc9ajqUARqZBAN3lw/4P0vKT1eL3vUx4d_OLbQg-Nrllaayv8CVUNmAMKXFLcpsbJVLFky-VweC0qfdB_eSgkaBlFr9r4jgbcFIIiyij4MwwoBjndrLXoyGPWSIffk1AvhMJC3zuno6Hz6SENxyf0b4qknIpLoeEXRC4kAPw/n7r58wfXEj9OhV42EjTixvjhGTAx6lm5EGo505LnhKk)',
      },
      cch_km_voiture: "90.1 kg CO2e, c'est environ 600 km en voiture",
      cch_limite_planete:
        "Et c'est 16.5 j d'\u00e9missions \u00e9quivalentes dans un monde bas carbone (objectif 2050)",
      cch_mediane_cat: '6',
      cch_mediane_affichage: '50 % des produits \u00e9mettent plus de \t5.8 kg CO2e',
      cch_ecart_mediane: '1441%',
      cch_ecart_affichage: "+1441% d'empreinte carbone",
      cch_ecart_affichage_detail: 'Ce produit \u00e9met +1441% par rapport \u00e0 la m\u00e9diane de sa cat\u00e9gorie',
      cch_min_cat: '3',
      cch_min_affichage: 'Le meilleur produit de cette cat\u00e9gorie \u00e9met 3.2 kg CO2e',
      cch_affichage: '90.1 kg CO2e',
      cch_affichage_detail: 'Ce produit \u00e9met             90.1 kg CO2e',
      cch_picto:
        '3222073.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/Cg7FbxZjzXNenRznTxruiQ/ej8LA0MFzFDAuonjmhtIGjM_jmUVdYVkgoE2z15gEdwzQp1HxHgjXg7DJB5zuk5qhuuXMvwSXVCYxxYHF3f8xXKqQWM-bAbPCfU0M6ThLn4i_xFHBFw11yad-AGnv9O9h4DnYf2ACDrqVrgmwefaNQ/k9z9DBziUBoeYW10m8F9yEueIYlgsz2zF6_03in2PGI)',
      cch_niveau: '1 plan\u00e8te',
      'cch_description medaille': "Ce produit n'a pas de qualit\u00e9 particuli\u00e8re pour ce crit\u00e8re",
      cch_img_médaille:
        'planettes2-12.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/_M9H6rc9ajqUARqZBAN3lw/4P0vKT1eL3vUx4d_OLbQg-Nrllaayv8CVUNmAMKXFLcpsbJVLFky-VweC0qfdB_eSgkaBlFr9r4jgbcFIIiyij4MwwoBjndrLXoyGPWSIffk1AvhMJC3zuno6Hz6SENxyf0b4qknIpLoeEXRC4kAPw/n7r58wfXEj9OhV42EjTixvjhGTAx6lm5EGo505LnhKk)',
      'Pays - Etape 1': 'CN',
      'Pays - Etape 2': 'IN',
      'Pays - Etape 3': 'IN',
      'Pays - Etape 4': 'IN',
      'Pays - Etape 5': 'IN',
      'Pays - Etape 6': 'FR',
      'Pays - Etape 7': 'FR',
      'Pays - Etape 8': 'FR',
      country_step: {
        'Pays - Etape 1': 'CN',
        'Pays - Etape 2': 'IN',
        'Pays - Etape 3': 'IN',
        'Pays - Etape 4': 'IN',
        'Pays - Etape 5': 'IN',
        'Pays - Etape 6': 'FR',
        'Pays - Etape 7': 'FR',
        'Pays - Etape 8': 'FR',
      },
      PEF: '42539',
      PEF_affichage_detail: 'Ce produit vaut 42540 \u00b5Pt',
      PEF_limite_planete:
        "42540 \u00b5Pt, c'est l'\u00e9quivalent de 373 heures de l'empreinte annuelle d'un europ\u00e9en",
      PEF_mediane_cat: '758',
      PEF_mediane_affichage: '50 % des produits valent plus de 760 \u00b5Pt',
      PEF_ecart_mediane: '98%',
      PEF_ecart_affichage: "+98% d'empreinte environnementale",
      PEF_ecart_affichage_detail: 'Ce produit \u00e9met +98% par rapport \u00e0 la m\u00e9diane de sa cat\u00e9gorie',
      PEF_min_cat: '493',
      PEF_min_affichage: 'Le meilleur produit de cette cat\u00e9gorie vaut 490 \u00b5Pt',
      pef: {
        PEF: '42539',
        PEF_affichage_detail: 'Ce produit vaut 42540 \u00b5Pt',
        PEF_limite_planete:
          "42540 \u00b5Pt, c'est l'\u00e9quivalent de 373 heures de l'empreinte annuelle d'un europ\u00e9en",
        PEF_mediane_cat: '758',
        PEF_mediane_affichage: '50 % des produits valent plus de 760 \u00b5Pt',
        PEF_ecart_mediane: '98%',
        PEF_ecart_affichage: "+98% d'empreinte environnementale",
        PEF_ecart_affichage_detail: 'Ce produit \u00e9met +98% par rapport \u00e0 la m\u00e9diane de sa cat\u00e9gorie',
        PEF_min_cat: '493',
        PEF_min_affichage: 'Le meilleur produit de cette cat\u00e9gorie vaut 490 \u00b5Pt',
      },
    },
  },
  {
    'ID produit': '8',
    'Num produit': '8',
    Nom: 'Tee-shirt 8',
    Marque: 'Marque X',
    'Description courte': 'Coton recycl\u00e9 - fabriqu\u00e9 en France',
    'Description longue': '',
    Label: 'Global Recycled Standard,Oeko Tex Label Made in Green',
    'Logo (from Label)':
      'image_2023-03-15_114050784.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/-tq4_nXb8vOxprYkKAhFMg/d7ZKNpv5RyJzLWV76kE37h4mRWxWa4HpGTs0eOXZRf3Tb6A-9RbZRmcPvzFWkbJvWX8925P-y-nGAQMBd_zWA4_cHaTKysKTD_XPOH1mqqbJS0Q0jc0MVMcahM0pISYfYzDZ2Elm6SzePZEpC33YW8UOax_FecvFlSWZBBXzZWs/rsy_IgItu7JqrEA22fY_bkWvhh5_k6H2EDRlGHCZO_8),image_2023-03-15_114455758.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/rjm9Fedk3enc4EOBS6KhLQ/JO3wXlCmVLd_cBLC5HI_cFoZ2bbu6bVG6SafcMUbWkLE9pAA58KbO479WmR_Gp1UDOS20YIEP91oOvIiTMmg0TLemkPppu7e5Oe_YFM-lAOEmLAOAOEcl7LUSJnPNrvq-yIDNyz2TWQz35u8ejR2lAMV_MoxdCYWAuQUlX4gUXU/43CnIaZz754zA5haitRU189UR5i-qT_wqAqfp7M4Zl0)',
    URL: '',
    Prix: '\u20ac45.00',
    Image:
      'image_2023-02-23_110442328-removebg-preview.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/z_isavVzEjbfl9EWBSqS4w/qz_mB3JKF91UQInFxFFP9bl3gvOpjGjhcNtJ0OdKdwGkFGDRjWsT97jJfk2Pf4ByXzPl6p7HdLopgPJkwvjPA_YBVgmMlIeZaaXd0FOh85X2V-WJ6SB7cRoQmj3kKa5hVuefjcNGZu4rlj0Yv1Y-W8b_2UYwNkwq3xEpzxOPVPJ77acEWrE3mO5lBh5M_kSW/Va0WZU82L8JiQTN0vX7rHBCLp6G4vLJhVn4pyM4lHMo)',
    Catégorie_softr: 'Habillement',
    Catégorie: 'Textile',
    Type: 'Tee-shirt',
    wtu: '111',
    wtu_picto:
      'image_2023-03-09_113253563.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/Tp5mq0CH0hDIz-R4Byzwzw/_EhCHERMBBISU6erycAlwHKN1BMUHAr_bleE7A9hotX3yhf_t7PhYJmnflU_-ibB3vwSdMxf65cx_xOX28I7kIaiunqt951ZSy8e1joVabEIWzlMjJH2eCibYCO6Qg1Bc_y6wP6WxriFdSyYeQb-zupwjk2j25YvO9lM5dPi7ac/PINf1tWNOYKVczsQ8zt-F5giwzb8HVv3Th85xyyBd8g)',
    pef_picto:
      'image_2023-03-09_113337288.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/BQbvMTJzkU8GzAKN4NL2JQ/0DNEpSjem-PIiiJXwhCV2VXhpbmePHc0ZJ-bzTjSA_F-xds8b6RtzUHTJBIDvYt-tOjc8pd6gnWTsAV489-51uE20FBm5QA85vWG11GsBMjAvirifVZ2e3n7u6ffTEJeIABphjilMCOUnSz2Beiu_LH8AMpbsd2W_3Ll1QDPLUc/mks6bu1Y4A0sc5mTpOOlEZU1nMK2yyXCbsTu92zWJ3E)',
    aff_env_lettre: 'A',
    affichage_environnemental:
      'image_2023-03-09_161457611.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/6odkabnwD751fBtX6ClX3w/RHz2kcsffM_dX-3N0fQkx1wby9s5i41PRiEyrbqGLy2jj0vwQxYSYAae-3EfHP1A9WbGdEt5DnuGCb6BWXI5K4n07ushAh_ghA2Ae9XDGQG_L9dj8pBOfWpGa2uGUR6RJJlx7bTzYL7350eLZRjm2hxrPFxaqdWdWC13xEbFn7I/IwRgLFBQMxU00WQeAD__cLhtCZFQelDLW9XjE6bDjfg)',
    pef_niveau: '5 plan\u00e8tes',
    'Name (from pef_niveau)': '5 plan\u00e8tes',
    pef_img_médaille:
      'planettes2-08.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/sbYVOTqft4m7hi03DzDY9A/PjX0DDpEL9NUBgoecFH6RlAprIuiO0k-kePxqReH4n2RdOxd0jFmGKfLlVIgIxRzC7rSspDph1rvAz5joqydJmcfI4zLLp3I_HLbkDuCqhzzHH5C3Jy7s8ffujDsDEf32sB4ZoNwsSZ7HQLPcrfrRg/l_-4iFk8XLe0QAyM_5kG78MrTBnrKmpTrYGpHJdItvs)',
    'pef_description medaille': 'Ce produit fait partie des 5% des meilleurs de sa cat\u00e9gorie',
    'Name (from cch_niveau)': '5 plan\u00e8tes',
    tracabilite:
      'tracabilite_teeshirt8.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/c0X6VuVGmLQbIoOd90YJJA/9LZc_FG3du-Q3qKsYRKjdQPvYAWWaXuOC4JUIqCIaZDXvryNu1c9ONeHJ5BEl0svPgEgnu-Rcchh7GLhqmbsJHl5dj0E8CL0aWn95Wh8GUiBBJSTFEQ8GcWRBdUc09myW4bUg86tDI7zJm-pxJYAQ6DCZt0wAy0ZKEivum1I2t4/FUSrAKJoehQwJ7coRaxzQ0yv51Wq6Jc90rsdbgVJaxU)',
    vide: '',
    'URL popup':
      'https://images.bannerbear.com/direct/KDWmnAMxpAvM80dVk4/requests/000/035/483/102/APW1bDp49YKdwbEJzjmVoORax/48f827fef618328d498fd47edb877363a3b809fb.png',
    'IMG popup':
      'popup-teeshirt8.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/PIvfkJLt5FsJ-XqCSl56MQ/YzqJ35_6N38Ku6ibrWslEm_InuLlOsGsDBs4VcQQyHDImalpM80pJ5KlPHj1tDtO5KxK421prVM5khpPLMIpRZYTtGG-bdUha56AlogGMmMUJ2aPT2vX8yhzHrk3Ew2uQCTYfe0x-FpwoGd5USZBIQ/Fe1ZeXmRrIY-2wn-pJoB9IWU-J60zqTamhXuljms4bQ)',
    attributes: {
      cch: {
        cch: '3',
        cch_km_voiture: "3.2 kg CO2e, c'est environ 20 km en voiture",
        cch_limite_planete:
          "Et c'est 0.5 j d'\u00e9missions \u00e9quivalentes dans un monde bas carbone (objectif 2050)",
        cch_mediane_cat: '6',
        cch_mediane_affichage: '50 % des produits \u00e9mettent plus de \t5.8 kg CO2e',
        cch_ecart_mediane: '-45%',
        cch_ecart_affichage: "-45% d'empreinte carbone",
        cch_ecart_affichage_detail: 'Ce produit \u00e9met -45% par rapport \u00e0 la m\u00e9diane de sa cat\u00e9gorie',
        cch_min_cat: '3',
        cch_min_affichage: 'Le meilleur produit de cette cat\u00e9gorie \u00e9met 3.2 kg CO2e',
        cch_affichage: '3.2 kg CO2e',
        cch_affichage_detail: 'Ce produit \u00e9met             3.2 kg CO2e',
        cch_picto:
          '3222073.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/Cg7FbxZjzXNenRznTxruiQ/ej8LA0MFzFDAuonjmhtIGjM_jmUVdYVkgoE2z15gEdwzQp1HxHgjXg7DJB5zuk5qhuuXMvwSXVCYxxYHF3f8xXKqQWM-bAbPCfU0M6ThLn4i_xFHBFw11yad-AGnv9O9h4DnYf2ACDrqVrgmwefaNQ/k9z9DBziUBoeYW10m8F9yEueIYlgsz2zF6_03in2PGI)',
        cch_niveau: '5 plan\u00e8tes',
        'cch_description medaille': 'Ce produit fait partie des 5% des meilleurs de sa cat\u00e9gorie',
        cch_img_médaille:
          'planettes2-08.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/sbYVOTqft4m7hi03DzDY9A/PjX0DDpEL9NUBgoecFH6RlAprIuiO0k-kePxqReH4n2RdOxd0jFmGKfLlVIgIxRzC7rSspDph1rvAz5joqydJmcfI4zLLp3I_HLbkDuCqhzzHH5C3Jy7s8ffujDsDEf32sB4ZoNwsSZ7HQLPcrfrRg/l_-4iFk8XLe0QAyM_5kG78MrTBnrKmpTrYGpHJdItvs)',
      },
      cch_km_voiture: "3.2 kg CO2e, c'est environ 20 km en voiture",
      cch_limite_planete: "Et c'est 0.5 j d'\u00e9missions \u00e9quivalentes dans un monde bas carbone (objectif 2050)",
      cch_mediane_cat: '6',
      cch_mediane_affichage: '50 % des produits \u00e9mettent plus de \t5.8 kg CO2e',
      cch_ecart_mediane: '-45%',
      cch_ecart_affichage: "-45% d'empreinte carbone",
      cch_ecart_affichage_detail: 'Ce produit \u00e9met -45% par rapport \u00e0 la m\u00e9diane de sa cat\u00e9gorie',
      cch_min_cat: '3',
      cch_min_affichage: 'Le meilleur produit de cette cat\u00e9gorie \u00e9met 3.2 kg CO2e',
      cch_affichage: '3.2 kg CO2e',
      cch_affichage_detail: 'Ce produit \u00e9met             3.2 kg CO2e',
      cch_picto:
        '3222073.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/Cg7FbxZjzXNenRznTxruiQ/ej8LA0MFzFDAuonjmhtIGjM_jmUVdYVkgoE2z15gEdwzQp1HxHgjXg7DJB5zuk5qhuuXMvwSXVCYxxYHF3f8xXKqQWM-bAbPCfU0M6ThLn4i_xFHBFw11yad-AGnv9O9h4DnYf2ACDrqVrgmwefaNQ/k9z9DBziUBoeYW10m8F9yEueIYlgsz2zF6_03in2PGI)',
      cch_niveau: '5 plan\u00e8tes',
      'cch_description medaille': 'Ce produit fait partie des 5% des meilleurs de sa cat\u00e9gorie',
      cch_img_médaille:
        'planettes2-08.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/sbYVOTqft4m7hi03DzDY9A/PjX0DDpEL9NUBgoecFH6RlAprIuiO0k-kePxqReH4n2RdOxd0jFmGKfLlVIgIxRzC7rSspDph1rvAz5joqydJmcfI4zLLp3I_HLbkDuCqhzzHH5C3Jy7s8ffujDsDEf32sB4ZoNwsSZ7HQLPcrfrRg/l_-4iFk8XLe0QAyM_5kG78MrTBnrKmpTrYGpHJdItvs)',
      'Pays - Etape 1': 'FR',
      'Pays - Etape 2': 'FR',
      'Pays - Etape 3': 'FR',
      'Pays - Etape 4': 'FR',
      'Pays - Etape 5': 'FR',
      'Pays - Etape 6': 'FR',
      'Pays - Etape 7': 'FR',
      'Pays - Etape 8': 'FR',
      country_step: {
        'Pays - Etape 1': 'FR',
        'Pays - Etape 2': 'FR',
        'Pays - Etape 3': 'FR',
        'Pays - Etape 4': 'FR',
        'Pays - Etape 5': 'FR',
        'Pays - Etape 6': 'FR',
        'Pays - Etape 7': 'FR',
        'Pays - Etape 8': 'FR',
      },
      PEF: '493',
      PEF_affichage_detail: 'Ce produit vaut 490 \u00b5Pt',
      PEF_limite_planete:
        "490 \u00b5Pt, c'est l'\u00e9quivalent de 4 heures de l'empreinte annuelle d'un europ\u00e9en",
      PEF_mediane_cat: '758',
      PEF_mediane_affichage: '50 % des produits valent plus de 760 \u00b5Pt',
      PEF_ecart_mediane: '-54%',
      PEF_ecart_affichage: "-54% d'empreinte environnementale",
      PEF_ecart_affichage_detail: 'Ce produit \u00e9met -54% par rapport \u00e0 la m\u00e9diane de sa cat\u00e9gorie',
      PEF_min_cat: '493',
      PEF_min_affichage: 'Le meilleur produit de cette cat\u00e9gorie vaut 490 \u00b5Pt',
      pef: {
        PEF: '493',
        PEF_affichage_detail: 'Ce produit vaut 490 \u00b5Pt',
        PEF_limite_planete:
          "490 \u00b5Pt, c'est l'\u00e9quivalent de 4 heures de l'empreinte annuelle d'un europ\u00e9en",
        PEF_mediane_cat: '758',
        PEF_mediane_affichage: '50 % des produits valent plus de 760 \u00b5Pt',
        PEF_ecart_mediane: '-54%',
        PEF_ecart_affichage: "-54% d'empreinte environnementale",
        PEF_ecart_affichage_detail: 'Ce produit \u00e9met -54% par rapport \u00e0 la m\u00e9diane de sa cat\u00e9gorie',
        PEF_min_cat: '493',
        PEF_min_affichage: 'Le meilleur produit de cette cat\u00e9gorie vaut 490 \u00b5Pt',
      },
    },
  },
  {
    'ID produit': '9',
    'Num produit': '9',
    Nom: 'Tee-shirt 9',
    Marque: 'Marque X',
    'Description courte': 'Polyester recycl\u00e9 - fabriqu\u00e9 en France',
    'Description longue': '',
    Label: 'Global Recycled Standard',
    'Logo (from Label)':
      'image_2023-03-15_114050784.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/-tq4_nXb8vOxprYkKAhFMg/d7ZKNpv5RyJzLWV76kE37h4mRWxWa4HpGTs0eOXZRf3Tb6A-9RbZRmcPvzFWkbJvWX8925P-y-nGAQMBd_zWA4_cHaTKysKTD_XPOH1mqqbJS0Q0jc0MVMcahM0pISYfYzDZ2Elm6SzePZEpC33YW8UOax_FecvFlSWZBBXzZWs/rsy_IgItu7JqrEA22fY_bkWvhh5_k6H2EDRlGHCZO_8)',
    URL: '',
    Prix: '\u20ac30.00',
    Image:
      'image_2023-02-23_110706893-removebg-preview.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/KdYkJuFBnGYUf-5m7uieaw/_hYuK02z2utq5HNLIbBTNWeAI8lQxwP-UIctbnzs8w0qd-7C_GuCptyK96_9uichGLDGsfy7ULkiBiB7uo6dEQldU5WSSS2ehsbAyHzm5haJ5w6tEyz67MbWDcHI4rA2lq_Po7cbzGm7pf5O70-pdyJajTKAdlsRgQAYYlw1WbdQ0TWDQT3sJSNUWoNMu2vI/uxC8SPfFDWIqnOmLyCBEqqbMTQ_Oh-nGbO3A4Ck-yPI)',
    Catégorie_softr: 'Habillement',
    Catégorie: 'Textile',
    Type: 'Tee-shirt',
    wtu: '59',
    wtu_picto:
      'image_2023-03-09_113253563.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/Tp5mq0CH0hDIz-R4Byzwzw/_EhCHERMBBISU6erycAlwHKN1BMUHAr_bleE7A9hotX3yhf_t7PhYJmnflU_-ibB3vwSdMxf65cx_xOX28I7kIaiunqt951ZSy8e1joVabEIWzlMjJH2eCibYCO6Qg1Bc_y6wP6WxriFdSyYeQb-zupwjk2j25YvO9lM5dPi7ac/PINf1tWNOYKVczsQ8zt-F5giwzb8HVv3Th85xyyBd8g)',
    pef_picto:
      'image_2023-03-09_113337288.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/BQbvMTJzkU8GzAKN4NL2JQ/0DNEpSjem-PIiiJXwhCV2VXhpbmePHc0ZJ-bzTjSA_F-xds8b6RtzUHTJBIDvYt-tOjc8pd6gnWTsAV489-51uE20FBm5QA85vWG11GsBMjAvirifVZ2e3n7u6ffTEJeIABphjilMCOUnSz2Beiu_LH8AMpbsd2W_3Ll1QDPLUc/mks6bu1Y4A0sc5mTpOOlEZU1nMK2yyXCbsTu92zWJ3E)',
    aff_env_lettre: 'B',
    affichage_environnemental:
      'image_2023-03-09_161416887.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/upt1iV85WDyZ3ry32wobnw/cZH9UmIoiDsiCQ-jFNRlT3fWSjSiStraeGe8-ZM8Tu3HfVeS8fMOG0wQGb0kr3k2V6KiL0UIxsURM7TsP6-WnbwAzdIuONxvm4Qn-CMEKjHQLLVYStcu5KmrvJwJWBda3CUv_ArYpfxji6TNTpMFa8-nveHR4EGFxoNGYD4Sqfo/PhoZrjOuhGmSAOXAxmtUMw-pAbjQNwr4ApTZ7o3ysWA)',
    pef_niveau: '3 plan\u00e8tes',
    'Name (from pef_niveau)': '3 plan\u00e8tes',
    pef_img_médaille:
      'planettes2-10.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/mVM9KoEIOlq297o8ahR1Bw/A5rkxooKkBnJ2622FnDndxZ1VV8dnf_7c7tJ58LII4_FlsLPA7aQ5mUc57xXn-RqWHZohU3m3irG2-nM5DQElq0I4TMfuBst-MUtPLORcC1IuqCFxjAqcUEz7g4KZvGOR4LpXOH-gWdJkyN3ez-N0Q/XRwU39-tqUork8C-E8fdV2HncmV-Rr6HTKsFAdeJ-Wo)',
    'pef_description medaille': 'Ce produit fait partie des 25% des meilleurs de sa cat\u00e9gorie',
    'Name (from cch_niveau)': '3 plan\u00e8tes',
    tracabilite:
      'tracabilite_teeshirt9.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/ezw5BnQNd6O1j2xmxfD_HA/xH1gLTwzdyUYwuedT_CdU6xzgSoC1m5Yms8Ov4vr9f-uFhCMylcr2ZFV3Ln71sgpvsdladrxW2AygjvT5bVuvPgewNsd7uf0GUdN6ZxSJNYr4j6dgrI0CG-2WD-Nam9OjZFykvOhy37F-FG2ScwADR6JC8LeUGwV0YSbwNa4jMo/WvjXXtzzrEm15hRPHcIltzAD-1H5YSLPkqSdmoZDCnA)',
    vide: '',
    'URL popup':
      'https://images.bannerbear.com/direct/KDWmnAMxpAvM80dVk4/requests/000/035/483/092/w0gWbdEPaYabkpRgzrVklOA5j/49f0e86b625c30aa3404ab969554d8e7bdd69945.png',
    'IMG popup':
      'popup-teeshirt9.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/rCbs5egfdXW4OlkoNYPA0w/WJqSWX6nTK-FIXc_d8fgA5N0O8wtJ7qJyooeQ_9fq4L0Gj1bmP7XNtpc9SJAZg3km8b8gP98oMKc5vvpA7-5g2B-3vCZTG9tgcVFuUQsS6S8P406U-2M8rqb8CRa7wbTSihqR8sfLq_hpHbM5Mf3rg/pwjpe0dtCmTxRp4wsoXUUk9jKclLP8Uh-68bOxlhcsc)',
    attributes: {
      cch: {
        cch: '4',
        cch_km_voiture: "3.8 kg CO2e, c'est environ 30 km en voiture",
        cch_limite_planete:
          "Et c'est 0.5 j d'\u00e9missions \u00e9quivalentes dans un monde bas carbone (objectif 2050)",
        cch_mediane_cat: '6',
        cch_mediane_affichage: '50 % des produits \u00e9mettent plus de \t5.8 kg CO2e',
        cch_ecart_mediane: '-35%',
        cch_ecart_affichage: "-35% d'empreinte carbone",
        cch_ecart_affichage_detail: 'Ce produit \u00e9met -35% par rapport \u00e0 la m\u00e9diane de sa cat\u00e9gorie',
        cch_min_cat: '3',
        cch_min_affichage: 'Le meilleur produit de cette cat\u00e9gorie \u00e9met 3.2 kg CO2e',
        cch_affichage: '3.8 kg CO2e',
        cch_affichage_detail: 'Ce produit \u00e9met             3.8 kg CO2e',
        cch_picto:
          '3222073.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/Cg7FbxZjzXNenRznTxruiQ/ej8LA0MFzFDAuonjmhtIGjM_jmUVdYVkgoE2z15gEdwzQp1HxHgjXg7DJB5zuk5qhuuXMvwSXVCYxxYHF3f8xXKqQWM-bAbPCfU0M6ThLn4i_xFHBFw11yad-AGnv9O9h4DnYf2ACDrqVrgmwefaNQ/k9z9DBziUBoeYW10m8F9yEueIYlgsz2zF6_03in2PGI)',
        cch_niveau: '3 plan\u00e8tes',
        'cch_description medaille': 'Ce produit fait partie des 25% des meilleurs de sa cat\u00e9gorie',
        cch_img_médaille:
          'planettes2-10.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/mVM9KoEIOlq297o8ahR1Bw/A5rkxooKkBnJ2622FnDndxZ1VV8dnf_7c7tJ58LII4_FlsLPA7aQ5mUc57xXn-RqWHZohU3m3irG2-nM5DQElq0I4TMfuBst-MUtPLORcC1IuqCFxjAqcUEz7g4KZvGOR4LpXOH-gWdJkyN3ez-N0Q/XRwU39-tqUork8C-E8fdV2HncmV-Rr6HTKsFAdeJ-Wo)',
      },
      cch_km_voiture: "3.8 kg CO2e, c'est environ 30 km en voiture",
      cch_limite_planete: "Et c'est 0.5 j d'\u00e9missions \u00e9quivalentes dans un monde bas carbone (objectif 2050)",
      cch_mediane_cat: '6',
      cch_mediane_affichage: '50 % des produits \u00e9mettent plus de \t5.8 kg CO2e',
      cch_ecart_mediane: '-35%',
      cch_ecart_affichage: "-35% d'empreinte carbone",
      cch_ecart_affichage_detail: 'Ce produit \u00e9met -35% par rapport \u00e0 la m\u00e9diane de sa cat\u00e9gorie',
      cch_min_cat: '3',
      cch_min_affichage: 'Le meilleur produit de cette cat\u00e9gorie \u00e9met 3.2 kg CO2e',
      cch_affichage: '3.8 kg CO2e',
      cch_affichage_detail: 'Ce produit \u00e9met             3.8 kg CO2e',
      cch_picto:
        '3222073.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/Cg7FbxZjzXNenRznTxruiQ/ej8LA0MFzFDAuonjmhtIGjM_jmUVdYVkgoE2z15gEdwzQp1HxHgjXg7DJB5zuk5qhuuXMvwSXVCYxxYHF3f8xXKqQWM-bAbPCfU0M6ThLn4i_xFHBFw11yad-AGnv9O9h4DnYf2ACDrqVrgmwefaNQ/k9z9DBziUBoeYW10m8F9yEueIYlgsz2zF6_03in2PGI)',
      cch_niveau: '3 plan\u00e8tes',
      'cch_description medaille': 'Ce produit fait partie des 25% des meilleurs de sa cat\u00e9gorie',
      cch_img_médaille:
        'planettes2-10.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/mVM9KoEIOlq297o8ahR1Bw/A5rkxooKkBnJ2622FnDndxZ1VV8dnf_7c7tJ58LII4_FlsLPA7aQ5mUc57xXn-RqWHZohU3m3irG2-nM5DQElq0I4TMfuBst-MUtPLORcC1IuqCFxjAqcUEz7g4KZvGOR4LpXOH-gWdJkyN3ez-N0Q/XRwU39-tqUork8C-E8fdV2HncmV-Rr6HTKsFAdeJ-Wo)',
      'Pays - Etape 1': 'CN',
      'Pays - Etape 2': 'FR',
      'Pays - Etape 3': 'FR',
      'Pays - Etape 4': 'FR',
      'Pays - Etape 5': 'FR',
      'Pays - Etape 6': 'FR',
      'Pays - Etape 7': 'FR',
      'Pays - Etape 8': 'FR',
      country_step: {
        'Pays - Etape 1': 'CN',
        'Pays - Etape 2': 'FR',
        'Pays - Etape 3': 'FR',
        'Pays - Etape 4': 'FR',
        'Pays - Etape 5': 'FR',
        'Pays - Etape 6': 'FR',
        'Pays - Etape 7': 'FR',
        'Pays - Etape 8': 'FR',
      },
      PEF: '519',
      PEF_affichage_detail: 'Ce produit vaut 520 \u00b5Pt',
      PEF_limite_planete:
        "520 \u00b5Pt, c'est l'\u00e9quivalent de 5 heures de l'empreinte annuelle d'un europ\u00e9en",
      PEF_mediane_cat: '758',
      PEF_mediane_affichage: '50 % des produits valent plus de 760 \u00b5Pt',
      PEF_ecart_mediane: '-46%',
      PEF_ecart_affichage: "-46% d'empreinte environnementale",
      PEF_ecart_affichage_detail: 'Ce produit \u00e9met -46% par rapport \u00e0 la m\u00e9diane de sa cat\u00e9gorie',
      PEF_min_cat: '493',
      PEF_min_affichage: 'Le meilleur produit de cette cat\u00e9gorie vaut 490 \u00b5Pt',
      pef: {
        PEF: '519',
        PEF_affichage_detail: 'Ce produit vaut 520 \u00b5Pt',
        PEF_limite_planete:
          "520 \u00b5Pt, c'est l'\u00e9quivalent de 5 heures de l'empreinte annuelle d'un europ\u00e9en",
        PEF_mediane_cat: '758',
        PEF_mediane_affichage: '50 % des produits valent plus de 760 \u00b5Pt',
        PEF_ecart_mediane: '-46%',
        PEF_ecart_affichage: "-46% d'empreinte environnementale",
        PEF_ecart_affichage_detail: 'Ce produit \u00e9met -46% par rapport \u00e0 la m\u00e9diane de sa cat\u00e9gorie',
        PEF_min_cat: '493',
        PEF_min_affichage: 'Le meilleur produit de cette cat\u00e9gorie vaut 490 \u00b5Pt',
      },
    },
  },
  {
    'ID produit': '10',
    'Num produit': '10',
    Nom: 'Tee-shirt 10',
    Marque: 'Marque X',
    'Description courte': 'Polyester recycl\u00e9 - fabriqu\u00e9 en Chine',
    'Description longue': '',
    Label: 'Global Recycled Standard',
    'Logo (from Label)':
      'image_2023-03-15_114050784.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/-tq4_nXb8vOxprYkKAhFMg/d7ZKNpv5RyJzLWV76kE37h4mRWxWa4HpGTs0eOXZRf3Tb6A-9RbZRmcPvzFWkbJvWX8925P-y-nGAQMBd_zWA4_cHaTKysKTD_XPOH1mqqbJS0Q0jc0MVMcahM0pISYfYzDZ2Elm6SzePZEpC33YW8UOax_FecvFlSWZBBXzZWs/rsy_IgItu7JqrEA22fY_bkWvhh5_k6H2EDRlGHCZO_8)',
    URL: '',
    Prix: '\u20ac10.00',
    Image:
      'image_2023-02-23_110535333-removebg-preview.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/ekBEwVo4jQxU8e87PyEcrQ/IlpbUPK85SRw3ANXmdsoL-zKf1p9AAifUlzw7a-9MI-zJQKN0ECiOJU7U_hMMkkLZW4eakkRkgtvIgeWSJeW4oCGyQEoRKEj6mjPDK4gaq-YxFEgfGtsDSZXjmXPhjzUjkGyHwpuWCWuiITzKIsXN9f2oZEXC4bVl-KJ0UGaEZlzGLa3KvacY9qe-zUZKL4Y/zLUoUBBROqSykXfx4TI_ytU9aMNs6kawcSWWsXXFfM8)',
    Catégorie_softr: 'Habillement',
    Catégorie: 'Textile',
    Type: 'Tee-shirt',
    wtu: '59',
    wtu_picto:
      'image_2023-03-09_113253563.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/Tp5mq0CH0hDIz-R4Byzwzw/_EhCHERMBBISU6erycAlwHKN1BMUHAr_bleE7A9hotX3yhf_t7PhYJmnflU_-ibB3vwSdMxf65cx_xOX28I7kIaiunqt951ZSy8e1joVabEIWzlMjJH2eCibYCO6Qg1Bc_y6wP6WxriFdSyYeQb-zupwjk2j25YvO9lM5dPi7ac/PINf1tWNOYKVczsQ8zt-F5giwzb8HVv3Th85xyyBd8g)',
    pef_picto:
      'image_2023-03-09_113337288.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/BQbvMTJzkU8GzAKN4NL2JQ/0DNEpSjem-PIiiJXwhCV2VXhpbmePHc0ZJ-bzTjSA_F-xds8b6RtzUHTJBIDvYt-tOjc8pd6gnWTsAV489-51uE20FBm5QA85vWG11GsBMjAvirifVZ2e3n7u6ffTEJeIABphjilMCOUnSz2Beiu_LH8AMpbsd2W_3Ll1QDPLUc/mks6bu1Y4A0sc5mTpOOlEZU1nMK2yyXCbsTu92zWJ3E)',
    aff_env_lettre: 'C',
    affichage_environnemental:
      'image_2023-03-09_161348128.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/F79k_Nin28RRWTgo_eNWyA/m_4mDo66omxCesbGCzNAuTRiLK_k_FYkTC7zriUTRHBXAqmtFqpsgjAhJ_DZksWf2Hwo-Lm0-BcXMJCtQ0Q52ai2lm8NDuJ1V5jlXaVtPLVDkKLLeUTMa3Y1l9VGHZ6xZNcOm6bIuVpW4dzj_a2fPO7WwT55lIa2MwtLFbmWvmE/Z5KVSdRhGZig6AAV3lIH8ipN6zUDys5ruUE1jbDbWwg)',
    pef_niveau: '2 plan\u00e8tes',
    'Name (from pef_niveau)': '2 plan\u00e8tes',
    pef_img_médaille:
      'planettes2-11.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/R66-AtqV295Qa2JtdkyRvw/IWepgFSNhQWt4zlKKakQKD6FUwqKoqOkgaqvKqlnMBtiqkWBSnY9pk4-9VcChvs3S02nLgQBcucFLnJisjRb6QlIGWWwNK624Htsf7y5AS3J3XsAB2VUtphGiLMu3FqaIKsWdxwCSsG90Mq74K2DPA/W0WIuQxot0HuYxOprYdyWnhmxjbV_k3UhLMqoG3xFSQ)',
    'pef_description medaille': 'Ce produit fait partie des 50% des meilleurs de sa cat\u00e9gorie',
    'Name (from cch_niveau)': '2 plan\u00e8tes',
    tracabilite:
      'tracabilite_teeshirt10.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/9ZwvF-SeqWvHauxVE8bGRw/k50A4NoPdWDeJkFxnOadJmAAiiBwfjBSuVZvh2sRBq2gMt3snBxcZaG0cmx-5g2jXmrd_uWzNoXrsy9VmaPtq1vre9bzNW4oXZDagEcs7rHb4tkJDc6tXlURQX4uFKxYcROFIcr6MNdzpl1p35dGLwHcTn-F6ZnxQry_8_EB5_Q/iG6uG_tjA8fIStlHxqMR-KjgBux3BvyHRsGwaHZwubs)',
    vide: '',
    'URL popup':
      'https://images.bannerbear.com/direct/KDWmnAMxpAvM80dVk4/requests/000/035/483/101/9e2VGL0qn6VLnNqVYEAv5mxr1/1fdba53d13c165b82ef3527deedab5f366224014.png',
    'IMG popup':
      'popup-teeshirt10.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/jTAm9lx3YCb_JS6nYTNetQ/Ak6SdvMBT3NFKNOZY78Qw-ttSCOVI0Dp0dq7XYmhUSSl4rzxb1XQh5Luj4puG67221sawK6VLY92M_5QUGB1pnChIUmRCnZEd4m49Ts2rhYa0V2Y6XRCZ2z12NboptdDO-DRyMXbvUJ9URL3OKwVOQ/wX0k5DupUgapPSa8DDBAvgZGWGW98RBBon2or6B9UTg)',
    attributes: {
      cch: {
        cch: '6',
        cch_km_voiture: "5.8 kg CO2e, c'est environ 40 km en voiture",
        cch_limite_planete: "Et c'est 1 j d'\u00e9missions \u00e9quivalentes dans un monde bas carbone (objectif 2050)",
        cch_mediane_cat: '6',
        cch_mediane_affichage: '50 % des produits \u00e9mettent plus de \t5.8 kg CO2e',
        cch_ecart_mediane: '0%',
        cch_ecart_affichage: "0% d'empreinte carbone",
        cch_ecart_affichage_detail: 'Ce produit \u00e9met 0% par rapport \u00e0 la m\u00e9diane de sa cat\u00e9gorie',
        cch_min_cat: '3',
        cch_min_affichage: 'Le meilleur produit de cette cat\u00e9gorie \u00e9met 3.2 kg CO2e',
        cch_affichage: '5.8 kg CO2e',
        cch_affichage_detail: 'Ce produit \u00e9met             5.8 kg CO2e',
        cch_picto:
          '3222073.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/Cg7FbxZjzXNenRznTxruiQ/ej8LA0MFzFDAuonjmhtIGjM_jmUVdYVkgoE2z15gEdwzQp1HxHgjXg7DJB5zuk5qhuuXMvwSXVCYxxYHF3f8xXKqQWM-bAbPCfU0M6ThLn4i_xFHBFw11yad-AGnv9O9h4DnYf2ACDrqVrgmwefaNQ/k9z9DBziUBoeYW10m8F9yEueIYlgsz2zF6_03in2PGI)',
        cch_niveau: '2 plan\u00e8tes',
        'cch_description medaille': 'Ce produit fait partie des 50% des meilleurs de sa cat\u00e9gorie',
        cch_img_médaille:
          'planettes2-11.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/R66-AtqV295Qa2JtdkyRvw/IWepgFSNhQWt4zlKKakQKD6FUwqKoqOkgaqvKqlnMBtiqkWBSnY9pk4-9VcChvs3S02nLgQBcucFLnJisjRb6QlIGWWwNK624Htsf7y5AS3J3XsAB2VUtphGiLMu3FqaIKsWdxwCSsG90Mq74K2DPA/W0WIuQxot0HuYxOprYdyWnhmxjbV_k3UhLMqoG3xFSQ)',
      },
      cch_km_voiture: "5.8 kg CO2e, c'est environ 40 km en voiture",
      cch_limite_planete: "Et c'est 1 j d'\u00e9missions \u00e9quivalentes dans un monde bas carbone (objectif 2050)",
      cch_mediane_cat: '6',
      cch_mediane_affichage: '50 % des produits \u00e9mettent plus de \t5.8 kg CO2e',
      cch_ecart_mediane: '0%',
      cch_ecart_affichage: "0% d'empreinte carbone",
      cch_ecart_affichage_detail: 'Ce produit \u00e9met 0% par rapport \u00e0 la m\u00e9diane de sa cat\u00e9gorie',
      cch_min_cat: '3',
      cch_min_affichage: 'Le meilleur produit de cette cat\u00e9gorie \u00e9met 3.2 kg CO2e',
      cch_affichage: '5.8 kg CO2e',
      cch_affichage_detail: 'Ce produit \u00e9met             5.8 kg CO2e',
      cch_picto:
        '3222073.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/Cg7FbxZjzXNenRznTxruiQ/ej8LA0MFzFDAuonjmhtIGjM_jmUVdYVkgoE2z15gEdwzQp1HxHgjXg7DJB5zuk5qhuuXMvwSXVCYxxYHF3f8xXKqQWM-bAbPCfU0M6ThLn4i_xFHBFw11yad-AGnv9O9h4DnYf2ACDrqVrgmwefaNQ/k9z9DBziUBoeYW10m8F9yEueIYlgsz2zF6_03in2PGI)',
      cch_niveau: '2 plan\u00e8tes',
      'cch_description medaille': 'Ce produit fait partie des 50% des meilleurs de sa cat\u00e9gorie',
      cch_img_médaille:
        'planettes2-11.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/R66-AtqV295Qa2JtdkyRvw/IWepgFSNhQWt4zlKKakQKD6FUwqKoqOkgaqvKqlnMBtiqkWBSnY9pk4-9VcChvs3S02nLgQBcucFLnJisjRb6QlIGWWwNK624Htsf7y5AS3J3XsAB2VUtphGiLMu3FqaIKsWdxwCSsG90Mq74K2DPA/W0WIuQxot0HuYxOprYdyWnhmxjbV_k3UhLMqoG3xFSQ)',
      'Pays - Etape 1': 'CN',
      'Pays - Etape 2': 'CN',
      'Pays - Etape 3': 'CN',
      'Pays - Etape 4': 'CN',
      'Pays - Etape 5': 'CN',
      'Pays - Etape 6': 'FR',
      'Pays - Etape 7': 'FR',
      'Pays - Etape 8': 'FR',
      country_step: {
        'Pays - Etape 1': 'CN',
        'Pays - Etape 2': 'CN',
        'Pays - Etape 3': 'CN',
        'Pays - Etape 4': 'CN',
        'Pays - Etape 5': 'CN',
        'Pays - Etape 6': 'FR',
        'Pays - Etape 7': 'FR',
        'Pays - Etape 8': 'FR',
      },
      PEF: '743',
      PEF_affichage_detail: 'Ce produit vaut 740 \u00b5Pt',
      PEF_limite_planete:
        "740 \u00b5Pt, c'est l'\u00e9quivalent de 7 heures de l'empreinte annuelle d'un europ\u00e9en",
      PEF_mediane_cat: '758',
      PEF_mediane_affichage: '50 % des produits valent plus de 760 \u00b5Pt',
      PEF_ecart_mediane: '-2%',
      PEF_ecart_affichage: "-2% d'empreinte environnementale",
      PEF_ecart_affichage_detail: 'Ce produit \u00e9met -2% par rapport \u00e0 la m\u00e9diane de sa cat\u00e9gorie',
      PEF_min_cat: '493',
      PEF_min_affichage: 'Le meilleur produit de cette cat\u00e9gorie vaut 490 \u00b5Pt',
      pef: {
        PEF: '743',
        PEF_affichage_detail: 'Ce produit vaut 740 \u00b5Pt',
        PEF_limite_planete:
          "740 \u00b5Pt, c'est l'\u00e9quivalent de 7 heures de l'empreinte annuelle d'un europ\u00e9en",
        PEF_mediane_cat: '758',
        PEF_mediane_affichage: '50 % des produits valent plus de 760 \u00b5Pt',
        PEF_ecart_mediane: '-2%',
        PEF_ecart_affichage: "-2% d'empreinte environnementale",
        PEF_ecart_affichage_detail: 'Ce produit \u00e9met -2% par rapport \u00e0 la m\u00e9diane de sa cat\u00e9gorie',
        PEF_min_cat: '493',
        PEF_min_affichage: 'Le meilleur produit de cette cat\u00e9gorie vaut 490 \u00b5Pt',
      },
    },
  },
  {
    'ID produit': '11',
    'Num produit': '11',
    Nom: 'Tee-shirt 11',
    Marque: 'Marque X',
    'Description courte': 'Laine - fabriqu\u00e9 en Chine',
    'Description longue': '',
    Label: 'Oeko Tex Label Made in Green,Global Organic Textile  Standard',
    'Logo (from Label)':
      'image_2023-03-15_114455758.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/rjm9Fedk3enc4EOBS6KhLQ/JO3wXlCmVLd_cBLC5HI_cFoZ2bbu6bVG6SafcMUbWkLE9pAA58KbO479WmR_Gp1UDOS20YIEP91oOvIiTMmg0TLemkPppu7e5Oe_YFM-lAOEmLAOAOEcl7LUSJnPNrvq-yIDNyz2TWQz35u8ejR2lAMV_MoxdCYWAuQUlX4gUXU/43CnIaZz754zA5haitRU189UR5i-qT_wqAqfp7M4Zl0),image_2023-03-15_114621318.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/plydAiTv9TgoIKkyHxFC5Q/PSqT4Ejb5g-U4tgNxQIvgvuTCTFYDeHUW-1r0clAJqebyBxK0QLAVe6F47xSYwTLSmlo--a7lhhMypSmAF6pY3rclq9AB6HBTWyUTwO1vsI7USv0QPtIoeiEYArc9WHWAL8OzRAe-as7baYqaCXj8RTSzikLRafNny3EvdNfLXo/9cuw9XjWlsV7FOxPTerJx2FJ_X9_bwDH6ffpUpB_Px0)',
    URL: '',
    Prix: '\u20ac70.00',
    Image:
      'image_2023-02-23_141414712-removebg-preview.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/XVBcCxEcLT-4pdwYDRfDsA/4soWHmRAriix3N3JjAsy34gxuz9kSw5bi7uqtWIjXE_mGdQa-ZAzwEu79juNpzFXbDKyNzZNedoPL8SivmalgTwSWxCLKWrLPDFKiLTvsh8hMI31gXvDK4gbY5X-xVnFa6zMZPmmMRPkegIMpOlzZKuzayQmQ6VZsBFAUhFYW8vtMw36ZMkZWLBDp9ERZCOb/Kc2POwPKLxU97FLcL6OLy-o0hkQV6zbSQuOKOxkt_bk)',
    Catégorie_softr: 'Habillement',
    Catégorie: 'Textile',
    Type: 'Tee-shirt',
    wtu: '77',
    wtu_picto:
      'image_2023-03-09_113253563.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/Tp5mq0CH0hDIz-R4Byzwzw/_EhCHERMBBISU6erycAlwHKN1BMUHAr_bleE7A9hotX3yhf_t7PhYJmnflU_-ibB3vwSdMxf65cx_xOX28I7kIaiunqt951ZSy8e1joVabEIWzlMjJH2eCibYCO6Qg1Bc_y6wP6WxriFdSyYeQb-zupwjk2j25YvO9lM5dPi7ac/PINf1tWNOYKVczsQ8zt-F5giwzb8HVv3Th85xyyBd8g)',
    pef_picto:
      'image_2023-03-09_113337288.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/BQbvMTJzkU8GzAKN4NL2JQ/0DNEpSjem-PIiiJXwhCV2VXhpbmePHc0ZJ-bzTjSA_F-xds8b6RtzUHTJBIDvYt-tOjc8pd6gnWTsAV489-51uE20FBm5QA85vWG11GsBMjAvirifVZ2e3n7u6ffTEJeIABphjilMCOUnSz2Beiu_LH8AMpbsd2W_3Ll1QDPLUc/mks6bu1Y4A0sc5mTpOOlEZU1nMK2yyXCbsTu92zWJ3E)',
    aff_env_lettre: 'D',
    affichage_environnemental:
      'image_2023-03-09_161446878.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/KoNG5zbfYyv3JzMRkpCdIA/mdyZY1V98Dro-KxoYjRBWlUJ1RgXT0VxSNW82TojcbLPoMtvnJGYGmfNKIJnOYiYr8MtCTCPwUKNTZKv_xlHf-jYcJjsUJeEed5fB0-WSZtv2arZNa7BgV8SmoCbF6qgGE5vgD9wSfWwczr3VjhlR-JNI1lhTGdn5NyOIuVH29U/d5wCcmj_wDNPo1VeNqngGQfQM3wT8nYCNPrrWOulgug)',
    pef_niveau: '1 plan\u00e8te',
    'Name (from pef_niveau)': '1 plan\u00e8te',
    pef_img_médaille:
      'planettes2-12.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/_M9H6rc9ajqUARqZBAN3lw/4P0vKT1eL3vUx4d_OLbQg-Nrllaayv8CVUNmAMKXFLcpsbJVLFky-VweC0qfdB_eSgkaBlFr9r4jgbcFIIiyij4MwwoBjndrLXoyGPWSIffk1AvhMJC3zuno6Hz6SENxyf0b4qknIpLoeEXRC4kAPw/n7r58wfXEj9OhV42EjTixvjhGTAx6lm5EGo505LnhKk)',
    'pef_description medaille': "Ce produit n'a pas de qualit\u00e9 particuli\u00e8re pour ce crit\u00e8re",
    'Name (from cch_niveau)': '1 plan\u00e8te',
    tracabilite:
      'tracabilite_teeshirt11.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/gipijbXUv-VGAfCQdR4OdQ/C_OPjrRfRRdwxvFc2nAJ7KbzMeN8tigTMVqFGTcfFo96EwxF-9UC4jqHucmD-tR381X3XxB7-OpzHVu9JuQ5DUX67PlBt2gNExDunnRgMjyookS-Sy8e-iOmkKr1SLGSZoPOdhDPdZfoyZCEoXsAi1cDp4U0oH-vefORbqJvPjE/lplV7w2q52ASHGD4UF2cVvhtmt1dfyA0Cg0-80r1wl4)',
    vide: '',
    'URL popup':
      'https://images.bannerbear.com/direct/KDWmnAMxpAvM80dVk4/requests/000/035/483/104/w0gWbdEPaYabkpwvzrVklOA5j/2cc67972a8b91be0aec2e28d348e3b2b6cb11924.png',
    'IMG popup':
      'popup-teeshirt11.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/q6_61HCHelxZkK6a97y0Gg/PoOg5GkcMEZFi0c9v94Ix8sCk_st44rMR246rjYYhP570O0YbubdAeZaMdr1pFfkI1Sf7ZyE9Nbdn4WWLUMP8I9o8a38B9cTHiWgUddGeHEZc3SgDR5D94AoxWcyYqdGboYPMfyJi_0xtaY3ooNBdA/vuOOOGWc9BGMRbeS2acglyzj7Tl7yBcnRDNUFyZrDXk)',
    attributes: {
      cch: {
        cch: '21',
        cch_km_voiture: "20.5 kg CO2e, c'est environ 140 km en voiture",
        cch_limite_planete:
          "Et c'est 3.5 j d'\u00e9missions \u00e9quivalentes dans un monde bas carbone (objectif 2050)",
        cch_mediane_cat: '6',
        cch_mediane_affichage: '50 % des produits \u00e9mettent plus de \t5.8 kg CO2e',
        cch_ecart_mediane: '250%',
        cch_ecart_affichage: "+250% d'empreinte carbone",
        cch_ecart_affichage_detail:
          'Ce produit \u00e9met +250% par rapport \u00e0 la m\u00e9diane de sa cat\u00e9gorie',
        cch_min_cat: '3',
        cch_min_affichage: 'Le meilleur produit de cette cat\u00e9gorie \u00e9met 3.2 kg CO2e',
        cch_affichage: '20.5 kg CO2e',
        cch_affichage_detail: 'Ce produit \u00e9met             20.5 kg CO2e',
        cch_picto:
          '3222073.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/Cg7FbxZjzXNenRznTxruiQ/ej8LA0MFzFDAuonjmhtIGjM_jmUVdYVkgoE2z15gEdwzQp1HxHgjXg7DJB5zuk5qhuuXMvwSXVCYxxYHF3f8xXKqQWM-bAbPCfU0M6ThLn4i_xFHBFw11yad-AGnv9O9h4DnYf2ACDrqVrgmwefaNQ/k9z9DBziUBoeYW10m8F9yEueIYlgsz2zF6_03in2PGI)',
        cch_niveau: '1 plan\u00e8te',
        'cch_description medaille': "Ce produit n'a pas de qualit\u00e9 particuli\u00e8re pour ce crit\u00e8re",
        cch_img_médaille:
          'planettes2-12.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/_M9H6rc9ajqUARqZBAN3lw/4P0vKT1eL3vUx4d_OLbQg-Nrllaayv8CVUNmAMKXFLcpsbJVLFky-VweC0qfdB_eSgkaBlFr9r4jgbcFIIiyij4MwwoBjndrLXoyGPWSIffk1AvhMJC3zuno6Hz6SENxyf0b4qknIpLoeEXRC4kAPw/n7r58wfXEj9OhV42EjTixvjhGTAx6lm5EGo505LnhKk)',
      },
      cch_km_voiture: "20.5 kg CO2e, c'est environ 140 km en voiture",
      cch_limite_planete: "Et c'est 3.5 j d'\u00e9missions \u00e9quivalentes dans un monde bas carbone (objectif 2050)",
      cch_mediane_cat: '6',
      cch_mediane_affichage: '50 % des produits \u00e9mettent plus de \t5.8 kg CO2e',
      cch_ecart_mediane: '250%',
      cch_ecart_affichage: "+250% d'empreinte carbone",
      cch_ecart_affichage_detail: 'Ce produit \u00e9met +250% par rapport \u00e0 la m\u00e9diane de sa cat\u00e9gorie',
      cch_min_cat: '3',
      cch_min_affichage: 'Le meilleur produit de cette cat\u00e9gorie \u00e9met 3.2 kg CO2e',
      cch_affichage: '20.5 kg CO2e',
      cch_affichage_detail: 'Ce produit \u00e9met             20.5 kg CO2e',
      cch_picto:
        '3222073.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/Cg7FbxZjzXNenRznTxruiQ/ej8LA0MFzFDAuonjmhtIGjM_jmUVdYVkgoE2z15gEdwzQp1HxHgjXg7DJB5zuk5qhuuXMvwSXVCYxxYHF3f8xXKqQWM-bAbPCfU0M6ThLn4i_xFHBFw11yad-AGnv9O9h4DnYf2ACDrqVrgmwefaNQ/k9z9DBziUBoeYW10m8F9yEueIYlgsz2zF6_03in2PGI)',
      cch_niveau: '1 plan\u00e8te',
      'cch_description medaille': "Ce produit n'a pas de qualit\u00e9 particuli\u00e8re pour ce crit\u00e8re",
      cch_img_médaille:
        'planettes2-12.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/_M9H6rc9ajqUARqZBAN3lw/4P0vKT1eL3vUx4d_OLbQg-Nrllaayv8CVUNmAMKXFLcpsbJVLFky-VweC0qfdB_eSgkaBlFr9r4jgbcFIIiyij4MwwoBjndrLXoyGPWSIffk1AvhMJC3zuno6Hz6SENxyf0b4qknIpLoeEXRC4kAPw/n7r58wfXEj9OhV42EjTixvjhGTAx6lm5EGo505LnhKk)',
      'Pays - Etape 1': 'CN',
      'Pays - Etape 2': 'CN',
      'Pays - Etape 3': 'CN',
      'Pays - Etape 4': 'CN',
      'Pays - Etape 5': 'CN',
      'Pays - Etape 6': 'FR',
      'Pays - Etape 7': 'FR',
      'Pays - Etape 8': 'FR',
      country_step: {
        'Pays - Etape 1': 'CN',
        'Pays - Etape 2': 'CN',
        'Pays - Etape 3': 'CN',
        'Pays - Etape 4': 'CN',
        'Pays - Etape 5': 'CN',
        'Pays - Etape 6': 'FR',
        'Pays - Etape 7': 'FR',
        'Pays - Etape 8': 'FR',
      },
      PEF: '1529',
      PEF_affichage_detail: 'Ce produit vaut 1530 \u00b5Pt',
      PEF_limite_planete:
        "1530 \u00b5Pt, c'est l'\u00e9quivalent de 13 heures de l'empreinte annuelle d'un europ\u00e9en",
      PEF_mediane_cat: '758',
      PEF_mediane_affichage: '50 % des produits valent plus de 760 \u00b5Pt',
      PEF_ecart_mediane: '50%',
      PEF_ecart_affichage: "+50% d'empreinte environnementale",
      PEF_ecart_affichage_detail: 'Ce produit \u00e9met +50% par rapport \u00e0 la m\u00e9diane de sa cat\u00e9gorie',
      PEF_min_cat: '493',
      PEF_min_affichage: 'Le meilleur produit de cette cat\u00e9gorie vaut 490 \u00b5Pt',
      pef: {
        PEF: '1529',
        PEF_affichage_detail: 'Ce produit vaut 1530 \u00b5Pt',
        PEF_limite_planete:
          "1530 \u00b5Pt, c'est l'\u00e9quivalent de 13 heures de l'empreinte annuelle d'un europ\u00e9en",
        PEF_mediane_cat: '758',
        PEF_mediane_affichage: '50 % des produits valent plus de 760 \u00b5Pt',
        PEF_ecart_mediane: '50%',
        PEF_ecart_affichage: "+50% d'empreinte environnementale",
        PEF_ecart_affichage_detail: 'Ce produit \u00e9met +50% par rapport \u00e0 la m\u00e9diane de sa cat\u00e9gorie',
        PEF_min_cat: '493',
        PEF_min_affichage: 'Le meilleur produit de cette cat\u00e9gorie vaut 490 \u00b5Pt',
      },
    },
  },
  {
    'ID produit': '12',
    'Num produit': '12',
    Nom: 'Tee-shirt 12',
    Marque: 'Marque X',
    'Description courte': 'Coton recycl\u00e9 - fabriqu\u00e9 en France - longue dur\u00e9e',
    'Description longue': '',
    Label: 'Global Recycled Standard,Oeko Tex Label,Oeko Tex Label Made in Green',
    'Logo (from Label)':
      'image_2023-03-15_114050784.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/-tq4_nXb8vOxprYkKAhFMg/d7ZKNpv5RyJzLWV76kE37h4mRWxWa4HpGTs0eOXZRf3Tb6A-9RbZRmcPvzFWkbJvWX8925P-y-nGAQMBd_zWA4_cHaTKysKTD_XPOH1mqqbJS0Q0jc0MVMcahM0pISYfYzDZ2Elm6SzePZEpC33YW8UOax_FecvFlSWZBBXzZWs/rsy_IgItu7JqrEA22fY_bkWvhh5_k6H2EDRlGHCZO_8),image_2023-03-15_114155185.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/FZq6jK3h2zYhnlIM7j_OAw/RqVMQYC76s2cZWcwYloryRPVx_wRjBf23wUlr5h5VjSsV4mFvvwYXexlFxIipRCe4qJHHe2-W6Kgk8z3I5DhO2JKzO0w1YJEtmm7qAncpvjEDrUkINzQuBI1aJjoaQKL87BYP-JjXxEXPDIcCbb3BvJnuwUz3e6-WJ2RtwaoMqc/Q4wZXRclBhzneF1ZDZZjXky5Y9qXvOwJlBtWvsGpCZE),image_2023-03-15_114455758.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/rjm9Fedk3enc4EOBS6KhLQ/JO3wXlCmVLd_cBLC5HI_cFoZ2bbu6bVG6SafcMUbWkLE9pAA58KbO479WmR_Gp1UDOS20YIEP91oOvIiTMmg0TLemkPppu7e5Oe_YFM-lAOEmLAOAOEcl7LUSJnPNrvq-yIDNyz2TWQz35u8ejR2lAMV_MoxdCYWAuQUlX4gUXU/43CnIaZz754zA5haitRU189UR5i-qT_wqAqfp7M4Zl0)',
    URL: '',
    Prix: '\u20ac40.00',
    Image:
      'image_2023-02-23_141844227-removebg-preview.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/nU7BTKWp3x4W8Ux_FOdW7w/HFNx6Uprr3NEh74z0T4nP_mlKZNlzEzzC8JG0jcG71aTvfVjpZC1Fh8J26xeLX-9xYINZVn-lXcF9F97J6lJJFx1b004K7Hv0Er7bCE_jF4aDxqJTPkcJ1I1HQNBKhd0S6QZ-wkNcP9ACMiQnn6ofmX0IfnJ2deL2sETy_24LPRMZLT0LAlgVcDiATxH6qkA/xl4cFNUhhOS5VD8yExKA_nGS_BPBBKL_pwxzYmDLNjI)',
    Catégorie_softr: 'Habillement',
    Catégorie: 'Textile',
    Type: 'Tee-shirt',
    wtu: '111',
    wtu_picto:
      'image_2023-03-09_113253563.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/Tp5mq0CH0hDIz-R4Byzwzw/_EhCHERMBBISU6erycAlwHKN1BMUHAr_bleE7A9hotX3yhf_t7PhYJmnflU_-ibB3vwSdMxf65cx_xOX28I7kIaiunqt951ZSy8e1joVabEIWzlMjJH2eCibYCO6Qg1Bc_y6wP6WxriFdSyYeQb-zupwjk2j25YvO9lM5dPi7ac/PINf1tWNOYKVczsQ8zt-F5giwzb8HVv3Th85xyyBd8g)',
    pef_picto:
      'image_2023-03-09_113337288.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/BQbvMTJzkU8GzAKN4NL2JQ/0DNEpSjem-PIiiJXwhCV2VXhpbmePHc0ZJ-bzTjSA_F-xds8b6RtzUHTJBIDvYt-tOjc8pd6gnWTsAV489-51uE20FBm5QA85vWG11GsBMjAvirifVZ2e3n7u6ffTEJeIABphjilMCOUnSz2Beiu_LH8AMpbsd2W_3Ll1QDPLUc/mks6bu1Y4A0sc5mTpOOlEZU1nMK2yyXCbsTu92zWJ3E)',
    aff_env_lettre: 'B',
    affichage_environnemental:
      'image_2023-03-09_161422595.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/qK4PM7aS_z3zLdlzojw9GA/mrP5tI9STFHSJhya5Kn_0AGbZDtM_c9Xu5-hjzYw8n5eelsLHjoKcw2tlHjRkNBYqUHaw8tMgF_QoxuPC7EbnOsbm6autbenNjnF-q5_InbhrkpfieJKQwP6-a-o2x5gF-pz8E2b92gUJ3zdQRhSAv5NYc7Dqq8S_IghjMs-B7s/8fCjM8eubDwINpCdtGLGVQ1inQDlV_bHGEps5O8UPz8)',
    pef_niveau: '3 plan\u00e8tes',
    'Name (from pef_niveau)': '3 plan\u00e8tes',
    pef_img_médaille:
      'planettes2-10.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/mVM9KoEIOlq297o8ahR1Bw/A5rkxooKkBnJ2622FnDndxZ1VV8dnf_7c7tJ58LII4_FlsLPA7aQ5mUc57xXn-RqWHZohU3m3irG2-nM5DQElq0I4TMfuBst-MUtPLORcC1IuqCFxjAqcUEz7g4KZvGOR4LpXOH-gWdJkyN3ez-N0Q/XRwU39-tqUork8C-E8fdV2HncmV-Rr6HTKsFAdeJ-Wo)',
    'pef_description medaille': 'Ce produit fait partie des 25% des meilleurs de sa cat\u00e9gorie',
    'Name (from cch_niveau)': '4 plan\u00e8tes',
    tracabilite:
      'tracabilite_teeshirt12.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/gyNXEKz-MckjZUz1o0rJgQ/IpqO6CkLGAJ9j2KwN3Yff_sr9-luo1ya_IJp1zc7sw89IkujYgLjrnK07FjN5M2CzeD50c-fmfVSOCir-jwcJKvqINGy9Er9LVbdaZ4BNPuOOekO0e1CxGub_s9RnMANHx8yKO6AIoVWOyWxXF0KYioAeZKK1qeHnFV4FRBysHU/piK9DlVdwWZSt2uzcJXYCtrMQx1_b6PdA5dUwwbyUdY)',
    vide: '',
    'URL popup':
      'https://images.bannerbear.com/direct/KDWmnAMxpAvM80dVk4/requests/000/035/483/103/NWlVkgmbMQEgZKEezZyAqEwDo/44fd79ccb6d914c0e1673b36f8ec9ae06006a4ee.png',
    'IMG popup':
      'popup-teeshirt12.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/RDJAVWSvtg5SPB69EKbEdQ/a0x2JuPozDYNeT3S_81lWgW7nAd3K05KeHgB7221gH2zmKodgYPOBtHy0ZE2AIdUUFb9McerNhRSLvH53aIJ9_gn3yaRkx-gEN_XkE1S3lwYD5huhnMh2xMqOMuTsUClp8EYCEXJXTtx_fTfmOhYQg/PihExfErdIWSzQBJVB6NPFq-jgcvldvBUgrhZoT_4ek)',
    attributes: {
      cch: {
        cch: '3',
        cch_km_voiture: "3.3 kg CO2e, c'est environ 20 km en voiture",
        cch_limite_planete:
          "Et c'est 0.5 j d'\u00e9missions \u00e9quivalentes dans un monde bas carbone (objectif 2050)",
        cch_mediane_cat: '6',
        cch_mediane_affichage: '50 % des produits \u00e9mettent plus de \t5.8 kg CO2e',
        cch_ecart_mediane: '-44%',
        cch_ecart_affichage: "-44% d'empreinte carbone",
        cch_ecart_affichage_detail: 'Ce produit \u00e9met -44% par rapport \u00e0 la m\u00e9diane de sa cat\u00e9gorie',
        cch_min_cat: '3',
        cch_min_affichage: 'Le meilleur produit de cette cat\u00e9gorie \u00e9met 3.2 kg CO2e',
        cch_affichage: '3.3 kg CO2e',
        cch_affichage_detail: 'Ce produit \u00e9met             3.3 kg CO2e',
        cch_picto:
          '3222073.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/Cg7FbxZjzXNenRznTxruiQ/ej8LA0MFzFDAuonjmhtIGjM_jmUVdYVkgoE2z15gEdwzQp1HxHgjXg7DJB5zuk5qhuuXMvwSXVCYxxYHF3f8xXKqQWM-bAbPCfU0M6ThLn4i_xFHBFw11yad-AGnv9O9h4DnYf2ACDrqVrgmwefaNQ/k9z9DBziUBoeYW10m8F9yEueIYlgsz2zF6_03in2PGI)',
        cch_niveau: '4 plan\u00e8tes',
        'cch_description medaille': 'Ce produit fait partie des 10% des meilleurs de sa cat\u00e9gorie',
        cch_img_médaille:
          'planettes2-09.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/BFuwmfjvjm-bkkvj7HLiIg/mjne6tjaU2Q5d_Ssx5lv024bdQAF80gRzVogWoxM_sJe76FYzrGI-ijoSWCT1FSpqIIibdoGqmUCVNtZvwKamf3P7TisYQHMoPaGCUAXBHvtppiFZc11MxBSiS6lkuvAUEeupEJGqP8TzRQqdrWZ8A/2dNMnsjvkLK7sx6MdPhbdt0kE6Jc-Gs4yB-9nMPRBbY)',
      },
      cch_km_voiture: "3.3 kg CO2e, c'est environ 20 km en voiture",
      cch_limite_planete: "Et c'est 0.5 j d'\u00e9missions \u00e9quivalentes dans un monde bas carbone (objectif 2050)",
      cch_mediane_cat: '6',
      cch_mediane_affichage: '50 % des produits \u00e9mettent plus de \t5.8 kg CO2e',
      cch_ecart_mediane: '-44%',
      cch_ecart_affichage: "-44% d'empreinte carbone",
      cch_ecart_affichage_detail: 'Ce produit \u00e9met -44% par rapport \u00e0 la m\u00e9diane de sa cat\u00e9gorie',
      cch_min_cat: '3',
      cch_min_affichage: 'Le meilleur produit de cette cat\u00e9gorie \u00e9met 3.2 kg CO2e',
      cch_affichage: '3.3 kg CO2e',
      cch_affichage_detail: 'Ce produit \u00e9met             3.3 kg CO2e',
      cch_picto:
        '3222073.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/Cg7FbxZjzXNenRznTxruiQ/ej8LA0MFzFDAuonjmhtIGjM_jmUVdYVkgoE2z15gEdwzQp1HxHgjXg7DJB5zuk5qhuuXMvwSXVCYxxYHF3f8xXKqQWM-bAbPCfU0M6ThLn4i_xFHBFw11yad-AGnv9O9h4DnYf2ACDrqVrgmwefaNQ/k9z9DBziUBoeYW10m8F9yEueIYlgsz2zF6_03in2PGI)',
      cch_niveau: '4 plan\u00e8tes',
      'cch_description medaille': 'Ce produit fait partie des 10% des meilleurs de sa cat\u00e9gorie',
      cch_img_médaille:
        'planettes2-09.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/BFuwmfjvjm-bkkvj7HLiIg/mjne6tjaU2Q5d_Ssx5lv024bdQAF80gRzVogWoxM_sJe76FYzrGI-ijoSWCT1FSpqIIibdoGqmUCVNtZvwKamf3P7TisYQHMoPaGCUAXBHvtppiFZc11MxBSiS6lkuvAUEeupEJGqP8TzRQqdrWZ8A/2dNMnsjvkLK7sx6MdPhbdt0kE6Jc-Gs4yB-9nMPRBbY)',
      'Pays - Etape 1': 'FR',
      'Pays - Etape 2': 'FR',
      'Pays - Etape 3': 'FR',
      'Pays - Etape 4': 'FR',
      'Pays - Etape 5': 'FR',
      'Pays - Etape 6': 'FR',
      'Pays - Etape 7': 'FR',
      'Pays - Etape 8': 'FR',
      country_step: {
        'Pays - Etape 1': 'FR',
        'Pays - Etape 2': 'FR',
        'Pays - Etape 3': 'FR',
        'Pays - Etape 4': 'FR',
        'Pays - Etape 5': 'FR',
        'Pays - Etape 6': 'FR',
        'Pays - Etape 7': 'FR',
        'Pays - Etape 8': 'FR',
      },
      PEF: '566',
      PEF_affichage_detail: 'Ce produit vaut 570 \u00b5Pt',
      PEF_limite_planete:
        "570 \u00b5Pt, c'est l'\u00e9quivalent de 5 heures de l'empreinte annuelle d'un europ\u00e9en",
      PEF_mediane_cat: '758',
      PEF_mediane_affichage: '50 % des produits valent plus de 760 \u00b5Pt',
      PEF_ecart_mediane: '-34%',
      PEF_ecart_affichage: "-34% d'empreinte environnementale",
      PEF_ecart_affichage_detail: 'Ce produit \u00e9met -34% par rapport \u00e0 la m\u00e9diane de sa cat\u00e9gorie',
      PEF_min_cat: '493',
      PEF_min_affichage: 'Le meilleur produit de cette cat\u00e9gorie vaut 490 \u00b5Pt',
      pef: {
        PEF: '566',
        PEF_affichage_detail: 'Ce produit vaut 570 \u00b5Pt',
        PEF_limite_planete:
          "570 \u00b5Pt, c'est l'\u00e9quivalent de 5 heures de l'empreinte annuelle d'un europ\u00e9en",
        PEF_mediane_cat: '758',
        PEF_mediane_affichage: '50 % des produits valent plus de 760 \u00b5Pt',
        PEF_ecart_mediane: '-34%',
        PEF_ecart_affichage: "-34% d'empreinte environnementale",
        PEF_ecart_affichage_detail: 'Ce produit \u00e9met -34% par rapport \u00e0 la m\u00e9diane de sa cat\u00e9gorie',
        PEF_min_cat: '493',
        PEF_min_affichage: 'Le meilleur produit de cette cat\u00e9gorie vaut 490 \u00b5Pt',
      },
    },
  },
  {
    'ID produit': '13',
    'Num produit': '13',
    Nom: 'Tee-shirt 13',
    Marque: 'Marque X',
    'Description courte': 'Lin - fabriqu\u00e9 en France',
    'Description longue': '',
    Label: 'Oeko Tex Label Made in Green,Global Organic Textile Standard',
    'Logo (from Label)':
      'image_2023-03-15_114455758.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/rjm9Fedk3enc4EOBS6KhLQ/JO3wXlCmVLd_cBLC5HI_cFoZ2bbu6bVG6SafcMUbWkLE9pAA58KbO479WmR_Gp1UDOS20YIEP91oOvIiTMmg0TLemkPppu7e5Oe_YFM-lAOEmLAOAOEcl7LUSJnPNrvq-yIDNyz2TWQz35u8ejR2lAMV_MoxdCYWAuQUlX4gUXU/43CnIaZz754zA5haitRU189UR5i-qT_wqAqfp7M4Zl0),image_2023-03-15_114621318.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/plydAiTv9TgoIKkyHxFC5Q/PSqT4Ejb5g-U4tgNxQIvgvuTCTFYDeHUW-1r0clAJqebyBxK0QLAVe6F47xSYwTLSmlo--a7lhhMypSmAF6pY3rclq9AB6HBTWyUTwO1vsI7USv0QPtIoeiEYArc9WHWAL8OzRAe-as7baYqaCXj8RTSzikLRafNny3EvdNfLXo/9cuw9XjWlsV7FOxPTerJx2FJ_X9_bwDH6ffpUpB_Px0)',
    URL: '',
    Prix: '\u20ac60.00',
    Image:
      'image_2023-02-23_142051067-removebg-preview.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/4lZYBbo8P9i0nFqgDFpfHw/QlD0O1RICqgLw7anH1ZpY6iTeyVYVS1FLkzlrUm8RcTnk6C7jyHnK_pR6z4QmMRIlwJF66MbdwkpvxvBDhTqyMjcsYTDLCpCb-fnSb_ehe8I98RKPCdx9wan4Wc_5fOKmhnak6Y76os9sWAnbOG6GMk1MSph3xFDUD3Jwztb0R1Uvtj162GnOQn0TZBb6fA6/uGTKxXVObfG7OUvAaAFV5b5cwcbFF-PuFOJgJ045kH8)',
    Catégorie_softr: 'Habillement',
    Catégorie: 'Textile',
    Type: 'Tee-shirt',
    wtu: '1',
    wtu_picto:
      'image_2023-03-09_113253563.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/Tp5mq0CH0hDIz-R4Byzwzw/_EhCHERMBBISU6erycAlwHKN1BMUHAr_bleE7A9hotX3yhf_t7PhYJmnflU_-ibB3vwSdMxf65cx_xOX28I7kIaiunqt951ZSy8e1joVabEIWzlMjJH2eCibYCO6Qg1Bc_y6wP6WxriFdSyYeQb-zupwjk2j25YvO9lM5dPi7ac/PINf1tWNOYKVczsQ8zt-F5giwzb8HVv3Th85xyyBd8g)',
    pef_picto:
      'image_2023-03-09_113337288.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/BQbvMTJzkU8GzAKN4NL2JQ/0DNEpSjem-PIiiJXwhCV2VXhpbmePHc0ZJ-bzTjSA_F-xds8b6RtzUHTJBIDvYt-tOjc8pd6gnWTsAV489-51uE20FBm5QA85vWG11GsBMjAvirifVZ2e3n7u6ffTEJeIABphjilMCOUnSz2Beiu_LH8AMpbsd2W_3Ll1QDPLUc/mks6bu1Y4A0sc5mTpOOlEZU1nMK2yyXCbsTu92zWJ3E)',
    aff_env_lettre: 'C',
    affichage_environnemental:
      'image_2023-03-09_161355207.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/rmktxEJH54M5jdgL4Y7Nwg/28NG1oNRuKCDNyEPYqqqh7CUp0WNTh6cRb17J9OmYd3hv_jKTekjEZrCCodnl7lzbg_8nJnNowUAHWI9zA6aFa6PH5mwjyNOfw7Ci1lCk6mpk9pO3amnHZG7md1nTvW5Lp1LDx9Y25afwiqwYidGWK9Fy68LPK4Pymz_fb2OAOY/CC_L3ct4IZacpmVuFUDgtdkoxP-7iximuJ-1oTXaUj8)',
    pef_niveau: '2 plan\u00e8tes',
    'Name (from pef_niveau)': '2 plan\u00e8tes',
    pef_img_médaille:
      'planettes2-11.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/R66-AtqV295Qa2JtdkyRvw/IWepgFSNhQWt4zlKKakQKD6FUwqKoqOkgaqvKqlnMBtiqkWBSnY9pk4-9VcChvs3S02nLgQBcucFLnJisjRb6QlIGWWwNK624Htsf7y5AS3J3XsAB2VUtphGiLMu3FqaIKsWdxwCSsG90Mq74K2DPA/W0WIuQxot0HuYxOprYdyWnhmxjbV_k3UhLMqoG3xFSQ)',
    'pef_description medaille': 'Ce produit fait partie des 50% des meilleurs de sa cat\u00e9gorie',
    'Name (from cch_niveau)': '2 plan\u00e8tes',
    tracabilite:
      'tracabilite_teeshirt13.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/SWXUvuk1Ta3YZHwt3p-9Vg/qCU02UfJ7Vzd4Q_3YtZZM6g9nW9_WU-LOJMDEZFMBYH5yyieOqBbkcC10sjTk29hcPzKDFtU15NL2peJkKbSdG-B6Ehv3bz9i3IYGHY034nlEKLQZHQGV2NAxkz65d6u2mv2Aoc7B0beHHZL9PnYOO9-FtX5ej0olgQzRcYBLWk/-4MB0Xt80tWeamo7UY3xzwOPvCJJCNAJrX1dMgqQEdY)',
    vide: '',
    'URL popup':
      'https://images.bannerbear.com/direct/KDWmnAMxpAvM80dVk4/requests/000/035/483/105/aMBJ5DWdLYP7Z0V7QXRNjrp4Z/251531c3031187634bb4517707afa24e61d69296.png',
    'IMG popup':
      'popup-teeshirt13.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/gxNziSsAE9x4aofCkpbWsg/-HSXpABQhU7LuQHaz7YMUXtpfBRrCgXUgQTuNzouApUNvvN2ELdwiL32_uuU0aiMYys751usAkN-VGqdC1WJV8si0P7-uDq9g0EhGzUxX-fXBE-DRBCRh46G5jkW2Wh0LiEb-Df3kmN74deOMDYuGw/fX2hi3o_qo8wboBLDOhdBO_aLhf0VVmU6MhAyYxXchM)',
    attributes: {
      cch: {
        cch: '6',
        cch_km_voiture: "5.7 kg CO2e, c'est environ 40 km en voiture",
        cch_limite_planete: "Et c'est 1 j d'\u00e9missions \u00e9quivalentes dans un monde bas carbone (objectif 2050)",
        cch_mediane_cat: '6',
        cch_mediane_affichage: '50 % des produits \u00e9mettent plus de \t5.8 kg CO2e',
        cch_ecart_mediane: '-3%',
        cch_ecart_affichage: "-3% d'empreinte carbone",
        cch_ecart_affichage_detail: 'Ce produit \u00e9met -3% par rapport \u00e0 la m\u00e9diane de sa cat\u00e9gorie',
        cch_min_cat: '3',
        cch_min_affichage: 'Le meilleur produit de cette cat\u00e9gorie \u00e9met 3.2 kg CO2e',
        cch_affichage: '5.7 kg CO2e',
        cch_affichage_detail: 'Ce produit \u00e9met             5.7 kg CO2e',
        cch_picto:
          '3222073.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/Cg7FbxZjzXNenRznTxruiQ/ej8LA0MFzFDAuonjmhtIGjM_jmUVdYVkgoE2z15gEdwzQp1HxHgjXg7DJB5zuk5qhuuXMvwSXVCYxxYHF3f8xXKqQWM-bAbPCfU0M6ThLn4i_xFHBFw11yad-AGnv9O9h4DnYf2ACDrqVrgmwefaNQ/k9z9DBziUBoeYW10m8F9yEueIYlgsz2zF6_03in2PGI)',
        cch_niveau: '2 plan\u00e8tes',
        'cch_description medaille': 'Ce produit fait partie des 50% des meilleurs de sa cat\u00e9gorie',
        cch_img_médaille:
          'planettes2-11.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/R66-AtqV295Qa2JtdkyRvw/IWepgFSNhQWt4zlKKakQKD6FUwqKoqOkgaqvKqlnMBtiqkWBSnY9pk4-9VcChvs3S02nLgQBcucFLnJisjRb6QlIGWWwNK624Htsf7y5AS3J3XsAB2VUtphGiLMu3FqaIKsWdxwCSsG90Mq74K2DPA/W0WIuQxot0HuYxOprYdyWnhmxjbV_k3UhLMqoG3xFSQ)',
      },
      cch_km_voiture: "5.7 kg CO2e, c'est environ 40 km en voiture",
      cch_limite_planete: "Et c'est 1 j d'\u00e9missions \u00e9quivalentes dans un monde bas carbone (objectif 2050)",
      cch_mediane_cat: '6',
      cch_mediane_affichage: '50 % des produits \u00e9mettent plus de \t5.8 kg CO2e',
      cch_ecart_mediane: '-3%',
      cch_ecart_affichage: "-3% d'empreinte carbone",
      cch_ecart_affichage_detail: 'Ce produit \u00e9met -3% par rapport \u00e0 la m\u00e9diane de sa cat\u00e9gorie',
      cch_min_cat: '3',
      cch_min_affichage: 'Le meilleur produit de cette cat\u00e9gorie \u00e9met 3.2 kg CO2e',
      cch_affichage: '5.7 kg CO2e',
      cch_affichage_detail: 'Ce produit \u00e9met             5.7 kg CO2e',
      cch_picto:
        '3222073.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/Cg7FbxZjzXNenRznTxruiQ/ej8LA0MFzFDAuonjmhtIGjM_jmUVdYVkgoE2z15gEdwzQp1HxHgjXg7DJB5zuk5qhuuXMvwSXVCYxxYHF3f8xXKqQWM-bAbPCfU0M6ThLn4i_xFHBFw11yad-AGnv9O9h4DnYf2ACDrqVrgmwefaNQ/k9z9DBziUBoeYW10m8F9yEueIYlgsz2zF6_03in2PGI)',
      cch_niveau: '2 plan\u00e8tes',
      'cch_description medaille': 'Ce produit fait partie des 50% des meilleurs de sa cat\u00e9gorie',
      cch_img_médaille:
        'planettes2-11.png (https://v5.airtableusercontent.com/v3/u/26/26/1710288000000/R66-AtqV295Qa2JtdkyRvw/IWepgFSNhQWt4zlKKakQKD6FUwqKoqOkgaqvKqlnMBtiqkWBSnY9pk4-9VcChvs3S02nLgQBcucFLnJisjRb6QlIGWWwNK624Htsf7y5AS3J3XsAB2VUtphGiLMu3FqaIKsWdxwCSsG90Mq74K2DPA/W0WIuQxot0HuYxOprYdyWnhmxjbV_k3UhLMqoG3xFSQ)',
      'Pays - Etape 1': 'CN',
      'Pays - Etape 2': 'FR',
      'Pays - Etape 3': 'FR',
      'Pays - Etape 4': 'FR',
      'Pays - Etape 5': 'FR',
      'Pays - Etape 6': 'FR',
      'Pays - Etape 7': 'FR',
      'Pays - Etape 8': 'FR',
      country_step: {
        'Pays - Etape 1': 'CN',
        'Pays - Etape 2': 'FR',
        'Pays - Etape 3': 'FR',
        'Pays - Etape 4': 'FR',
        'Pays - Etape 5': 'FR',
        'Pays - Etape 6': 'FR',
        'Pays - Etape 7': 'FR',
        'Pays - Etape 8': 'FR',
      },
      PEF: '747',
      PEF_affichage_detail: 'Ce produit vaut 750 \u00b5Pt',
      PEF_limite_planete:
        "750 \u00b5Pt, c'est l'\u00e9quivalent de 7 heures de l'empreinte annuelle d'un europ\u00e9en",
      PEF_mediane_cat: '758',
      PEF_mediane_affichage: '50 % des produits valent plus de 760 \u00b5Pt',
      PEF_ecart_mediane: '-1%',
      PEF_ecart_affichage: "-1% d'empreinte environnementale",
      PEF_ecart_affichage_detail: 'Ce produit \u00e9met -1% par rapport \u00e0 la m\u00e9diane de sa cat\u00e9gorie',
      PEF_min_cat: '493',
      PEF_min_affichage: 'Le meilleur produit de cette cat\u00e9gorie vaut 490 \u00b5Pt',
      pef: {
        PEF: '747',
        PEF_affichage_detail: 'Ce produit vaut 750 \u00b5Pt',
        PEF_limite_planete:
          "750 \u00b5Pt, c'est l'\u00e9quivalent de 7 heures de l'empreinte annuelle d'un europ\u00e9en",
        PEF_mediane_cat: '758',
        PEF_mediane_affichage: '50 % des produits valent plus de 760 \u00b5Pt',
        PEF_ecart_mediane: '-1%',
        PEF_ecart_affichage: "-1% d'empreinte environnementale",
        PEF_ecart_affichage_detail: 'Ce produit \u00e9met -1% par rapport \u00e0 la m\u00e9diane de sa cat\u00e9gorie',
        PEF_min_cat: '493',
        PEF_min_affichage: 'Le meilleur produit de cette cat\u00e9gorie vaut 490 \u00b5Pt',
      },
    },
  },
];

export default { CatalogProductsColumns, CatalogProductsMock };

const routes = {
  login: '/api/dolibarr/login',
  info: '/api/dolibarr/info',
  logout: '/api/dolibarr/logout',
  sync: '/api/dolibarr/sync',
  update: '/api/dolibarr/update',
  register: '/api/dolibarr/register',
};

export default routes;

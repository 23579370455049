import { fork, takeEvery, all } from 'redux-saga/effects';
import {
  LOGIN_REQUEST,
  LOGIN_WITH_EMAIL_REQUEST,
  LOGOUT_REQUEST,
  REGISTER_WITH_EMAIL_REQUEST,
  // REGISTER_WITH_EMAIL_SUCCESS,
  PASSWORD_FORGET_REQUEST,
} from '../constants/authConstants';
import {
  loginSagaDolibarr,
  loginWithEmailSagaDolibarr,
  registerWithEmailSagaDolibarr,
  // createUserSagaDolibarr,
  logoutSagaDolibarr,
  syncUserSagaDolibarr,
  passwordForgetSagaDolibarr,
} from './authSagasDolibarr';
import { LOAD_PAGE } from '../constants/uiConstants';

//= ====================================
//  WATCHERS
//-------------------------------------

function* loginRootSaga() {
  yield fork(syncUserSagaDolibarr);
  yield all([
    takeEvery(LOAD_PAGE, syncUserSagaDolibarr),
    takeEvery(LOGIN_REQUEST, loginSagaDolibarr),
    takeEvery(LOGIN_WITH_EMAIL_REQUEST, loginWithEmailSagaDolibarr),
    takeEvery(REGISTER_WITH_EMAIL_REQUEST, registerWithEmailSagaDolibarr),
    // takeEvery(REGISTER_WITH_EMAIL_SUCCESS, createUserSagaDolibarr),
    takeEvery(LOGOUT_REQUEST, logoutSagaDolibarr),
    takeEvery(PASSWORD_FORGET_REQUEST, passwordForgetSagaDolibarr),
  ]);
}

const authSagas = [fork(loginRootSaga)];

export default authSagas;

import { defineMessages } from 'react-intl';

export const scope = 'boilerplate.components.Catalog.CatalogProductExpandedView';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Properties',
  },
  durability: {
    id: `${scope}.durability`,
    defaultMessage: 'Non-physical Durability',
  },
  durability__category: {
    id: `${scope}.durability__category`,
    defaultMessage: 'Category',
  },
  durability__reference_number: {
    id: `${scope}.durability__reference_number`,
    defaultMessage: 'Reference Number',
  },
  raw_materials: {
    id: `${scope}.raw_materials`,
    defaultMessage: 'Raw Materials',
  },
  spinning: {
    id: `${scope}.spinning`,
    defaultMessage: 'Transformation - Spinning',
  },
  weaving: {
    id: `${scope}.weaving`,
    defaultMessage: 'Transformation - Weaving / Knitting',
  },
  ennoblement: {
    id: `${scope}.ennoblement`,
    defaultMessage: 'Transformation - Ennoblement',
  },
  making: {
    id: `${scope}.making`,
    defaultMessage: 'Transformation - Making',
  },
  distribution: {
    id: `${scope}.distribution`,
    defaultMessage: 'Distribution',
  },
  use: {
    id: `${scope}.use`,
    defaultMessage: 'Use',
  },
  end_of_life: {
    id: `${scope}.end_of_life`,
    defaultMessage: 'End of Life',
  },
  durabilityCategory__shirt: {
    id: `${scope}.durabilityCategory__shirt`,
    defaultMessage: 'Shirt',
  },
  durabilityCategory__jean: {
    id: `${scope}.durabilityCategory__jean`,
    defaultMessage: 'Jean',
  },
  durabilityCategory__skirt_dress: {
    id: `${scope}.durabilityCategory__skirt_dress`,
    defaultMessage: 'Skirt / Dress',
  },
  durabilityCategory__coat_jacket: {
    id: `${scope}.durabilityCategory__coat_jacket`,
    defaultMessage: 'Coat / Jacket',
  },
  durabilityCategory__pants_shorts: {
    id: `${scope}.durabilityCategory__pants_shorts`,
    defaultMessage: 'Pants / Shorts',
  },
  durabilityCategory__sweater: {
    id: `${scope}.durabilityCategory__sweater`,
    defaultMessage: 'Sweater',
  },
  durabilityCategory__tshirt_polo: {
    id: `${scope}.durabilityCategory__tshirt_polo`,
    defaultMessage: 'T-shirt / Polo',
  },
  durabilityCategory__socks: {
    id: `${scope}.durabilityCategory__socks`,
    defaultMessage: 'Socks',
  },
  durabilityCategory__woven_boxer: {
    id: `${scope}.durabilityCategory__woven_boxer`,
    defaultMessage: 'Woven Boxer',
  },
  durabilityCategory__knitted_boxer: {
    id: `${scope}.durabilityCategory__knitted_boxer`,
    defaultMessage: 'Knitted Boxer',
  },
  durabilityCategory__swimsuit: {
    id: `${scope}.durabilityCategory__swimsuit`,
    defaultMessage: 'Swimsuit',
  },
});

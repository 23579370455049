import axios from 'axios';
import routes from './routes';

export async function fetchDolibarrUser(token = null) {
  return (await axios.get(routes.info, { headers: token ? { DOLAPIKEY: token } : {} })).data;
}

export async function fetchDolibarrLogin(login, password) {
  return (await axios.post(routes.login, { login, password })).data;
}

export async function logoutDolibarr() {
  return (await axios.post(routes.logout)).data;
}

export async function loginWithEmailDolibarr(email, password) {
  const data = await fetchDolibarrLogin(email, password);
  return { ...(await fetchDolibarrUser(data.token)), token: data.token };
}

export async function syncDolibarrUser() {
  return (await axios.post(routes.sync)).data;
}

export async function registerWithEmailDolibarr(credentials) {
  return (await axios.post(routes.register, credentials)).data;
}

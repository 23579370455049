module.exports = [
  {
    key: 'home',
    name: 'Home',
    icon: 'home',
    child: [
      {
        key: 'dashboard',
        name: 'Dashboard',
        icon: 'home',
        link: '/app',
      },
    ],
  },
  {
    key: 'campaigns',
    name: 'Catalogs',
    icon: 'menu_book',
    child: [
      {
        key: 'campaign_create',
        name: 'New Catalog',
        icon: 'library_add',
        link: '/app/campaign-create',
      },
      {
        key: 'campaign_list',
        name: 'Campaigns',
        icon: 'library_books',
        link: '/app/campaign-list',
      },
      {
        key: 'campaign_products',
        name: 'Campaign Data',
        icon: 'library_books',
        link: '/app/campaign-product-list-extended',
      },
      {
        key: 'campaign_eco_score',
        name: 'Eco Score',
        icon: 'library_books',
        link: '/app/campaign-eco-score',
      },
      {
        key: 'campaign_statistics',
        name: 'Catalog Statistics',
        icon: 'query_stats',
        link: '/app/campaign-statistics',
      }
    ],
  },
  {
    key: 'products',
    name: 'Products',
    icon: 'widgets',
    child: [
      {
        key: 'product_create',
        name: 'New Product',
        icon: 'post_add',
        link: '/app/product-create',
      },
      {
        key: 'product_list',
        name: 'Product List',
        icon: 'list',
        link: '/app/product-list',
      },
      {
        key: 'product_statistics',
        name: 'Product Statistics',
        icon: 'query_stats',
        link: '/app/product-statistics',
      },
    ],
  },
  {
    key: 'configurations',
    name: 'Configurations',
    icon: 'settings',
    child: [
      {
        key: 'labels',
        name: 'Labels',
        icon: 'label',
        link: '/app/labels',
      },
      {
        key: 'api',
        name: 'API',
        icon: 'api',
        link: '/app/api',
      },
      {
        key: 'connectors',
        name: 'Connectors',
        icon: 'connect_without_contact',
        link: '/app/connectors',
      },
      {
        key: 'integrations',
        name: 'Integrations',
        icon: 'integration_instructions',
        link: '/app/integrations',
      },
    ],
  },
  {
    key: 'statistics',
    name: 'Statistics & Ratings',
    icon: 'query_stats',
    child: [
      {
        key: 'market_statistics',
        name: 'Market Statistics',
        icon: 'query_stats',
        link: '/app/market-statistics',
      },
      {
        key: 'sector_comparison',
        name: 'Sector Comparison',
        icon: 'compare_arrows',
        link: '/app/sector-comparison',
      },
      {
        key: 'research',
        name: 'Research',
        icon: 'search',
        link: '/app/research',
      },
    ],
  },
];

export const apiRoutes = {
  index: {
    getCatalog: (id) => `/catalog/${id}`,
    addAttribute: (id) => `/product/${id}/attributes`,
  },
  ecobalyse: {
    textile: {
      simulator: '/textile/simulator',
    },
  },
};

import React, { useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import {
  FormControl, Grid, Input, InputLabel, MenuItem, Select, Typography
} from '@mui/material';
import Accordion from './Accordion';
import messages from './messages';
import { durabilityCategoryItems } from './Helpers';

const useStyles = makeStyles()((theme) => ({
  root: {
    cursor: 'default',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    overflow: 'auto',
  },
  title: {
    fontSize: theme.typography.pxToRem(32),
    fontWeight: 'bold',
  },
  itemSelect: {
    width: '100%',
    gap: theme.spacing(1),
  },
}));

function CatalogProductExpandedView(props) {
  const { intl, innerRef } = props;

  const [formData, setFormData] = useState({});

  const handleChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  const { classes, cx } = useStyles();

  return (
    <div
      ref={innerRef}
      className={cx(classes.root)}
      tabIndex={-1}
    >
      <Typography
        variant="h1"
        className={cx(classes.title)}
      >
        <FormattedMessage {...messages.title} />
      </Typography>
      <Grid container>
        <Grid
          item
          xs={12}
        >
          <Accordion
            title={intl.formatMessage(messages.durability)}
            color={'#ebecec'}
          >
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                xs={12}
                md={6}
              >
                <FormControl fullWidth>
                  <InputLabel
                    id="durabilityCategoryLabel"
                    variant="standard"
                  >
                    {intl.formatMessage(messages.durability__category)}
                  </InputLabel>
                  <Select
                    variant="standard"
                    labelId="durabilityCategoryLabel"
                    id="durabilityCategory"
                    name="durabilityCategory"
                    className={cx(classes.test)}
                    label={intl.formatMessage(messages.durability__category)}
                    value={formData.durabilityCategory || ''}
                    onChange={handleChange}
                    fullWidth
                  >
                    {durabilityCategoryItems.map((typeItem) => (
                      <MenuItem
                        key={typeItem.value}
                        value={typeItem.value}
                        // className={cx(classes.itemSelect)}
                      >
                        <FormattedMessage {...messages[typeItem.messageKey]} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
              >
                <FormControl fullWidth>
                  <InputLabel
                    id="durabilityReferenceNumberLabel"
                    variant="standard"
                  >
                    {intl.formatMessage(messages.durability__reference_number)}
                  </InputLabel>
                  <Input
                    id="durabilityReferenceNumber"
                    name="durabilityReferenceNumber"
                    value={formData.durabilityReferenceNumber || 0}
                    onChange={handleChange}
                    fullWidth
                    type={'number'}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Accordion>
          <Accordion
            title={intl.formatMessage(messages.raw_materials)}
            color={'#7b4dff'}
          ></Accordion>
          <Accordion
            title={intl.formatMessage(messages.spinning)}
            color={'#ea60df'}
          ></Accordion>
          <Accordion
            title={intl.formatMessage(messages.weaving)}
            color={'#ea60df'}
          ></Accordion>
          <Accordion
            title={intl.formatMessage(messages.ennoblement)}
            color={'#ea60df'}
          ></Accordion>
          <Accordion
            title={intl.formatMessage(messages.making)}
            color={'#ea60df'}
          ></Accordion>
          <Accordion
            title={intl.formatMessage(messages.distribution)}
            color={'#f5325b'}
          ></Accordion>
          <Accordion
            title={intl.formatMessage(messages.use)}
            color={'#ffca00'}
          ></Accordion>
          <Accordion
            title={intl.formatMessage(messages.end_of_life)}
            color={'#22d2ba'}
          ></Accordion>
        </Grid>
      </Grid>
    </div>
  );
}

CatalogProductExpandedView.propTypes = {
  intl: PropTypes.object.isRequired,
  innerRef: PropTypes.object,
  data: PropTypes.object,
};

export default injectIntl(CatalogProductExpandedView);

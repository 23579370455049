import { defineMessages } from 'react-intl';

export const scope = 'boilerplate.components.Catalogs.EcoScoreView';

export default defineMessages({
  acd: {
    id: `${scope}.acd`,
    defaultMessage: 'Acidification'
  },
  bvi: {
    id: `${scope}.bvi`,
    defaultMessage: 'Biodiversity Impact'
  },
  cch: {
    id: `${scope}.cch`,
    defaultMessage: 'Climate Change (Global Warming Potential)'
  },
  ecs: {
    id: `${scope}.ecs`,
    defaultMessage: 'Impact Score'
  },
  etf: {
    id: `${scope}.etf`,
    defaultMessage: 'Ecotoxicity in Freshwater'
  },
  fru: {
    id: `${scope}.fru`,
    defaultMessage: 'Fossil Resource Usage'
  },
  fwe: {
    id: `${scope}.fwe`,
    defaultMessage: 'Freshwater Eutrophication'
  },
  htc: {
    id: `${scope}.htc`,
    defaultMessage: 'Human Toxicity - Cancer'
  },
  htn: {
    id: `${scope}.htn`,
    defaultMessage: 'Human Toxicity - Non-cancer'
  },
  ior: {
    id: `${scope}.ior`,
    defaultMessage: 'Ionizing Radiation'
  },
  ldu: {
    id: `${scope}.ldu`,
    defaultMessage: 'Land Use'
  },
  mru: {
    id: `${scope}.mru`,
    defaultMessage: 'Mineral Resource Usage'
  },
  ozd: {
    id: `${scope}.ozd`,
    defaultMessage: 'Ozone Depletion'
  },
  pco: {
    id: `${scope}.pco`,
    defaultMessage: 'Photochemical Ozone Creation'
  },
  pef: {
    id: `${scope}.pef`,
    defaultMessage: 'Product Environmental Footprint'
  },
  pma: {
    id: `${scope}.pma`,
    defaultMessage: 'Particulate Matter Formation'
  },
  swe: {
    id: `${scope}.swe`,
    defaultMessage: 'Marine Water Eutrophication'
  },
  tre: {
    id: `${scope}.tre`,
    defaultMessage: 'Terrestrial Eutrophication'
  },
  wtu: {
    id: `${scope}.wtu`,
    defaultMessage: 'Water Usage'
  },
});

import React from 'react';
import * as Icons from '@mui/icons-material';
import _ from 'lodash';
import { GridActionsCellItem } from '@mui/x-data-grid';
import { Button, Tooltip } from '@mui/material';
import messages from './messages.js';
import HeaderWithIcon from './HeaderWithIcon.jsx';
import CustomBodyRenderValue from './CustomCellBody.jsx';
import Constraint from '../../../utils/constraints/columnConstraints.js';
import {
  businessTypes, countryCodes, fabricProcess, materialTypes, dyeingOn, tracabilityTypes
} from '../../../utils/ecobalyse/constants.js';

export const CatalogProductListExpandedColumnGroupingModel = (intl) => [
  {
    groupId: 'caracteristics',
    headerName: intl.formatMessage(messages.header_group_characteristics),
    children: [
      { field: 'attributes.pef' },
      { field: 'product_code' },
      { field: 'product_description' },
      { field: 'attributes.code' },
      { field: 'attributes.supplier' },
    ],
    renderHeaderGroup: (params) => <HeaderWithIcon color={'#222222'} superHeader {...params} />,
  },
  {
    groupId: 'completion',
    headerName: intl.formatMessage(messages.header_group_completion),
    children: [
      { field: 'completion_rate' },
      { field: 'nok_number' },
    ],
    renderHeaderGroup: (params) => <HeaderWithIcon color={'#bdbd76'} superHeader {...params} />,
  },
  {
    groupId: 'durability',
    headerName: intl.formatMessage(messages.header_group_non_physical_durability),
    children: [
      { field: 'attributes.mass' },
      { field: 'attributes.price' },
      { field: 'attributes.number_of_references' },
      { field: 'attributes.marketing_duration' },
      { field: 'attributes.business' },
      { field: 'attributes.traceability_displayed' },
    ],
    renderHeaderGroup: (params) => <HeaderWithIcon color={'#000000'} superHeader {...params} />,
  },
  {
    groupId: 'raw_materials',
    headerName: intl.formatMessage(messages.header_group_raw_materials),
    children: [
      { field: 'attributes.material_1_code' },
      { field: 'attributes.material_1_origin_country' },
      { field: 'attributes.material_1_percentage' },
      { field: 'attributes.material_2_code' },
      { field: 'attributes.material_2_origin_country' },
      { field: 'attributes.material_2_percentage' },
      { field: 'attributes.material_3_code' },
      { field: 'attributes.material_3_origin_country' },
      { field: 'attributes.material_3_percentage' },
    ],
    renderHeaderGroup: (params) => <HeaderWithIcon color={'#73a111'} superHeader {...params} />,
  },
  {
    groupId: 'transformation',
    headerName: intl.formatMessage(messages.header_group_transformation),
    children: [
      { field: 'attributes.spinning_country' },
      { field: 'attributes.yarn_titrage_nm' },
      { field: 'attributes.yarn_country' },
      { field: 'attributes.dyeing_process' },
      { field: 'attributes.dyeing_country' },
      { field: 'attributes.dyeing_printing_process' },
      { field: 'attributes.dyeing_printing_percentage' },
      { field: 'attributes.making_country' },
      { field: 'attributes.loose_percentage' },
      { field: 'attributes.dormant_stock_percentage' },
      { field: 'attributes.air_transport_percentage' },
    ],
    renderHeaderGroup: (params) => <HeaderWithIcon color={'#118610'} superHeader {...params} />,
  },
];

/**
 * A getter function that retrieves a value from a row object based on the given name.
 *
 * @param {string} name - The name of the property to retrieve from the row object.
 * @param {string} [defaultValue=''] - The default value to return if the retrieved value is empty or not found. Defaults to `''`.
 *
 * @param {any} __ - The first parameter is not used in this function.
 * @param {object} row - The row object from which to retrieve the value.
 *
 * @returns {(__: any, row: object) => string} The retrieved value, or an empty string if the value is empty or not found.
 */
const getter = (name, defaultValue = '') => (__, row) => {
  const value = _.get(row, name);
  if (!value || value.source.toUpperCase() === 'EMPTY') return defaultValue;
  return String(value.value);
};

/**
 * Adds a unit to a value
 *
 * @param {string} unit - The unit to be added.
 * @param {boolean} [close=false] - Indicates whether the unit should be directly attached to the value or separated by a non-breaking space.
 * @param {number} value - The value to which the unit should be added.
 *
 * @returns {(value: any) => string} A function that takes a value and returns the value with the unit added.
 */
const addUnit = (unit, close = false) => (value) => (value ? (`${value}${close ? '' : '\u00A0'}${unit}`) : ''); // \u00A0 = non-breaking space

const CatalogColumns = (intl, onSync = () => {}) => [
  {
    field: '_actions_sync',
    type: 'actions',
    headerName: intl.formatMessage(messages.column_header_sync),
    editable: false,
    width: 120,
    getActions: (params) => [
      <Tooltip title='Calculer Impact' key='sync'>
        <GridActionsCellItem
          key='sync'
          icon={<Icons.Sync />}
          label={'Calculer Impact'}
          onClick={() => onSync(params)}
        />
      </Tooltip>,
    ]
  },
  {
    field: 'attributes.ECOBALYSE_RESULT_URL',
    headerName: intl.formatMessage(messages.column_header_ecobalyse_result_url),
    editable: false,
    noTooltip: true,
    renderCell: ({ formattedValue }) => (
      <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        {formattedValue
          ? <a href={formattedValue} target='_blank' rel='noreferrer'>
            <Icons.OpenInNew />
          </a>
          : ''
        }
      </div>
    )
  },
  {
    field: 'attributes.ECOBALYSE_INPUT_JSON',
    headerName: intl.formatMessage(messages.column_header_ecobalyse_input_json),
    editable: false,
    noTooltip: true,
    renderCell: ({ formattedValue }) => (
      <div style={{
        width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'
      }}>
        {formattedValue
          ? <Button
            variant='text'
            onClick={() => console.log('Open JSON')}
          >
            <Icons.RemoveRedEye />
          </Button>
          : ''
        }
      </div>
    )
  },
  {
    field: 'attributes.ecs',
    headerName: intl.formatMessage(messages.column_header_ecs),
    type: 'number',
    editable: false,
    defaultValue: '-',
    valueFormatter: (value) => {
      if (!value) return '';
      if (Number.isNaN(Number(value))) return value;
      return Number(value).toFixed(2) + ' CTUe';
    },
    renderCell: ({ formattedValue }) => (
      <div style={{ display: 'flex' }}>
        <span style={{ width: '100%', textAlign: 'center' }}>{formattedValue}</span>
      </div>
    )
  },
  {
    field: 'attributes.pef',
    headerName: 'PEF',
    type: 'number',
    editable: false,
    defaultValue: '-',
    valueFormatter: (value) => {
      if (!value) return '';
      if (Number.isNaN(Number(value))) return value;
      return Number(value).toFixed(2) + ' µPt';
    },
    renderCell: ({ formattedValue }) => (
      <div style={{ display: 'flex' }}>
        <span style={{ width: '100%', textAlign: 'center' }}>{formattedValue}</span>
      </div>
    )
  },
  {
    field: 'product_code',
    headerName: intl.formatMessage(messages.column_header_product_name),
    valueGetter: (__, row) => (_.get(row, 'product_code', '')),
    editable: false,
    noTooltip: true,
  },
  {
    field: 'product_description',
    headerName: intl.formatMessage(messages.column_header_description),
    valueGetter: (__, row) => (_.get(row, 'product_description', '')),
    editable: false,
    noTooltip: true,
  },
  {
    field: 'attributes.code',
    headerName: intl.formatMessage(messages.column_header_reference),
    type: 'string',
    constraints: ['required'],
  },
  {
    field: 'attributes.supplier',
    headerName: intl.formatMessage(messages.column_header_supplier),
    type: 'string',
    constraints: ['required'],
  },
  {
    field: 'completion_rate',
    headerName: intl.formatMessage(messages.column_header_completion_rate),
    editable: false,
    type: 'number',
    valueGetter: (__, row, ___, api) => {
      const attributesColumns = api.current.getAllColumns().filter((col) => (
        col.field.startsWith('attributes.') && !['.pef'].some((e) => col.field.endsWith(e))
      ));
      const nokNumber = attributesColumns.filter((col) => (!_.get(row, col.field) || _.get(row, col.field).source === 'EMPTY')).length;
      return `${(((attributesColumns.length - nokNumber) / attributesColumns.length) * 100).toFixed(2)}`;
    },
    valueFormatter: addUnit('%', true),
    noTooltip: true,
  },
  {
    field: 'nok_number',
    headerName: intl.formatMessage(messages.column_header_nok_number),
    editable: false,
    type: 'number',
    valueGetter: (__, row, ___, api) => {
      const attributesColumns = api.current.getAllColumns().filter((col) => (
        col.field.startsWith('attributes.') && !['.pef'].some((e) => col.field.endsWith(e))
      ));
      return attributesColumns.filter((col) => (!_.get(row, col.field) || _.get(row, col.field).source === 'EMPTY')).length;
    },
    noTooltip: true,
  },
  {
    field: 'attributes.mass',
    headerName: intl.formatMessage(messages.column_header_mass),
    type: 'number',
    constraints: ['required', 'positive'],
    valueFormatter: addUnit('g'),
  },
  {
    field: 'attributes.price',
    headerName: intl.formatMessage(messages.column_header_price),
    type: 'number',
    constraints: ['required', 'positive'],
    valueFormatter: addUnit('€'),
  },
  {
    field: 'attributes.number_of_references',
    headerName: intl.formatMessage(messages.column_header_reference_number),
    type: 'number',
    constraints: ['required', 'positive'],
    valueGetter: (__, row) => getter('attributes.number_of_references')(__, row)?.replace(/[^\d.]/g, ''),
  },
  {
    field: 'attributes.marketing_duration',
    headerName: intl.formatMessage(messages.column_header_marketing_duration),
    type: 'number',
    constraints: ['required', 'positive'],
    valueFormatter: (value) => {
      if (!value) return '';
      const v = Number(value) || 0;
      return addUnit('jour' + (v > 1 ? 's' : ''))(v);
    },
  },
  {
    field: 'attributes.business',
    headerName: intl.formatMessage(messages.column_header_business),
    constraints: [{ name: 'oneOf', args: [businessTypes] }],
    valueGetter: (__, row) => (_.get(row, 'attributes.business', '')).value,
    valueFormatter: (value) => {
      if (!value) return '';
      return businessTypes[value] || '';
    },
  },
  {
    field: 'attributes.traceability_displayed',
    headerName: intl.formatMessage(messages.column_header_displayed_traceability),
    type: 'boolean',
    valueFormatter: (value) => (value ? 'Oui' : 'Non'),
    constraints: [{ name: 'oneOf', args: [tracabilityTypes] }],

  },
  {
    field: 'attributes.material_1_code',
    headerName: intl.formatMessage(messages.column_header_material, { nb: 1 }),
    constraints: ['required', { name: 'oneOf', args: [materialTypes] }],
  },
  {
    field: 'attributes.material_1_origin_country',
    headerName: intl.formatMessage(messages.column_header_origin, { nb: 1 }),
    constraints: ['required', { name: 'oneOf', args: [countryCodes] }],
  },
  {
    field: 'attributes.material_1_percentage',
    headerName: intl.formatMessage(messages.column_header_percentage, { nb: 1 }),
    type: 'number',
    constraints: ['required', 'positive', { name: 'max', args: [100] }],
    valueFormatter: addUnit('%'),
  },
  {
    field: 'attributes.material_2_code',
    headerName: intl.formatMessage(messages.column_header_material, { nb: 2 }),
    constraints: ['required', { name: 'oneOf', args: [materialTypes] }],
  },
  {
    field: 'attributes.material_2_origin_country',
    headerName: intl.formatMessage(messages.column_header_origin, { nb: 2 }),
    constraints: ['required', { name: 'oneOf', args: [countryCodes] }],
  },
  {
    field: 'attributes.material_2_percentage',
    headerName: intl.formatMessage(messages.column_header_percentage, { nb: 2 }),
    type: 'number',
    constraints: ['required', 'positive', { name: 'max', args: [100] }],
    valueFormatter: addUnit('%'),
  },
  {
    field: 'attributes.material_3_code',
    headerName: intl.formatMessage(messages.column_header_material, { nb: 3 }),
    constraints: ['required', { name: 'oneOf', args: [materialTypes] }],
  },
  {
    field: 'attributes.material_3_origin_country',
    headerName: intl.formatMessage(messages.column_header_origin, { nb: 3 }),
    constraints: ['required', { name: 'oneOf', args: [countryCodes] }],
  },
  {
    field: 'attributes.material_3_percentage',
    headerName: intl.formatMessage(messages.column_header_percentage, { nb: 3 }),
    type: 'number',
    constraints: ['required', 'positive', { name: 'max', args: [100] }],
    valueFormatter: addUnit('%'),
  },
  {
    field: 'attributes.spinning_country',
    headerName: intl.formatMessage(messages.column_header_spinning_country),
    constraints: ['required', { name: 'oneOf', args: [countryCodes] }],
  },
  {
    field: 'attributes.yarn_titrage_nm',
    headerName: intl.formatMessage(messages.column_header_titration_nm),
    type: 'number',
    constraints: ['required', 'positive'],
    valueFormatter: addUnit('nm'),
  },
  {
    field: 'attributes.yarn_country',
    headerName: intl.formatMessage(messages.column_header_weaving_country),
    constraints: ['required', { name: 'oneOf', args: [countryCodes] }],
  },
  {
    field: 'attributes.dyeing_process',
    headerName: intl.formatMessage(messages.column_header_process),
    constraints: ['required', { name: 'oneOf', args: [fabricProcess] }],
  },
  {
    field: 'attributes.dyeing_country',
    headerName: intl.formatMessage(messages.column_header_finishing_country),
    constraints: ['required', { name: 'oneOf', args: [countryCodes] }],
  },
  {
    field: 'attributes.dyeing_printing_process',
    headerName: intl.formatMessage(messages.column_header_dyeing_on),
    constraints: [{ name: 'oneOf', args: [dyeingOn] }],
  },
  {
    field: 'attributes.dyeing_printing_percentage',
    headerName: intl.formatMessage(messages.column_header_printing),
    type: 'number',
    constraints: ['required', 'positive', { name: 'max', args: [100] }],
    valueFormatter: addUnit('%'),
  },
  {
    field: 'attributes.making_country',
    headerName: intl.formatMessage(messages.column_header_manufacturing_country),
    constraints: ['required', { name: 'oneOf', args: [countryCodes] }],
  },

  {
    field: 'attributes.loose_percentage',
    headerName: intl.formatMessage(messages.column_header_loss_percentage),
    type: 'number',
    constraints: ['required', 'positive', { name: 'max', args: [100] }],
    valueFormatter: addUnit('%'),
  },
  {
    field: 'attributes.dormant_stock_percentage',
    headerName: intl.formatMessage(messages.column_header_dormant_stock_percentage),
    type: 'number',
    constraints: ['required', 'positive', { name: 'max', args: [100] }],
    valueFormatter: addUnit('%'),
  },
  {
    field: 'attributes.air_transport_percentage',
    headerName: intl.formatMessage(messages.column_header_air_transport_percentage),
    type: 'number',
    constraints: ['required', 'positive', { name: 'max', args: [100] }],
    valueFormatter: addUnit('%'),
  },
];

const ColumnBaseOptions = (colOpt, attributesColumnsNb) => ({
  width: 200,
  editable: true,
  valueGetter: getter(colOpt.field, colOpt.defaultValue || ''),
  renderHeader: (params) => (<HeaderWithIcon {...params.colDef} />),
  renderCell: (params) => (
    <CustomBodyRenderValue
      formattedValue={params.formattedValue}
      valueOptions={_.get(params.row, params.colDef.field)}
      options={params}
      attributesColumnsNb={attributesColumnsNb}
      printTooltip={!params.colDef.noTooltip}
      lookup={colOpt?.lookup || colOpt?.constraints?.find((c) => c.key === 'oneOf')?.args?.values || {}}
    />
  ),
});

export const CatalogProductListExpandedColumns = (intl, onSync = () => {}) => {
  const columns = CatalogColumns(intl, onSync);
  const attributesColumnsNb = columns.filter((col) => col.field.startsWith('attributes.')).length;
  const constraintObject = new Constraint(intl);
  return columns.map((columnOptions) => {
    if (columnOptions.constraints?.length) {
      columnOptions.constraints = columnOptions.constraints.reduce((acc, cons) => {
        if (typeof cons === 'string') {
          if (cons !== 'constructor' && typeof constraintObject[cons] === 'function') {
            acc.push(constraintObject[cons]());
          }
        } else if (typeof cons === 'object') {
          if (cons.name && cons.name !== 'constructor' && typeof constraintObject[cons.name] === 'function') {
            const args = cons.args || [];
            acc.push(constraintObject[cons.name](...args));
          } else {
            acc.push(cons);
          }
        }
        return acc;
      }, []);
    }
    const baseOptions = ColumnBaseOptions(columnOptions, attributesColumnsNb);
    if (columnOptions.type === 'actions') {
      delete baseOptions.valueGetter;
      delete baseOptions.renderCell;
    }
    return { ...baseOptions, ...columnOptions };
  });
};

export default { CatalogProductListExpandedColumns };

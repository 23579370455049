import axios from 'axios';
import { apiRoutes } from './routes';

export function flattenObject(ob) {
  const returnObj = {};
  Object.keys(ob).map((i) => {
    if (typeof ob[i] === 'object' && ob[i] !== null) {
      const flatObject = flattenObject(ob[i]);
      Object.keys(flatObject).map((x) => {
        returnObj[`${i}.${x}`] = flatObject[x];
        return x;
      });
    } else {
      returnObj[i] = ob[i];
    }
    return i;
  });
  return returnObj;
}

export function slugify(text) {
  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w-]+/g, '') // Remove all non-word chars
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text
}

export function decodeHTMLEntities(html) {
  const txt = document.createElement('textarea');
  txt.innerHTML = html;
  return txt.value;
}

export async function fetchCampaignData(id) {
  /** @type {import('axios').AxiosRequestConfig} */
  const axiosConfigs = { url: `${process.env.NOCOO_INDEX_URL}${apiRoutes.index.getCatalog(id)}`, method: 'GET', };
  return axios(axiosConfigs).then(({ data }) => (data.filter((prod) => prod.success)?.at(0) ?? {}));
}

import React, { useEffect, useState } from 'react';
import {
  Button, FormControl, Input, InputLabel, MenuItem, Select
} from '@mui/material';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import messages from './messages';

function CustomToolbar(props) {
  const {
    intl, classes, cx, columns = [], columnFilter, attr, val, setVal, setAttr, handleChangeValue, disabled
  } = props;

  const [selectedColumn, setSelectedColumn] = useState(0);

  const filteredColumns = columns.filter(columnFilter);

  useEffect(() => {
    if (typeof attr === 'string') {
      const index = filteredColumns.findIndex((column) => column.field === attr);
      if (index !== -1) {
        setSelectedColumn(filteredColumns[index]);
      } else {
        setSelectedColumn(null);
      }
    } else {
      setSelectedColumn(null);
    }
  }, [attr]);

  return (
    <div className={classes.colChangeContainer}>
      <Select
        value={attr}
        className={cx(classes.themedBorder)}
        onChange={(e) => setAttr(e.target.value)}
        variant='standard'
      >
        <MenuItem value={0}>{intl.formatMessage(messages.product_list_select_column)}</MenuItem>
        {filteredColumns.map((column) => (
          <MenuItem
            key={column.field}
            value={column.field}
          >
            {column.headerName}
          </MenuItem>
        ))}
      </Select>
      <FormControl>
        {
          (selectedColumn && selectedColumn.constraints?.find(c => c.key === 'oneOf'))
            ? (
              <Select
                value={val}
                onChange={(e) => setVal(e.target.value)}
                className={cx(classes.noSpace, classes.themedBorder)}
              >
                {Object.entries(selectedColumn.constraints.find(c => c.key === 'oneOf').args.values).map(([key, displayValue]) => (
                  <MenuItem
                    key={key}
                    value={key}
                  >
                    {displayValue}
                  </MenuItem>
                ))}
              </Select>
            ) : (
              <>
                <InputLabel
                  htmlFor='newColumnValue'
                  className={cx(classes.lowerLabel)}
                >
                  {intl.formatMessage(messages.product_list_new_value)}
                </InputLabel>
                <Input
                  id='newColumnValue'
                  value={val}
                  onChange={(e) => setVal(e.target.value)}
                  className={cx(classes.noSpace, classes.themedBorder)}
                  onKeyDown={(e) => (!disabled && e.key === 'Enter') && handleChangeValue()}
                />
              </>
            )
        }
      </FormControl>
      <Button
        variant='outlined'
        onClick={handleChangeValue}
        disabled={disabled}
      >
        {intl.formatMessage(messages.product_list_change_value)}
      </Button>
    </div>
  );
}

CustomToolbar.propTypes = {
  intl: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  cx: PropTypes.func.isRequired,
  columns: PropTypes.array.isRequired,
  columnFilter: PropTypes.func.isRequired,
  attr: PropTypes.any.isRequired,
  val: PropTypes.any.isRequired,
  setVal: PropTypes.func.isRequired,
  setAttr: PropTypes.func.isRequired,
  handleChangeValue: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default injectIntl(CustomToolbar);

import React, { Fragment, useState } from "react";
import { MaterialDropZone } from "enl-components";
import PropTypes from "prop-types";
import messages from "./messages";
import { injectIntl } from "react-intl";

function CatalogFileUpload(props) {
  const { intl, files, setFiles, maxSize, filesLimit } = props;

  return (
    <Fragment>
      <div>
        <MaterialDropZone
          files={files}
          setFiles={setFiles}
          showPreviews
          maxSize={maxSize}
          filesLimit={filesLimit}
          text={intl.formatMessage(messages.drop_files)}
        />
      </div>
    </Fragment>
  );
}

CatalogFileUpload.propTypes = {
  intl: PropTypes.object.isRequired,
  files: PropTypes.array.isRequired,
  setFiles: PropTypes.func.isRequired,
  maxSize: PropTypes.number,
  filesLimit: PropTypes.number,
};

export default injectIntl(CatalogFileUpload);

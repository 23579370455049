import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import PropTypes from "prop-types";
import { Button, Typography } from "@mui/material";
import { CheckCircle } from "@mui/icons-material";
import messages from "./messages";
import { makeStyles } from "tss-react/mui";
import { generatePath } from "react-router";

const useStyles = makeStyles()((theme) => ({
  appBar: {
    position: "relative",
  },
  layout: {
    width: "auto",
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(3),
  },
  stepper: {
    padding: `${theme.spacing(3)} 0 ${theme.spacing(5)}`,
  },
  finishMessage: {
    textAlign: "center",
    // maxWidth: 600,
    margin: "0 clamp(1rem, 5%, 5rem)",
    "& h4": {
      "& span": {
        textAlign: "center",
        display: "block",
        "& svg": {
          color: theme.palette.secondary.main,
          height: "auto",
          width: 148,
        },
      },
    },
  },
  buttons: {
    display: "flex",
    justifyContent: "center",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

function CatalogProcessed(props) {
  const { intl, history, campaignCreateResult, campaignId } = props;
  const { classes, cx } = useStyles();

  function handleClick() {
    props.history.push(generatePath("/app/campaign-detail/:id", { id: campaignId ?? 21 }));
  }

  return (
    <div className={classes.finishMessage}>
      <Typography
        variant="h4"
        gutterBottom
      >
        <span>
          <CheckCircle />
        </span>
        <FormattedMessage {...messages.processed} />
      </Typography>
      <Typography variant="subtitle1">
        <FormattedMessage {...messages.files_processing} />
      </Typography>
      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        onClick={handleClick}
      >
        <FormattedMessage {...messages.see_campaign} />
      </Button>
    </div>
  );
}

CatalogProcessed.propTypes = {
  intl: PropTypes.object.isRequired,
};

export default injectIntl(CatalogProcessed);

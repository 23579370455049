import React from 'react';
import { makeStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import Paper from '@mui/material/Paper';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Icon } from '@mui/material';
import FileIcon from '@mui/icons-material/Description';
import PropTypes from 'prop-types';
import messages from './messages';
import isImage from '../Forms/helpers/helpers';
import 'enl-styles/vendors/react-dropzone/react-dropzone.css';

const useStyles = makeStyles()((theme) => ({
  listItem: {
    padding: `${theme.spacing(1)} 0`,
  },
  paper: {
    border: `2px solid ${theme.palette.primary.main}`,
    padding: theme.spacing(2),
    height: 550,
    overflow: 'auto',
    '& h6': {
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'center',
      '& span.material-icons': {
        fontSize: 24,
        alignSelf: 'center',
      },
    },
  },
  thumb: {
    width: 120,
    height: 70,
    overflow: 'hidden',
    marginRight: theme.spacing(2),
    borderRadius: theme.rounded.small,
    '& img': {
      maxWidth: '100%',
    },
  },
  dropItem: {
    borderColor: theme.palette.divider,
    background: theme.palette.background.default,
    borderRadius: theme.rounded.medium,
    color: theme.palette.text.disabled,
    textAlign: 'center',
  },
  uploadIconSize: {
    display: 'inline-block',
    '& svg': {
      width: 72,
      height: 72,
      fill: theme.palette.secondary.main,
    },
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
    '& svg': {
      fill: theme.palette.common.white,
    },
  },
  button: {
    marginTop: 20,
  },
  fileInfos: {
    fontSize: '1rem',
    color: '#000',
    textAlign: 'center',
    position: 'absolute',
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: '#ffffff80',
    lineHeight: '1',
  },
}));

function CatalogSideReview(props) {
  const {
    type, campaignName, description, files
  } = props;

  const { classes } = useStyles();

  const fileRender = (file, index) => (
    <div key={index.toString()}>
      <div className="imageContainer col fileIconImg">
        {isImage(file) ? (
          <figure className="imgWrap">
            <img
              className="smallPreviewImg"
              src={URL.createObjectURL(file)}
              alt="preview"
            />
          </figure>
        ) : (
          <FileIcon
            className="smallPreviewImg"
            alt="preview"
          />
        )}
        <span className={classes.fileInfos}>{file.name}</span>
      </div>
    </div>
  );

  return (
    <Paper
      className={classes.paper}
      elevation={0}
    >
      <Typography
        variant="h6"
        gutterBottom
      >
        <Icon className={type ? messages[type].icon : 'menu_book'}>{type ? messages[type].icon : 'menu_book'}</Icon>
        &nbsp;&nbsp;
        {campaignName || <FormattedMessage {...messages.campaign_summary} />}
      </Typography>
      <Typography variant="body1">{description || ''}</Typography>
      <List component="ul">{files.map(fileRender)}</List>
    </Paper>
  );
}

CatalogSideReview.propTypes = {
  type: PropTypes.string,
  campaignName: PropTypes.string,
  description: PropTypes.string,
  files: PropTypes.array,
};

export default injectIntl(CatalogSideReview);

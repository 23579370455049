export const FETCH_EMAIL_DATA = 'FETCH_EMAIL_DATA';
export const OPEN_MAIL = 'OPEN_MAIL';
export const FILTER_MAIL = 'FILTER_MAIL';
export const COMPOSE_MAIL = 'COMPOSE_MAIL';
export const DISCARD_MESSAGE = 'DISCARD_MESSAGE';
export const SEARCH_MAIL = 'SEARCH_MAIL';
export const SUBMIT_MAIL = 'SUBMIT_MAIL';
export const MOVE_TO = 'MOVE_TO';
export const REMOVE_MAIL = 'REMOVE_MAIL';
export const TOGGLE_STARED = 'TOGGLE_STARED';

import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import brand from "enl-api/dummy/brand";

function BaseHelmet(props) {
  const title = (props.title ? `${props.title} — ` : "") + brand.name;
  const description = props.description || brand.desc;

  return (
    <Helmet>
      <title>{title}</title>
      <meta
        name="description"
        content={description}
      />
      <meta
        property="og:title"
        content={title}
      />
      <meta
        property="og:description"
        content={description}
      />
      <meta
        property="twitter:title"
        content={title}
      />
      <meta
        property="twitter:description"
        content={description}
      />
    </Helmet>
  );
}

BaseHelmet.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};

export default BaseHelmet;

import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Campaign from '@mui/icons-material/Campaign';
import SupervisorAccount from '@mui/icons-material/SupervisorAccount';
import Category from '@mui/icons-material/Category';
import { injectIntl } from 'react-intl';
import CounterWidget from '../Counter/CounterWidget';
import messages from './messages';
import useStyles from './widget-jss';

function StatusIconsWidget(props) {
  const { intl } = props;
  const { classes } = useStyles();

  return (
    <div className={classes.rootCounterFull}>
      <Grid container spacing={2}>
        <Grid item xs={6} md={4}>
          <CounterWidget
            color="secondary-dark"
            start={0}
            end={207}
            duration={3}
            title={intl.formatMessage(messages.campains)}
          >
            <Campaign className={classes.counterIcon} />
          </CounterWidget>
        </Grid>
        <Grid item xs={6} md={4}>
          <CounterWidget
            color="secondary-main"
            start={0}
            end={300}
            duration={3}
            title={intl.formatMessage(messages.supplier)}
          >
            <SupervisorAccount className={classes.counterIcon} />
          </CounterWidget>
        </Grid>
        <Grid item xs={6} md={4}>
          <CounterWidget
            color="secondary-main"
            start={0}
            end={67}
            duration={3}
            title={intl.formatMessage(messages.products)}
          >
            <Category className={classes.counterIcon} />
          </CounterWidget>
        </Grid>
      </Grid>
    </div>
  );
}

StatusIconsWidget.propTypes = {
  intl: PropTypes.object.isRequired
};

export default injectIntl(StatusIconsWidget);

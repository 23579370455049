import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';

const headerWithIconStyle = makeStyles()(() => ({
  superHeader: {
    'div.MuiDataGrid-columnHeaderTitleContainer.MuiDataGrid-withBorderColor:has(&)': {
      width: '100%',
      textAlign: 'center',
      borderRadius: '8px',
      backgroundColor: '#eeeeee',
    },
    '& span': {
      color: 'white',
      overflow: 'hidden',
      fontWeight: 'bold',
      marginRight: '5px',
      fontSize: '1.2rem',
      paddingInline: '8px',
      textOverflow: 'ellipsis',
    },
  },
  header: {
    width: '100%',
    display: 'flex',
    overflow: 'hidden',
    alignItems: 'center',
    'div:has(&)': {
      width: '100%',
    },
    '& span': {
      width: '100%',
      textAlign: 'center',
    },
  },
}));

const HeaderWithIcon = (props) => {
  const {
    headerName, groupId, icon, superHeader = false, color,
  } = props;

  const { classes, cx } = headerWithIconStyle();

  return (
    <div className={cx(classes.header, superHeader ? classes.superHeader : null)}>
      <span style={{ color: color ?? 'black' }}>{headerName ?? groupId}</span> {icon}
    </div>
  );
};

HeaderWithIcon.propTypes = {
  headerName: PropTypes.string,
  groupId: PropTypes.string,
  icon: PropTypes.node,
  superHeader: PropTypes.bool,
  color: PropTypes.string,
};

export default HeaderWithIcon;

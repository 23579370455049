import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import {
  amber, cyan, grey, lightGreen, red
} from '@mui/material/colors';

const useStyles = makeStyles()((theme) => ({
  cellText: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '5px',
    height: '100%',
  },
  flex: {
    display: 'flex',
  },
  center: {
    justifyContent: 'center',
  },
  left: {
    justifyContent: 'flex-start',
  },
  fullWidth: {
    width: '100%',
  },
  dotStatus: {
    width: theme.spacing(1),
    height: theme.spacing(1),
    display: 'inline-block',
    borderRadius: '50%',
    marginRight: theme.spacing(0.5)
  },
  tooltip: {
    cursor: 'help',
  },
  online: {
    backgroundColor: lightGreen[500]
  },
  modified: {
    backgroundColor: cyan[500]
  },
  bussy: {
    backgroundColor: red[500]
  },
  idle: {
    backgroundColor: amber[500]
  },
  offline: {
    backgroundColor: grey[500]
  },
}));

const CustomBodyRenderValue = (props) => {
  const {
    formattedValue, valueOptions, attributesColumnsNb, printTooltip, options, lookup, intl, // eslint-disable-line no-unused-vars
  } = props;

  const displayValue = lookup ? lookup[formattedValue] ?? formattedValue : formattedValue;

  const { field, value } = options;

  const { classes, cx } = useStyles();

  let valueStatus = 'offline';
  let statusInfo = 'Unkown';
  let source;
  let color;

  if (field === 'nok_number') {
    color = `rgb(${(value * (255 / attributesColumnsNb))}, ${255 - (value * (255 / attributesColumnsNb))}, 0)`;
  } else if (field === 'completion_rate') {
    color = `rgb(${255 - (value * 2.55)}, ${value * 2.55}, 0)`;
  }

  if (valueOptions && typeof valueOptions === 'string') {
    source = 'EXTRACTED';
  } else {
    source = valueOptions?.source ?? 'EMPTY';
  }

  switch (source) {
    case 'EXTRACTED':
      valueStatus = 'online';
      statusInfo = 'Donnée trouvée';
      break;
    case 'MODIFIED':
      valueStatus = 'modified';
      statusInfo = 'Donnée modifiée';
      break;
    case 'DEFAULT':
      valueStatus = 'idle';
      statusInfo = 'Donnée par défaut';
      break;
    case 'EMPTY':
    default:
      valueStatus = 'bussy';
      statusInfo = 'Donnée manquante';
      break;
  }

  return (
    <div className={cx(classes.cellText)} style={color ? { color } : {}}>
      <Tooltip title={displayValue}>
        <span className={cx(classes.fullWidth, classes.flex, String(value).length > 20 ? classes.left : classes.center)}>
          {displayValue}
        </span>
      </Tooltip>
      {printTooltip && (<Tooltip title={statusInfo} className={cx(classes.tooltip)}>
        <i className={cx(classes.dotStatus, classes[valueStatus])} />
      </Tooltip>)}
    </div>
  );
};

CustomBodyRenderValue.propTypes = {
  formattedValue: PropTypes.string,
  valueOptions: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object, // undefined, null
    PropTypes.shape({
      value: PropTypes.string,
      source: PropTypes.string,
      code: PropTypes.string,
    }),
  ]),
  attributesColumnsNb: PropTypes.any,
  printTooltip: PropTypes.bool,
  options: PropTypes.any,
  lookup: PropTypes.object,
  intl: PropTypes.object,
};

CustomBodyRenderValue.defaultProps = {
  formattedValue: '',
  valueOptions: '',
  printTooltip: true,
  attributesColumnsNb: 0,
  options: {},
  lookup: {},
  intl: {},
};

export default CustomBodyRenderValue;

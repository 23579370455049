import { pink, purple, orange, teal, lightGreen, lightBlue, indigo, red } from "@mui/material/colors";

const colorfull = [
  pink[400],
  indigo.A200,
  lightBlue[500],
  teal[500],
  orange[600],
  lightGreen[600],
  purple[500],
  red[700],
];

export default colorfull;

export const durabilityCategoryItems = [
  {
    value: 'shirt',
    messageKey: 'durabilityCategory__shirt',
  },
  {
    value: 'jean',
    messageKey: 'durabilityCategory__jean',
  },
  {
    value: 'skirt_dress',
    messageKey: 'durabilityCategory__skirt_dress',
  },
  {
    value: 'coat_jacket',
    messageKey: 'durabilityCategory__coat_jacket',
  },
  {
    value: 'pants_shorts',
    messageKey: 'durabilityCategory__pants_shorts',
  },
  {
    value: 'sweater',
    messageKey: 'durabilityCategory__sweater',
  },
  {
    value: 'tshirt_polo',
    messageKey: 'durabilityCategory__tshirt_polo',
  },
  {
    value: 'socks',
    messageKey: 'durabilityCategory__socks',
  },
  {
    value: 'woven_boxer',
    messageKey: 'durabilityCategory__woven_boxer',
  },
  {
    value: 'knitted_boxer',
    messageKey: 'durabilityCategory__knitted_boxer',
  },
  {
    value: 'swimsuit',
    messageKey: 'durabilityCategory__swimsuit',
  },
];

export const units = {
  acd: { unit: 'mol H+ eq', round: 2 },
  bvi: { unit: 'Qualitative or Index', round: 2 },
  cch: { unit: 'kg CO2 eq', round: 2 },
  ecs: { unit: 'CTUe', round: 2, customStyle: { fontWeight: 'bold' } },
  etf: { unit: 'CTUe', round: 2 },
  fru: { unit: 'MJ', round: 2 },
  fwe: { unit: 'kg P eq', round: 2 },
  htc: { unit: 'CTUh', round: 2 },
  htn: { unit: 'CTUh', round: 2 },
  ior: { unit: 'kBq U235 eq', round: 2 },
  ldu: { unit: 'm²', round: 2 },
  mru: { unit: 'kg Sb eq', round: 2 },
  ozd: { unit: 'kg CFC-11 eq', round: 2 },
  pco: { unit: 'kg NMVOC', round: 2 },
  pef: { unit: 'µPt', round: 2 },
  pma: { unit: 'incidence/m³', round: 2 },
  swe: { unit: 'kg N eq', round: 2 },
  tre: { unit: 'mol N eq', round: 2 },
  wtu: { unit: 'm³', round: 2 },
};

export const materialTypes = {
  'coton-rdpc': 'Production de fil de coton recyclé (recyclage mécanique) post-consumption',
  'coton-rdp': 'Production de fil de coton recyclé (recyclage mécanique) de production',
  'ei-chanvre': 'Chanvre',
  'ei-coton': 'Coton',
  'ei-coton-organic': 'Coton organique',
  elasthane: 'Elasthane (Lycra)',
  'ei-acrylique': 'Acrylique',
  'ei-jute-kenaf': 'Jute',
  'ei-laine-par-defaut': 'Laine par défaut',
  'ei-laine-nouvelle-filiere': 'Laine nouvelle filière',
  'ei-lin': 'Lin',
  'ei-pa': 'Nylon',
  'ei-pp': 'Polypropylène',
  'ei-pet': 'Polyester',
  'ei-pet-r': 'Polyester recyclé',
  'ei-viscose': 'Viscose',
  'ei-viscose-r': 'Viscose recyclée',
};

export const countryCodes = {
  '---': 'Inconnu',
  REO: "Région - Europe de l'Ouest",
  REE: "Région - Europe de l'Est",
  RAS: 'Région - Asie',
  RAF: 'Région - Afrique',
  RME: 'Région - Moyen-Orient',
  RLA: 'Région - Amérique Latine',
  RNA: 'Région - Amérique du Nord',
  ROC: 'Région - Océanie',
  MM: 'Myanmar',
  BD: 'Bangladesh',
  CN: 'Chine',
  FR: 'France',
  IN: 'Inde',
  KH: 'Cambodge',
  MA: 'Maroc',
  PK: 'Pakistan',
  TN: 'Tunisie',
  TR: 'Turquie',
  VN: 'Vietnam',
};

export const businessTypes = {
  'small-Business': 'PME/TPE',
  'large-business-with-services': 'Grande entreprise proposant un service de réparation',
  'large-business-without-services': 'Grande entreprise ne proposant pas de service de réparation',
};

export const fabricProcess = {
  'knitting-mix': 'Knitting, mix',
  'knitting-fully-fashioned': 'Knitting, fully-fashioned',
  'knitting-integral': 'Knitting, integral',
  'knitting-circular': 'Knitting, circular',
  'knitting-straight': 'Knitting, straight',
  weaving: 'Weaving',
};

export const dyeingOn = {
  article: 'sur article',
  fabric: 'sur tissu',
  yarn: 'sur fil',
};

export const tracabilityTypes = {
  true: 'Oui',
  false: 'Non',
};

export const LOAD_TASK = 'LOAD_TASK';
export const LOAD_TASKS_FULFILLED = 'LOAD_TASKS_FULFILLED';

export const CREATE_TASK = 'CREATE_TASK';
export const CREATE_TASK_FAILED = 'CREATE_TASK_FAILED';
export const CREATE_TASK_FULFILLED = 'CREATE_TASK_FULFILLED';

export const REMOVE_TASK = 'REMOVE_TASK';
export const REMOVE_TASK_FAILED = 'REMOVE_TASK_FAILED';
export const REMOVE_TASK_FULFILLED = 'REMOVE_TASK_FULFILLED';

export const UPDATE_TASK = 'UPDATE_TASK';
export const UPDATE_TASK_FAILED = 'UPDATE_TASK_FAILED';
export const UPDATE_TASK_FULFILLED = 'UPDATE_TASK_FULFILLED';

export const FILTER_TASKS = 'FILTER_TASKS';

import { DataGrid } from '@mui/x-data-grid';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { frFR } from '@mui/x-data-grid/locales';
import { injectIntl } from 'react-intl';
import { EcoScoreColumnsOptions } from './Columns';

function EcoScoreView(props) {
  const {
    intl, rows, getRowId, loading, onCellClick, globalApiRef, slots, processRowUpdate, checkboxSelection, onRowSelectionModelChange
  } = props;

  const [paginationModel, setPaginationModel] = React.useState({ pageSize: 25, page: 0 });

  const columns = useMemo(() => EcoScoreColumnsOptions(intl), [intl]);

  return (
    <DataGrid
      rows={rows.filter((row) => row.attributes.pef)}
      slots={slots}
      pageSize={25}
      columns={columns}
      loading={loading}
      getRowId={getRowId}
      apiRef={globalApiRef}
      onCellClick={onCellClick}
      paginationModel={paginationModel}
      processRowUpdate={processRowUpdate}
      checkboxSelection={checkboxSelection}
      onRowSelectionModelChange={onRowSelectionModelChange}
      onPaginationModelChange={(model) => setPaginationModel(model)}
      localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
    />
  );
}

EcoScoreView.propTypes = {
  intl: PropTypes.object.isRequired,
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  getRowId: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  onCellClick: PropTypes.func,
  globalApiRef: PropTypes.object,
  slots: PropTypes.object,
  processRowUpdate: PropTypes.func,
  checkboxSelection: PropTypes.bool,
  onRowSelectionModelChange: PropTypes.func
};

EcoScoreView.defaultProps = {
  loading: false,
  onCellClick: () => {},
  globalApiRef: null,
  slots: {},
  processRowUpdate: () => {},
  checkboxSelection: false,
  onRowSelectionModelChange: () => {}
};

export default injectIntl(EcoScoreView);

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/**\n * Retrieves a color value from the $material-colors Sass map\n * Inspired by https://github.com/nilskaspersson/Google-Material-UI-Color-Palette/\n *\n * @function\n * @param {string} color-name             - quoted, lower-case, dasherized color\n *                                          name (e.g. 'pink', 'amber')\n * @param {string} [color-variant='500']  - quoted, lowercase color variant (e.g.\n *                                          '200', 'a100')\n */\n.H55iVRU7OEzD4sNPhJoAhw\\=\\= {\n  position: relative;\n  padding: 16px;\n  max-height: 450px;\n  overflow: auto;\n}\n@media (max-width: 600px) {\n  .H55iVRU7OEzD4sNPhJoAhw\\=\\= {\n    padding: 15px 10px;\n  }\n}\n\n._3JMZ7j4l0\\+AMlsIjN-aJnA\\=\\= {\n  background: rgba(136, 136, 136, 0.15);\n  position: relative;\n  bottom: 0;\n  left: 0;\n  width: 100%;\n  padding: 8px 24px;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  -webkit-box-pack: justify;\n      -ms-flex-pack: justify;\n          justify-content: space-between;\n}\n._3JMZ7j4l0\\+AMlsIjN-aJnA\\=\\= button {\n  margin-right: 5px;\n}\n._3JMZ7j4l0\\+AMlsIjN-aJnA\\=\\= p {\n  max-width: 250px;\n  text-align: left;\n  padding-right: 20px;\n  font-size: 11px;\n  color: #8e8e8e;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bodyForm": "H55iVRU7OEzD4sNPhJoAhw==",
	"buttonArea": "_3JMZ7j4l0+AMlsIjN-aJnA=="
};
module.exports = ___CSS_LOADER_EXPORT___;

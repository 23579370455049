import { defineMessages } from 'react-intl';

export const scope = 'boilerplate.utils.constraints';

export default defineMessages({
  required: {
    id: `${scope}.required`,
    defaultMessage: 'Ce champ est requis',
  },
  number: {
    id: `${scope}.number`,
    defaultMessage: 'La valeur doit être un nombre',
  },
  integer: {
    id: `${scope}.integer`,
    defaultMessage: 'La valeur doit être un entier',
  },
  min: {
    id: `${scope}.min`,
    defaultMessage: 'La valeur doit être supérieure ou égale à {minVal}',
  },
  max: {
    id: `${scope}.max`,
    defaultMessage: 'La valeur doit être inférieure ou égale à {maxVal}',
  },
  maxLength: {
    id: `${scope}.maxLength`,
    defaultMessage: 'La valeur doit contenir au maximum {maxVal} caractères',
  },
  minLength: {
    id: `${scope}.minLength`,
    defaultMessage: 'La valeur doit contenir au minimum {minVal} caractères',
  },
  email: {
    id: `${scope}.email`,
    defaultMessage: "L'adresse email est invalide",
  },
  positive: {
    id: `${scope}.positive`,
    defaultMessage: 'La valeur doit être positive',
  },
  negative: {
    id: `${scope}.negative`,
    defaultMessage: 'La valeur doit être négative',
  },
  range: {
    id: `${scope}.range`,
    defaultMessage: 'La valeur doit être comprise entre {minVal} et {maxVal}',
  },
  oneOf: {
    id: `${scope}.oneOf`,
    defaultMessage: "La valeur doit être l'une des suivants: {values}",
  },
});

import { Avatar, Icon, Typography } from "@mui/material";
import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { makeStyles } from "tss-react/mui";
import colorfull from "enl-api/palette/colorfull";
import PropTypes from "prop-types";
import { orange, green, blue, red } from "@mui/material/colors";

const useStyles = makeStyles()((theme) => ({
  bigResume: {
    marginBottom: theme.spacing(5),
    justifyContent: "space-between",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      height: 160,
      display: "block",
    },
    "& li": {
      paddingRight: theme.spacing(3),
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      [theme.breakpoints.down("sm")]: {
        paddingRight: 0,
        paddingBottom: theme.spacing(2),
        width: "50%",
        float: "left",
      },
    },
    [`& .avatar`]: {
      [theme.breakpoints.up("sm")]: {
        width: 50,
        height: 50,
        "& svg": {
          fontSize: 32,
        },
      },
    },
  },
  avatar: {
    marginRight: theme.spacing(1),
    boxShadow: theme.shadows[4],
    background: theme.palette.background.paper,
    "& svg": {
      fontSize: 24,
      fill: theme.palette.primary.main,
    },
    [`&.sm`]: {
      width: 30,
      height: 30,
    },
    [`&.mc`]: {
      width: 24,
      height: 24,
      top: 2,
      left: 8,
      marginRight: 0,
    },
  },
  orange: {
    "& .avatar": {
      color: orange[600],
      border: `1px solid ${orange[600]}`,
    },
    "& span": {
      color: orange[600],
      // fontSize: 30,
      "& svg": {
        fill: orange[600],
      },
    },
  },
  green: {
    "& .avatar": {
      color: green[600],
      border: `1px solid ${green[600]}`,
    },
    "& span": {
      color: green[600],
      // fontSize: 30,
      "& svg": {
        fill: green[600],
      },
    },
  },
  blue: {
    "& .avatar": {
      color: blue[600],
      border: `1px solid ${blue[600]}`,
    },
    "& span": {
      color: blue[600],
      // fontSize: 30,
      "& svg": {
        fill: blue[600],
      },
    },
  },
  red: {
    "& .avatar": {
      color: red[600],
      border: `1px solid ${red[600]}`,
    },
    "& span": {
      color: red[600],
      // fontSize: 30,
      "& svg": {
        fill: red[600],
      },
    },
  },
}));

function Summary(props) {
  const { data = [] } = props;

  const { classes, cx } = useStyles();

  const renderItem = ({ icon, value, label, color = "orange" }, index) => (
    <li
      key={index}
      className={classes[color]}
    >
      <Avatar className={cx(classes.avatar)}>
        <Icon className={icon}>{icon}</Icon>
      </Avatar>
      <Typography variant="h6">
        <span className={{}}>{value}</span>
        <Typography noWrap>{label}</Typography>
      </Typography>
    </li>
  );

  renderItem.propTypes = {
    icon: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    label: PropTypes.string.isRequired,
    color: PropTypes.string,
  };

  return (
    <ul className={classes.bigResume}>
      {data.map((item, index) => renderItem(item, index))}
      {/* <li>
        <Avatar className={cx(classes.avatar)}>
          <Dvr />
        </Avatar>
        <Typography variant="h6">
          <span className={{}}>40</span>
          <Typography noWrap>
            <FormattedMessage {...messages.attends} />
          </Typography>
        </Typography>
      </li> */}
      {/* <li>
        <Avatar className={cx(classes.avatar, classes.indigoAvatar)}>
          <CheckCircle />
        </Avatar>
        <Typography variant="h6">
          <span className={classes.indigoText}>125</span>
          <Typography noWrap>
            <FormattedMessage {...messages.tasks_done} />
          </Typography>
        </Typography>
      </li>
      <li>
        <Avatar className={cx(classes.avatar, classes.pinkAvatar)}>
          <Healing />
        </Avatar>
        <Typography variant="h6">
          <span className={classes.pinkText}>17</span>
          <Typography noWrap>
            <FormattedMessage {...messages.complaints} />
          </Typography>
        </Typography>
      </li>
      <li>
        <Avatar className={cx(classes.avatar, classes.purpleAvatar)}>
          <LocalActivity />
        </Avatar>
        <Typography variant="h6">
          <span className={classes.purpleText}>18</span>
          <Typography noWrap>
            <FormattedMessage {...messages.referrals} />
          </Typography>
        </Typography>
      </li> */}
    </ul>
  );
}

Summary.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      label: PropTypes.string.isRequired,
      color: PropTypes.string,
    })
  ),
};

export default injectIntl(Summary);

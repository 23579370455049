import React, { Fragment } from 'react';
import { makeStyles } from 'tss-react/mui';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { injectIntl, FormattedMessage } from 'react-intl';
import {
  Icon, InputLabel, MenuItem, Select,
  FormControl
} from '@mui/material';

import messages from './messages';

const useStyles = makeStyles()((theme) => ({
  itemSelect: {
    width: '100%',
    gap: theme.spacing(1),
  },
  test: {
    '& > .MuiSelect-select': {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(1),
    },
  },
}));

function CatalogCreateForm(props) {
  const {
    intl, formRef, type, setType, campaignName, setCampaignName, description, setDescription, campaignTypeItems
  } = props;
  const { classes, cx } = useStyles();

  function handleSubmit(event) {
    event.preventDefault();
  }

  return (
    <Fragment>
      <Typography
        variant="h6"
        gutterBottom
      >
        <FormattedMessage {...messages.campaign_informations} />
      </Typography>
      <form
        ref={formRef}
        onSubmit={handleSubmit}
      >
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            xs={12}
          >
            <TextField
              variant="standard"
              id="campaignName"
              name="campaignName"
              label={intl.formatMessage(messages.campaign_name)}
              value={campaignName}
              onChange={(e) => setCampaignName(e.target.value)}
              fullWidth
              required
            />
          </Grid>
          <Grid
            item
            xs={12}
          >
            <TextField
              variant="standard"
              id="campaignDescription"
              name="campaignDescription"
              label={intl.formatMessage(messages.campaign_description)}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              fullWidth
              multiline={true}
              rows={4}
            />
          </Grid>
          <Grid
            item
            xs={12}
          >
            <FormControl fullWidth>
              <InputLabel
                id="campaignTypeLabel"
                variant="standard"
              >
                {intl.formatMessage(messages.campaign_type)}
              </InputLabel>
              <Select
                variant="standard"
                labelId="campaignTypeLabel"
                id="campaignType"
                name="campaignType"
                className={cx(classes.test)}
                label={intl.formatMessage(messages.campaign_type)}
                value={type}
                onChange={(e) => setType(e.target.value)}
                fullWidth
                required
              >
                {campaignTypeItems.map((typeItem) => (
                  <MenuItem
                    key={typeItem.value}
                    value={typeItem.value}
                    className={cx(classes.itemSelect)}
                    disabled={typeItem.disabled || false}
                  >
                    <Icon className={messages[typeItem.messageKey].icon}>{messages[typeItem.messageKey].icon}</Icon>
                    <FormattedMessage {...messages[typeItem.messageKey]} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </form>
    </Fragment>
  );
}

CatalogCreateForm.propTypes = {
  intl: PropTypes.object.isRequired,
  formRef: PropTypes.object.isRequired,
  type: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  setType: PropTypes.func.isRequired,
  campaignName: PropTypes.string.isRequired,
  setCampaignName: PropTypes.func.isRequired,
  description: PropTypes.string.isRequired,
  setDescription: PropTypes.func.isRequired,
  campaignTypeItems: PropTypes.array.isRequired,
};

export default injectIntl(CatalogCreateForm);

/* eslint-disable class-methods-use-this */

import messages from './messages';

export default class Constraint {
  constructor(intl) {
    this.intl = intl;
  }

  required() {
    return {
      key: 'required',
      message: this.intl.formatMessage(messages.required),
      validate: (value) => String(value).trim() !== '',
    };
  }

  number() {
    return {
      key: 'number',
      message: this.intl.formatMessage(messages.number),
      validate: (value) => !Number.isNaN(Number(value)),
    };
  }

  integer() {
    return {
      key: 'integer',
      message: this.intl.formatMessage(messages.integer),
      validate: (value) => Number.isInteger(Number(value)),
    };
  }

  min(minVal = 0) {
    return {
      key: 'min',
      args: { minVal },
      message: this.intl.formatMessage(messages.min, { minVal }),
      validate: (value) => Number(value) >= minVal,
    };
  }

  max(maxVal = 0) {
    return {
      key: 'max',
      args: { maxVal },
      message: this.intl.formatMessage(messages.max, { maxVal }),
      validate: (value) => Number(value) <= maxVal,
    };
  }

  maxLength(maxVal = 0) {
    return {
      key: 'maxLength',
      args: { maxVal },
      message: this.intl.formatMessage(messages.maxLength, { maxVal }),
      validate: (value) => String(value).length <= maxVal,
    };
  }

  minLength(minVal = 0) {
    return {
      key: 'minLength',
      args: { minVal },
      message: this.intl.formatMessage(messages.minLength, { minVal }),
      validate: (value) => String(value).length >= minVal,
    };
  }

  email() {
    return {
      key: 'email',
      message: this.intl.formatMessage(messages.email),
      validate: (value) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(String(value)),
    };
  }

  positive() {
    return {
      key: 'positive',
      message: this.intl.formatMessage(messages.positive),
      validate: (value) => this.number().validate(value) && this.min(0).validate(value)
    };
  }

  negative() {
    return {
      key: 'negative',
      message: this.intl.formatMessage(messages.negative),
      validate: (value) => this.number().validate(value) && this.max(0).validate(value)
    };
  }

  range(minVal = 0, maxVal = 0) {
    return {
      key: 'range',
      args: { minVal, maxVal },
      message: this.intl.formatMessage(messages.range, { minVal, maxVal }),
      validate: (value) => this.number().validate(value) && this.min(minVal).validate(value) && this.max(maxVal).validate(value)
    };
  }

  /**
   * @param {Record<string,string>} values - The `key` must be the value to validate and the `value` the message to display
   */
  oneOf(values = {}) {
    return {
      key: 'oneOf',
      args: { values },
      message: this.intl.formatMessage(messages.oneOf, { values: Object.values(values).join(', ') }),
      validate: (value) => Object.keys(values).includes(value),
    };
  }
}

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import Button from '@mui/material/Button';
import useMediaQuery from '@mui/material/useMediaQuery';
import brand from 'enl-api/dummy/brand';
import logo from 'enl-images/logo.png';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ArrowForward from '@mui/icons-material/ArrowForward';
import Icon from '@mui/material/Icon';
import CircularProgress from '@mui/material/CircularProgress';
import { injectIntl, FormattedMessage } from 'react-intl';
import { closeMsgAction } from 'enl-redux/actions/authActions';
import { CheckboxRedux, TextFieldRedux } from './ReduxFormMUI';
import MessagesForm from './MessagesForm';
import messages from './messages';
import useStyles from './user-jss';
import { decodeHTMLEntities } from '../../utils/helpers';

// validation functions
const required = (value) => (value === null ? 'Required' : undefined);
const email = (value) => (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{1,}$/i.test(value) ? 'Invalid email' : undefined);

const passwordsMatch = (value, allValues) => {
  if (value !== allValues.pass) {
    return 'Passwords dont match';
  }
  return undefined;
};

/* eslint-disable */
const LinkBtn = React.forwardRef(function LinkBtn(props, ref) {
  return (
    <NavLink
      to={props.to}
      {...props}
      innerRef={ref}
    />
  );
});
/* eslint-enable */

function RegisterForm(props) {
  const { classes, cx } = useStyles();
  const mdUp = useMediaQuery((theme) => theme.breakpoints.up('md'));

  const {
    handleSubmit, pristine, submitting, intl, messagesAuth, closeMsg, loading
  } = props;

  return (
    <Paper className={classes.sideWrap}>
      {!mdUp && (
        <div className={classes.headLogo}>
          <NavLink
            to="/"
            className={classes.brand}
          >
            <img
              src={logo}
              alt={brand.name}
            />
          </NavLink>
        </div>
      )}
      <div className={classes.topBar}>
        <Typography
          variant="h4"
          className={classes.title}
        >
          <FormattedMessage {...messages.register} />
        </Typography>
        <Button
          size="small"
          className={classes.buttonLink}
          component={LinkBtn}
          to="/login"
        >
          <Icon className={cx(classes.icon, classes.signArrow)}>arrow_forward</Icon>
          <FormattedMessage {...messages.toAccount} />
        </Button>
      </div>
      {messagesAuth !== null || '' ? (
        <MessagesForm
          variant="error"
          className={classes.msgUser}
          message={
            messagesAuth.startsWith('</>') ? (
              <span dangerouslySetInnerHTML={{ __html: decodeHTMLEntities(messagesAuth.replace('</>', '')) }} />
            ) : (
              intl.formatMessage(messages[messagesAuth] ?? messages.connection_error)
            )
          }
          onClose={closeMsg}
        />
      ) : (
        ''
      )}
      <section>
        <form onSubmit={handleSubmit}>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              xs={12}
              sm={6}
            >
              <FormControl
                variant="standard"
                className={classes.formControl}
              >
                <Field
                  name="firstname"
                  component={TextFieldRedux}
                  type="text"
                  label={intl.formatMessage(messages.register_field_firstname)}
                  required
                  validate={[required]}
                  className={classes.field}
                />
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
            >
              <FormControl
                variant="standard"
                className={classes.formControl}
              >
                <Field
                  name="lastname"
                  component={TextFieldRedux}
                  type="text"
                  label={intl.formatMessage(messages.register_field_lastname)}
                  required
                  validate={[required]}
                  className={classes.field}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              xs={12}
              sm={6}
            >
              <FormControl
                variant="standard"
                className={classes.formControl}
              >
                <Field
                  name="login"
                  component={TextFieldRedux}
                  label={intl.formatMessage(messages.loginFieldName)}
                  required
                  validate={[required]}
                  className={classes.field}
                />
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
            >
              <FormControl
                variant="standard"
                className={classes.formControl}
              >
                <Field
                  name="email"
                  component={TextFieldRedux}
                  label={intl.formatMessage(messages.loginFieldEmail)}
                  required
                  validate={[required, email]}
                  className={classes.field}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              xs={12}
              sm={6}
            >
              <FormControl
                variant="standard"
                className={classes.formControl}
              >
                <Field
                  name="pass"
                  component={TextFieldRedux}
                  type="password"
                  label={intl.formatMessage(messages.loginFieldPassword)}
                  required
                  validate={[required, passwordsMatch]}
                  className={classes.field}
                />
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
            >
              <FormControl
                variant="standard"
                className={classes.formControl}
              >
                <Field
                  name="passConfirm"
                  component={TextFieldRedux}
                  type="password"
                  label={intl.formatMessage(messages.loginFieldRetypePassword)}
                  required
                  validate={[required, passwordsMatch]}
                  className={classes.field}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              xs={12}
              sm={6}
            >
              <FormControl
                variant="standard"
                className={classes.formControl}
              >
                <Field
                  name="address"
                  component={TextFieldRedux}
                  type="text"
                  label={intl.formatMessage(messages.register_field_address)}
                  className={classes.field}
                />
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
            >
              <FormControl
                variant="standard"
                className={classes.formControl}
              >
                <Field
                  name="town"
                  component={TextFieldRedux}
                  type="text"
                  label={intl.formatMessage(messages.register_field_town)}
                  className={classes.field}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              xs={12}
              sm={6}
            >
              <FormControl
                variant="standard"
                className={classes.formControl}
              >
                <Field
                  name="zip"
                  component={TextFieldRedux}
                  type="text"
                  label={intl.formatMessage(messages.register_field_zip)}
                  className={classes.field}
                />
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
            >
              <FormControl
                variant="standard"
                className={classes.formControl}
              >
                <Field
                  name="office_phone"
                  component={TextFieldRedux}
                  type="text"
                  label={intl.formatMessage(messages.register_field_office_phone)}
                  className={classes.field}
                />
              </FormControl>
            </Grid>
          </Grid>
          <div>
            <FormControlLabel
              control={
                <Field
                  name="checkbox"
                  required
                  component={CheckboxRedux}
                  className={classes.agree}
                />
              }
              label={intl.formatMessage(messages.aggree)}
            />
            <a
              href="/terms-conditions"
              target="_blank"
              className={classes.link}
            >
              <FormattedMessage {...messages.terms} />
            </a>
          </div>
          <div className={classes.btnArea}>
            <Button
              variant="contained"
              fullWidth
              disabled={loading}
              color="primary"
              type="submit"
            >
              {loading && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
              <FormattedMessage {...messages.loginButtonContinue} />
              {!loading && (
                <ArrowForward
                  className={cx(classes.rightIcon, classes.iconSmall, classes.signArrow)}
                  disabled={submitting || pristine}
                />
              )}
            </Button>
          </div>
        </form>
      </section>
    </Paper>
  );
}

RegisterForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  intl: PropTypes.object.isRequired,
  messagesAuth: PropTypes.string,
  closeMsg: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

RegisterForm.defaultProps = {
  messagesAuth: null,
};

const RegisterFormReduxed = reduxForm({
  form: 'registerForm',
  enableReinitialize: true,
})(RegisterForm);

const mapDispatchToProps = {
  closeMsg: closeMsgAction,
};

const mapStateToProps = (state) => ({
  messagesAuth: state.authReducer.message,
  loading: state.authReducer.loading,
});

const RegisterFormMapped = connect(mapStateToProps, mapDispatchToProps)(RegisterFormReduxed);

export default injectIntl(RegisterFormMapped);

import { defineMessages } from "react-intl";

export const scope = "boilerplate.components.Dashboard";

export default defineMessages({
  campains: {
    id: `${scope}.StatusIconsWidget.campains`,
    defaultMessage: "Campaigns",
  },
  supplier: {
    id: `${scope}.StatusIconsWidget.supplier`,
    defaultMessage: "Suppliers",
  },
  products: {
    id: `${scope}.StatusIconsWidget.products`,
    defaultMessage: "Products",
  },
  performance_indicator_title: {
    id: `${scope}.PerformanceIndicator.performance_indicator_title`,
    defaultMessage: "Performance Indicator",
  },
  products_success: {
    id: `${scope}.PerformanceIndicator.products_success`,
    defaultMessage: "Products Success",
  },
  products_errors: {
    id: `${scope}.PerformanceIndicator.products_errors`,
    defaultMessage: "Products Errors",
  },
  products_pendings: {
    id: `${scope}.PerformanceIndicator.products_pendings`,
    defaultMessage: "Products Pendings",
  },
  referrals: {
    id: `${scope}.PerformanceIndicator.referrals`,
    defaultMessage: "Referrals",
  },
  achievement_target: {
    id: `${scope}.PerformanceIndicator.achievement_target`,
    defaultMessage: "Achievement Target",
  },
  supplier_directory_title: {
    id: `${scope}.SupplierDirectory.supplier_directory_title`,
    defaultMessage: "Supplier Directory",
  },
  chat: {
    id: `${scope}.SupplierDirectory.chat`,
    defaultMessage: "Chat",
  },
  email: {
    id: `${scope}.SupplierDirectory.email`,
    defaultMessage: "Email",
  },
  call: {
    id: `${scope}.SupplierDirectory.call`,
    defaultMessage: "Call",
  },
  fixit: {
    id: `${scope}.SupplierDirectory.fixit`,
    defaultMessage: "Fix It",
  },
  skip: {
    id: `${scope}.SupplierDirectory.skip`,
    defaultMessage: "Skip",
  },
  contacts: {
    id: `${scope}.SupplierDirectory.contacts`,
    defaultMessage: "Contacts",
  },
  massages: {
    id: `${scope}.SupplierDirectory.massages`,
    defaultMessage: "Messages",
  },
  notification: {
    id: `${scope}.SupplierDirectory.notification`,
    defaultMessage: "Notification",
  },
});

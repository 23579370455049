import React from 'react';
import {
  Accordion, AccordionDetails, AccordionSummary, Typography
} from '@mui/material';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  rootBorder: { borderLeft: '4px solid' },
  pointer: { cursor: 'pointer' },
}));

function CatalogProductExpandedViewAccordion(props) {
  const { title, children, color } = props;

  const { classes, cx } = useStyles();

  return (
    <Accordion
      className={cx(classes.rootBorder)}
      style={{ borderLeftColor: color }}
    >
      <AccordionSummary className={cx(classes.pointer)}>
        <Typography variant="h5">{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
}

CatalogProductExpandedViewAccordion.propTypes = {
  intl: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
  color: PropTypes.string,
};

CatalogProductExpandedViewAccordion.defaultProps = {
  children: null,
  color: 'transparent',
};

export default injectIntl(CatalogProductExpandedViewAccordion);

import React from 'react';
import _ from 'lodash';
import {
  GridCsvExportMenuItem, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExportContainer, GridToolbarFilterButton, gridFilteredSortedRowIdsSelector, gridVisibleColumnFieldsSelector, useGridApiContext
} from '@mui/x-data-grid';
import { MenuItem } from '@mui/material';

/**
 * A getter function that retrieves a value from a row object based on the given name.
 *
 * @param {string} name - The name of the property to retrieve from the row object.
 * @param {string} [defaultValue=''] - The default value to return if the retrieved value is empty or not found. Defaults to `''`.
 *
 * @param {any} __ - The first parameter is not used in this function.
 * @param {object} row - The row object from which to retrieve the value.
 *
 * @returns {(__: any, row: object) => string} The retrieved value, or an empty string if the value is empty or not found.
 */
export const getter = (name, defaultValue = '') => (__, row) => {
  const value = _.get(row, name);
  if (!value || value.source.toUpperCase() === 'EMPTY') return defaultValue;
  return String(value.value);
};

/**
 * Adds a unit to a value
 *
 * @param {string} unit - The unit to be added.
 * @param {boolean} [close=false] - Indicates whether the unit should be directly attached to the value or separated by a non-breaking space. Defaults to `false`.
 * @param {number} value - The value to which the unit should be added.
 *
 * @returns {(value: any) => string} A function that takes a value and returns the value with the unit added.
 */
export const addUnit = (unit, close = false) => (value) => (value ? (`${value}${close ? '' : '\u00A0'}${unit}`) : ''); // \u00A0 = non-breaking space

/**
 * Retrieves the JSON data from the grid and formats it as a JSON string.
 *
 * @param {object} apiRef - The grid API reference object.
 *
 * @returns {string} The JSON data as a formatted JSON string.
 */
export const getJson = (apiRef) => {
  // Select rows and columns
  const filteredSortedRowIds = gridFilteredSortedRowIdsSelector(apiRef);
  const visibleColumnsField = gridVisibleColumnFieldsSelector(apiRef).filter(field => !field.startsWith('_'));
  // Format the data. Here we only keep the value
  const data = filteredSortedRowIds.map((rowId) => {
    const row = {};
    visibleColumnsField.forEach((field) => {
      row[field.replace('attributes.', '')] = apiRef.current.getCellParams(rowId, field).value;
    });
    return row;
  });
  return JSON.stringify(data, null, 2); // Stringify with some indentation
};

/**
 * Saves the given blob as a file with the specified filename.
 *
 * @param {Blob} blob - The blob to be saved.
 * @param {string} filename - The name of the file to be downloaded.
 */
export const exportBlob = (blob, filename) => {
  // Save the blob in a json file
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  a.click();
  setTimeout(() => {
    URL.revokeObjectURL(url);
  });
};

/**
 * Component for exporting data as JSON.
 *
 * @param {Object} jsonProps - The props for the JSON export menu item.
 * @param {Function} jsonProps.hideMenu - The function to hide the export menu.
 *
 * @returns {JSX.Element} The JSON export menu item component.
 */
export const JsonExportMenuItem = (jsonProps) => {
  const apiRef = useGridApiContext();

  const { hideMenu } = jsonProps;

  return (
    <MenuItem
      style={{ fontFamily: '"Roboto","Helvetica","Arial",sans-serif' }}
      onClick={() => {
        const jsonString = getJson(apiRef);
        const blob = new Blob([jsonString], { type: 'text/json' });
        exportBlob(blob, `${document.title}.json`);
        // Hide the export menu after the export
        hideMenu?.();
      }}
    >
      Télécharger en JSON
    </MenuItem>
  );
};

/**
 * CustomToolbar component for the data grid.
 *
 * @param {object} toolbarProps - Props for the toolbar.
 *
 * @returns {JSX.Element} - The rendered CustomToolbar component.
 */
export const CustomToolbar = (toolbarProps) => (
  <GridToolbarContainer {...toolbarProps}>
    <GridToolbarColumnsButton />
    <GridToolbarFilterButton />
    <GridToolbarDensitySelector />
    <GridToolbarExportContainer>
      <GridCsvExportMenuItem options={{ delimiter: ';', utf8WithBom: true }} />
      <JsonExportMenuItem />
    </GridToolbarExportContainer>
  </GridToolbarContainer>
);

import React from 'react';
import { Tooltip } from '@mui/material';
import * as Icons from '@mui/icons-material';
import _ from 'lodash';
import { GridActionsCellItem } from '@mui/x-data-grid';
import messages from './messages';

const CatalogListColumnsDetails = (intl, onDelete = () => {}) => [
  {
    field: 'data.product_collection_name',
    headerName: intl.formatMessage(messages.campaign_list_name),
  },
  {
    field: 'data.product_collection_code',
    headerName: intl.formatMessage(messages.campaign_list_code),
  },
  {
    field: 'data.product_collection_files_pending',
    headerName: intl.formatMessage(messages.campaign_list_files_pending),
  },
  {
    field: 'data.product_collection_files_success',
    headerName: intl.formatMessage(messages.campaign_list_files_success),
  },
  {
    field: 'data.product_collection_files_errors',
    headerName: intl.formatMessage(messages.campaign_list_files_errors),
    renderCell: (params) => {
      const { row, value } = params;
      const errors = row.data.product_collection_files_errors_errors;
      return (
        <span style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
          {value}
          {errors?.length ? (
            <Tooltip title={errors?.join(' - ')} style={{ cursor: 'help' }}>
              <Icons.HelpOutline fontSize='small' />
            </Tooltip>
          ) : null}
        </span>
      );
    },
  },
  {
    field: 'actions',
    type: 'actions',
    headerName: intl.formatMessage(messages.campaign_list_actions),
    width: 100,
    getActions: (params) => [
      <GridActionsCellItem
        key='delete'
        icon={<Icons.Delete />}
        label={intl.formatMessage(messages.campaign_list_delete)}
        onClick={() => onDelete(params.id)}
      />,
    ],
  }
];

export const CatalogListMock = [];

const ColumnBaseOptions = (colOpt) => ({
  width: 200,
  valueGetter: (__, row) => _.get(row, colOpt.field, ''),
});

export const CatalogListColumns = (intl, onDelete = () => {}) => {
  const columns = CatalogListColumnsDetails(intl, onDelete);
  return columns.map((columnOptions) => {
    const baseOptions = ColumnBaseOptions(columnOptions);
    if (columnOptions.type === 'actions') {
      delete baseOptions.valueGetter;
    }
    return { ...baseOptions, ...columnOptions };
  });
};

export default { CatalogListColumns, CatalogListMock };

const firebaseConfig = {
  apiKey: 'ccraSyBT9MFsTOdDG5Cx0skAOtef8uzIBLJQ2xA',
  authDomain: 'yourapp-1234e.firebaseapp.com',
  databaseURL: 'https://youreapp-1234e.firebaseio.com',
  projectId: 'youreapp-1234e',
  storageBucket: 'youreapp-1234e.appspot.com',
  messagingSenderId: '37325684805'
};

export default firebaseConfig;

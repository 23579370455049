import React from 'react';
import LocaleToggle from 'containers/LocaleToggle';

function SelectLanguage() {
  return (
    <div>
      <LocaleToggle />
    </div>
  );
}

export default SelectLanguage;

import React from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Dvr from "@mui/icons-material/Dvr";
import CheckCircle from "@mui/icons-material/CheckCircle";
import Error from "@mui/icons-material/Error";
import Pending from "@mui/icons-material/Pending";
import Healing from "@mui/icons-material/Healing";
import FilterCenterFocus from "@mui/icons-material/FilterCenterFocus";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import LinearProgress from "@mui/material/LinearProgress";
import LocalActivity from "@mui/icons-material/LocalActivity";
import Typography from "@mui/material/Typography";
import { injectIntl, FormattedMessage } from "react-intl";
import "enl-styles/vendors/rechart/styles.css";
import { ComposedChart, Line, Area, XAxis, YAxis, Tooltip, CartesianGrid, ResponsiveContainer } from "recharts";
import { dataPerformance } from "enl-api/chart/chartData";
import colorfull from "enl-api/palette/colorfull";
import messages from "./messages";
import useStyles from "./widget-jss";
import PapperBlock from "../PapperBlock/PapperBlock";

const color = {
  main: colorfull[2],
  secondary: colorfull[6],
  third: colorfull[0],
  fourth: colorfull[1],
};

function PerformanceIndicator(props) {
  const { intl } = props;
  const { classes, cx } = useStyles();

  return (
    <PapperBlock
      whiteBg
      noMargin
      title={intl.formatMessage(messages.performance_indicator_title)}
      icon="timeline"
      desc=""
    >
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          md={12}
          xs={12}
        >
          <ul className={classes.bigResume}>
            <li>
              <Avatar className={cx(classes.avatar, classes.greenAvatar)}>
                <CheckCircle />
              </Avatar>
              <Typography variant="h6">
                <span className={classes.greenText}>40</span>
                <Typography noWrap>
                  <FormattedMessage {...messages.products_success} />
                </Typography>
              </Typography>
            </li>
            <li>
              <Avatar className={cx(classes.avatar, classes.redAvatar)}>
                <Error />
              </Avatar>
              <Typography variant="h6">
                <span className={classes.redText}>125</span>
                <Typography noWrap>
                  <FormattedMessage {...messages.products_errors} />
                </Typography>
              </Typography>
            </li>
            <li>
              <Avatar className={cx(classes.avatar, classes.orangeAvatar)}>
                <Pending />
              </Avatar>
              <Typography variant="h6">
                <span className={classes.orangeText}>17</span>
                <Typography noWrap>
                  <FormattedMessage {...messages.products_pendings} />
                </Typography>
              </Typography>
            </li>
          </ul>
        </Grid>
        <Grid
          item
          md={12}
          xs={12}
        >
          <Typography
            className={classes.smallTitle}
            variant="button"
          >
            <FilterCenterFocus className={classes.leftIcon} />
            <FormattedMessage {...messages.achievement_target} />
          </Typography>
          <Divider className={classes.divider} />
          <ul className={classes.secondaryWrap}>
            <li>
              <Typography gutterBottom>Finish 100 task</Typography>
              <LinearProgress
                variant="determinate"
                className={cx(classes.progress, classes.pinkProgress)}
                value={24}
              />
            </li>
            <li>
              <Typography gutterBottom>Get 10 attending</Typography>
              <LinearProgress
                variant="determinate"
                className={cx(classes.progress, classes.purpleProgress)}
                value={89}
              />
            </li>
            <li>
              <Typography gutterBottom>Get less than 10 complaint</Typography>
              <LinearProgress
                variant="determinate"
                className={cx(classes.progress, classes.orangeProgress)}
                value={78}
              />
            </li>
            {/* <li>
              <Typography gutterBottom>Upload 5 videos or articles</Typography>
              <LinearProgress
                variant="determinate"
                className={cx(classes.progress, classes.greenProgress)}
                value={55}
              />
            </li> */}
            {/* <li>
              <Typography gutterBottom>Completing profile</Typography>
              <LinearProgress
                variant="determinate"
                className={cx(classes.progress, classes.blueProgress)}
                value={80}
              />
            </li> */}
          </ul>
        </Grid>
      </Grid>
    </PapperBlock>
  );
}

PerformanceIndicator.propTypes = {
  intl: PropTypes.object.isRequired,
};

export default injectIntl(PerformanceIndicator);
